import { Box, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import parse from "html-react-parser";
import { useState } from "react";

const ExpandableCell: React.FC<
  GridRenderCellParams & {
    maxChar?: number;
    maxWord?: number;
    expandedMaxChar?: number;
    expandedMaxWord?: number;
    wordsDelimitter?: string;
  }
> = ({
  value = "",
  maxChar = 160,
  maxWord = 0,
  expandedMaxChar = 0,
  expandedMaxWord = 0,
  wordsDelimitter = " ",
}) => {
  const [expanded, setExpanded] = useState(false);
  const words = value.split(wordsDelimitter);
  const noOfWords = words.length;
  const noOfChars = value.length;

  const _value =
    maxWord > 0
      ? words.slice(0, maxWord).join(wordsDelimitter)
      : value.slice(0, maxChar);

  let expandedValue = value;

  if (expanded) {
    if (expandedMaxChar > 0 && value.length > expandedMaxChar) {
      expandedValue = `${value.slice(0, expandedMaxChar) as string}...`;
    }

    if (expandedMaxWord > 0 && words.length > expandedMaxWord) {
      expandedValue = `${
        words.slice(0, expandedMaxWord).join(wordsDelimitter) as string
      }...`;
    }
  }

  return (
    <Box sx={{ width: "100%", overflowWrap: "break-word" }}>
      {expanded ? parse(expandedValue) : parse(_value)}
      {((maxWord > 0 && noOfWords > maxWord) || noOfChars > maxChar) && (
        <>
          {expanded ? " " : `${wordsDelimitter} `}
          <Link
            type="button"
            component="button"
            sx={{ fontSize: "inherit" }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "view less" : "view more"}
          </Link>
        </>
      )}
    </Box>
  );
};

export default ExpandableCell;
