import { GridColDef } from "@mui/x-data-grid";
import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect, useState } from "react";

import {
  dropListProps,
  dropdownFieldsDataProps,
  dropdownOptionProps,
  fieldMapProps,
  validFieldPatternsProps,
} from "../../@types/assetTypes/ap";
import SearchClose from "../../assets/Icons/SearchClose";
import Button from "../Button";
import Dropdown from "../Dropdown";
import SearchableDropdown from "../SearchableDropdown";
import SearchableMultiSelectDropdown from "../SearchableMultiSelectDropdown";

interface UpdateExpenseViewProps {
  title: string;
  fields: GridColDef[];
  mandatoryFields: string[];
  upperCaseFields?: string[];
  hideFields: string[];
  disableFields?: string[];
  dropdownFieldsData?: dropdownFieldsDataProps[];
  fieldMap?: fieldMapProps;
  validFieldPatterns?: validFieldPatternsProps;
  data: { [k: string]: string } | null | undefined;
  onClose: () => void;
  onSubmit: (data: { [k: string]: string | object }) => Promise<void>;
}

const UpdateExpenseView: React.FC<UpdateExpenseViewProps> = ({
  title,
  fields,
  mandatoryFields,
  upperCaseFields,
  hideFields,
  disableFields,
  dropdownFieldsData,
  fieldMap,
  validFieldPatterns,
  data,
  onClose,
  onSubmit,
}) => {
  const [inputs, setInputs] = useState<{
    [k: string]: string | object | Array<number | string>;
  }>({});

  const [errors, setErrors] = useState<{
    [k: string]: string;
  }>({});

  const isMandatoryFieldEmpty = !!mandatoryFields.find((field: string) => {
    if (typeof inputs[field] === "object") {
      const f = fields.find(({ field: f }) => f === field);
      const d = dropdownFieldsData?.find(({ field: f }) => f === field);
      const mapToField = d?.mapToField;

      if (mapToField) {
        let isHide0 = false;
        let isHide1 = false;

        const value = inputs[mapToField] as {
          id: string;
          value: string;
        };

        isHide0 = (fieldMap?.[value?.id]?.hide as boolean) || false;
        isHide1 = (fieldMap?.[value?.value]?.hide as boolean) || false;

        if (isHide0 || isHide1) return false;
      }

      if (f?.type === "multi-select-dropdown") {
        const input = inputs[field] as Array<number | string>;

        return input.length === 0;
      } else if (f?.type === "search-dropdown") {
        const input = inputs[field] as { value: string };

        return !input.value;
      } else {
        const input = inputs[field] as { id: string };

        return !input.id;
      }
    }

    return !inputs[field];
  });

  const hasError = Object.keys(errors).findIndex((e) => !!errors[e]) > -1;

  const handleChange =
    (field: string, options?: { [k: string]: boolean }) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;

      let v = value;

      const pattern = validFieldPatterns?.[field]?.pattern;
      const isValid = validFieldPatterns?.[field]?.isValid;

      if (v !== "" && pattern && !v.match(pattern)) return;

      if (v !== "" && isValid && !isValid(v)) {
        const errorText = validFieldPatterns?.[field]?.errorText || "Invalid!";

        setErrors((errors) => ({ ...errors, [field]: errorText }));
      } else {
        setErrors((errors) => ({ ...errors, [field]: "" }));
      }

      if (options?.isUpperCase) v = value.toUpperCase();

      setInputs((inputs) => ({ ...inputs, [field]: v }));
    };

  const handleDropdownChange = (field: string) => (d: dropListProps) => {
    setInputs((inputs) => {
      const _inputs = { ...inputs };

      _inputs[field] = d;

      const mtf = dropdownFieldsData?.find(
        ({ mapToField }) => mapToField === field,
      );

      const mapToField = mtf?.field;

      if (mapToField) {
        const defaultId = mtf?.defaultId;

        // setting default value on change
        if (defaultId) {
          if (mtf?.data) {
            _inputs[mapToField] =
              mtf?.data?.find((dt) => dt.id === defaultId(_inputs)) || {};
          } else if (mtf?.data1) {
            _inputs[mapToField] =
              mtf?.data1?.find((dt) => dt.value === defaultId(_inputs)) || {};
          } else {
            _inputs[mapToField] = {};
          }
        } else {
          _inputs[mapToField] = {};
        }
      }

      return _inputs;
    });
  };

  const handleSearchDropdownChange =
    (field: string) => (d: dropdownOptionProps) => {
      setInputs((inputs) => {
        const _inputs = { ...inputs };

        _inputs[field] = d;

        const mtf = dropdownFieldsData?.find(
          ({ mapToField }) => mapToField === field,
        );

        const mapToField = mtf?.field;

        if (mapToField) {
          const defaultId = mtf?.defaultId;

          // setting default value on change
          if (defaultId) {
            if (mtf?.data) {
              _inputs[mapToField] =
                mtf?.data?.find((dt) => dt.id === defaultId(_inputs)) || {};
            } else if (mtf?.data1) {
              _inputs[mapToField] =
                mtf?.data1?.find((dt) => dt.value === defaultId(_inputs)) || {};
            } else {
              _inputs[mapToField] = {};
            }
          } else {
            _inputs[mapToField] = {};
          }
        }

        return _inputs;
      });
    };

  const handleMultiSelectDropdownChange = useCallback(
    (field: string) => (value: number | string) => {
      const _inputs = cloneDeep(inputs);

      const mapToField = dropdownFieldsData?.find(
        ({ mapToField }) => mapToField === field,
      )?.field;

      if (mapToField) _inputs[mapToField] = [];

      if (value === "__none__") _inputs[field] = [];
      else {
        const inputField = (_inputs[field] as Array<number | string>) || [];
        const i = inputField.findIndex((v) => v === value);

        if (i > -1) inputField.splice(i, 1);
        else inputField.push(value);

        _inputs[field] = inputField;
      }

      setInputs(_inputs);
    },
    [inputs],
  );

  useEffect(() => {
    const inputs: { [k: string]: string | {} } = {};

    fields.forEach(({ field }) => {
      inputs[field] =
        data?.[field] !== undefined && data?.[field] !== null
          ? data[field]
          : "";
    });

    // setting default value on load
    fields.forEach(({ field }) => {
      if (!inputs[field]) {
        const d = dropdownFieldsData?.find(({ field: f }) => f === field);
        const defaultId = d?.defaultId;

        if (defaultId) {
          if (d?.data) {
            inputs[field] =
              d?.data?.find((d) => d.id === defaultId(inputs)) || "";
          } else if (d?.data1) {
            inputs[field] =
              d?.data1?.find((d) => d.value === defaultId(inputs)) || "";
          } else {
            inputs[field] = "";
          }
        }
      }
    });
    setInputs(inputs);
  }, [fields, data, dropdownFieldsData]);

  return (
    <>
      <header className="flex justify-between items-center px-5">
        <h1 className="font-bold text-lg mx-auto ">{title}</h1>
        <button
          onClick={onClose}
          className="font-mono font-bold text-xl border-0 text-[#211551] leading-4 "
        >
          x
        </button>
      </header>
      <div className="border border-[#211551] w-full my-3"></div>
      <div className="px-5 w-[600px]">
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="space-y-10 w-full">
            {fields.map(({ field, type, headerName }) => {
              if (
                type &&
                ![
                  "dropdown",
                  "search-dropdown",
                  "multi-select-dropdown",
                  "textarea",
                ].includes(type)
              )
                return null;

              const isMandatory = mandatoryFields.includes(field);
              const isUpperCase = (upperCaseFields || []).includes(field);
              const isHide = hideFields.includes(field);
              const isDisable = (disableFields || []).includes(field);

              if (isHide) return null;

              const d = dropdownFieldsData?.find(({ field: f }) => f === field);
              const mapToField = d?.mapToField;
              const allowCustomValue = d?.allowCustomValue;
              const selectOnly = d?.selectOnly;

              let data = d?.data;
              let data1 = d?.data1;
              let isHide0 = false;
              let isHide1 = false;

              if (mapToField) {
                const value = inputs[mapToField] as {
                  id: string;
                  value: string;
                };

                const vId = value?.id || "";
                const vValue = value?.value || "";

                data = data?.filter(({ id }) => {
                  const fieldArray = fieldMap?.[vId || vValue]?.[
                    field
                  ] as string[];

                  return fieldArray?.includes(id);
                });

                isHide0 = !!fieldMap?.[vId || vValue]?.hide;

                data1 = data1?.filter(({ value }) => {
                  const fieldArray = fieldMap?.[vId || vValue]?.[
                    field
                  ] as string[];

                  return fieldArray?.includes(value);
                });

                isHide1 = !!fieldMap?.[vId || vValue]?.hide;
              }

              if (isHide0 || isHide1) return null;

              const helperText = validFieldPatterns?.[field]?.helperText || "";
              const errorText = errors[field] || "";

              return (
                <div key={field} className="flex justify-between items-center">
                  <div>
                    {headerName}{" "}
                    {isMandatory && <span className="text-red-600">*</span>}
                  </div>
                  {type === "dropdown" && (
                    <div className="max-w-xs w-full">
                      <Dropdown
                        arrayList={data || []}
                        changeHandler={handleDropdownChange(field)}
                        value={(inputs[field] as dropListProps) || {}}
                        fullWidth
                        disabled={isDisable}
                      />
                    </div>
                  )}
                  {type === "search-dropdown" && (
                    <div className="max-w-xs w-full">
                      <SearchableDropdown
                        options={data1 || []}
                        onSelect={handleSearchDropdownChange(field)}
                        value={
                          (inputs[field] as dropdownOptionProps) || {
                            value: "",
                            label: "",
                          }
                        }
                        fullWidth
                        disabled={isDisable}
                        allowCustomValue={
                          allowCustomValue
                            ? allowCustomValue({ mapToField, data1 })
                            : true
                        }
                        selectOnly={
                          selectOnly ? selectOnly({ mapToField, data1 }) : false
                        }
                      />
                    </div>
                  )}
                  {type === "multi-select-dropdown" && (
                    <div className="max-w-xs w-full space-y-2">
                      <SearchableMultiSelectDropdown
                        options={data1 || []}
                        selected={
                          (inputs[field] as Array<string | number>) || []
                        }
                        fullWidth
                        onSelect={handleMultiSelectDropdownChange(field)}
                        disabled={isDisable}
                      />
                      <div className="w-full h-28 resize-none flex flex-col overflow-auto space-y-2 border p-2 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed">
                        {((inputs[field] as Array<string | number>) || []).map(
                          (value) => {
                            const c = (data1 || []).find(
                              (c) => c.value === value,
                            );

                            const label = c?.label;

                            if (!label) return null;

                            return (
                              <div
                                key={value}
                                className="flex space-x-5 w-full items-center justify-between bg-gray-100 rounded px-3 py-1.5"
                              >
                                <span className="text-sm">{label}</span>
                                <SearchClose
                                  className="w-3 h-3 flex-shrink-0 cursor-pointer"
                                  onClick={() =>
                                    handleMultiSelectDropdownChange(field)(
                                      value,
                                    )
                                  }
                                />
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  )}
                  {type === "textarea" && (
                    <textarea
                      className="w-full h-32 resize-none flex justify-between border shadow-custom shadow-blue-200 px-5 py-2 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                      value={(inputs[field] as string) || ""}
                      onChange={handleChange(field, { isUpperCase })}
                      disabled={isDisable}
                    ></textarea>
                  )}
                  {![
                    "dropdown",
                    "search-dropdown",
                    "multi-select-dropdown",
                    "textarea",
                  ].includes(type || "") && (
                    <div className="max-w-xs w-full space-y-2">
                      <input
                        type="text"
                        className={[
                          "w-full h-10 flex justify-between gap-3 border items-center shadow-custom shadow-blue-200 px-5 py-1 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed",
                          isUpperCase ? "uppercase" : "",
                        ].join(" ")}
                        value={(inputs[field] as string) || ""}
                        onChange={handleChange(field, { isUpperCase })}
                        disabled={isDisable}
                      />
                      {!errorText && helperText && (
                        <span className="block text-sm text-[#211551]">
                          {helperText}
                        </span>
                      )}
                      {errorText && (
                        <span className="block text-sm text-red-600">
                          {errorText}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <footer className="flex w-full justify-center mt-8">
          <Button
            type="submit"
            label="Submit"
            disabled={isMandatoryFieldEmpty || hasError}
            onClick={() => onSubmit(inputs)}
            styleVal="transition ease-in-out delay-50 hover:scale-110"
          />
        </footer>
      </div>
    </>
  );
};

export default UpdateExpenseView;
