import {
  DataGrid,
  GridRowsProp,
  MuiEvent,
  GridColDef,
  GridCellParams,
  GridRowId,
  GridRenderCellParams,
  GridFilterModel,
  getGridNumericOperators,
  GridSortModel,
  getGridStringOperators,
  GridFilterInputValueProps,
  GridFilterInputMultipleValue,
  GridColumnHeaderParams,
  GridPaginationModel,
} from "@mui/x-data-grid";
import React from "react";

import { S4RunHistoryDetails } from "../../@types/assetTypes/ap";
import StatusTitle from "../../components/ColumnHeaders/StatusTitle";
import TooltipContent from "../../components/ColumnHeaders/TooltipContent";
import ExpandableCell from "../../components/ExpandableCell";
import Refresh from "../../components/RefreshButton";
import SectionContainer from "../../components/SectionConatiner";
import { features } from "../../constants/features";
import {
  s4RunHistoryShowDetails,
  s4RunHistoryStatusFilter,
} from "../../constants/s4upload";
import statusDetail from "../../data/S4StatusDetail.json";
import useStore from "../../store/useStore";
import { includesStatus } from "../../utils";

import Empty from "./components/Empty";
import RadioButton from "./components/RadioButton";
import Toolbar from "./components/Toolbar";

const { isVMSSourceInvoicesEnabled } = features.s4Upload;

const columns: GridColDef[] = [
  {
    field: "id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 150,
    headerName: "EBDR Run ID",
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value === "=",
    ),
    valueFormatter: (params) => params.value,
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <StatusTitle
          title={params.colDef.headerName}
          detail={statusDetail}
          hideCustomSort
        />
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipContent title={params.value} />;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: "bdrs",
    headerName: "BDR(s)",
    width: 200,
    renderCell: (params: GridRenderCellParams) => (
      <ExpandableCell
        {...params}
        maxWord={3}
        expandedMaxChar={300}
        expandedMaxWord={25}
        wordsDelimitter=","
      />
    ),
    sortable: false,
    filterable: false,
  },
  {
    field: "invoices",
    headerName: "SAP Invoices",
    width: 200,
    sortable: false,
    filterable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains",
    ),
    renderCell: (params: GridRenderCellParams) => (
      <ExpandableCell
        {...params}
        maxWord={3}
        expandedMaxChar={300}
        expandedMaxWord={25}
        wordsDelimitter=","
      />
    ),
  },
  {
    field: "sourceInvoices",
    headerName: "VMS/Source Invoices",
    width: 200,
    renderCell: (params: GridRenderCellParams) => (
      <ExpandableCell
        {...params}
        maxWord={3}
        expandedMaxChar={300}
        expandedMaxWord={25}
        wordsDelimitter=","
      />
    ),
    sortable: false,
    filterOperators: getGridStringOperators()
      .filter((operator) => operator.value === "isAnyOf")
      .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent
          ? (props: GridFilterInputValueProps) => (
              <GridFilterInputMultipleValue {...props} variant="standard" />
            )
          : undefined,
      })),
  },
  {
    field: "details",
    headerName: "Details",
    width: 160,
    renderCell: ({ value }: GridRenderCellParams) => {
      if (value) return <span className="text-[#1976d2]">Details</span>;
      else return <></>;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: "ebdrFileName",
    headerName: "File Name",
    width: 250,
    cellClassName: "break-words",
    sortable: false,
    filterable: false,
  },
  {
    field: "remarks",
    headerName: "Comments",
    width: 250,
    sortable: false,
    filterable: false,
  },
  {
    field: "uploadedBy",
    headerName: "Uploaded By",
    minWidth: 250,
    sortable: false,
    filterable: false,
  },
  {
    field: "lastActionBy",
    headerName: "Last Action By",
    minWidth: 250,
    sortable: false,
    filterable: false,
  },
  {
    field: "lastActionAt",
    headerName: "Last Action Time (UTC)",
    width: 200,
    sortable: false,
    filterable: false,
  },
];

if (!isVMSSourceInvoicesEnabled) {
  const i = columns.findIndex((c) => c.field === "sourceInvoices");

  columns.splice(i, 1);
}

interface historyProps {
  statusFilter: string;
  runHistory: S4RunHistoryDetails[];
  totalRowCount: number;
  paginationModel: GridPaginationModel;
  pageSize: number;
  pageNo: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  getRunHistoryFunc: (pageNo: number) => void;
  onStatusFilterChange: (id: string) => void;
  onDetailsClick: (id: GridRowId) => void;
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
  onFilterChange: (filterModel: GridFilterModel) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

const Historysection: React.FC<historyProps> = ({
  statusFilter,
  runHistory,
  totalRowCount,
  paginationModel,
  pageNo,
  pageSize,
  filterModel,
  sortModel,
  getRunHistoryFunc,
  onStatusFilterChange,
  onDetailsClick,
  onPaginationModelChange,
  onFilterChange,
  onSortModelChange,
}) => {
  const enableLoader = useStore((state) => state.enableLoader);
  const [rowCountState, setRowCountState] = React.useState(totalRowCount || 0);

  const rows: GridRowsProp = runHistory.map((item) => {
    const successReferenceDocument = (item.bdrDetails || [])
      .map(({ referenceDocument }) => referenceDocument)
      .filter(Boolean);

    const errorReferenceDocument = (item.referenceErrorDetails || [])
      .map(({ referenceDocument }) => referenceDocument)
      .filter(Boolean);

    const sourceInvoices = [
      ...new Set([...successReferenceDocument, ...errorReferenceDocument]),
    ]
      .sort((a, b) => (a || "").localeCompare(b || ""))
      .join(", ");

    return {
      ...item,
      status: [item.status, item.error].filter(Boolean).join("\n\n"),
      lastActionAt: item.lastActionAt.value,
      bdrs: (item.bdrDetails || [])
        .map(({ bdrId }) => (bdrId ? Number(bdrId) : null))
        .filter(Boolean)
        .sort((a, b) => (a || 0) - (b || 0))
        .join(", "),
      invoices: [
        ...new Set(
          (item.invoiceDetails || [])
            .map(({ invoiceId }) => (invoiceId ? Number(invoiceId) : null))
            .filter(Boolean)
            .sort((a, b) => (a || 0) - (b || 0)),
        ),
      ].join(", "),
      sourceInvoices,
      details: includesStatus(
        s4RunHistoryShowDetails,
        (item.status || "").split(":")[0] || "",
      ),
    };
  });

  const handleClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
  ): void => {
    event.preventDefault();
    const { field, value, id } = params;

    if (field === "details" && value && id) {
      onDetailsClick(id);
      window.scrollTo({ top: 0, left: 0 });
    }
  };

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState,
    );
  }, [totalRowCount, setRowCountState]);

  return (
    <SectionContainer
      title="Run History"
      className="w-full mx-auto mt-14 px-10"
    >
      <div className="flex items-center justify-between">
        <div className="flex space-x-5 items-center">
          <RadioButton
            id="all-radio"
            name="status-radio-group"
            label="All"
            checked={statusFilter === s4RunHistoryStatusFilter.ALL}
            onChange={() => onStatusFilterChange(s4RunHistoryStatusFilter.ALL)}
          />
          <RadioButton
            id="in-progress-radio"
            name="status-radio-group"
            label="In Progress"
            checked={statusFilter === s4RunHistoryStatusFilter.IN_PROGRESS}
            onChange={() =>
              onStatusFilterChange(s4RunHistoryStatusFilter.IN_PROGRESS)
            }
          />
          <RadioButton
            id="completed-radio"
            name="status-radio-group"
            label="Completed"
            checked={statusFilter === s4RunHistoryStatusFilter.COMPLETED}
            onChange={() =>
              onStatusFilterChange(s4RunHistoryStatusFilter.COMPLETED)
            }
          />
        </div>
        <div className="flex justify-end">
          <Refresh refresh={() => getRunHistoryFunc(pageNo)} label="Refresh" />
        </div>
      </div>
      <div
        className={[
          rowCountState < pageSize
            ? "max-h-[850px]"
            : pageSize === 5
              ? "min-h-[450px] max-h-[550px] h-[60vh] 2xl:h-[45vh]"
              : "min-h-[450px] max-h-[850px] h-[70vh] 2xl:h-[65vh]",
          "mx-auto my-5 border border-[#211551] rounded-md",
        ].join(" ")}
      >
        <DataGrid
          autoHeight={rowCountState < pageSize}
          hideFooter={rowCountState < pageSize}
          loading={enableLoader}
          sortingOrder={["desc", "asc"]}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          rowCount={rowCountState}
          rows={rows}
          columns={columns}
          slots={{ toolbar: Toolbar, loadingOverlay: Empty }}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[5, 10]}
          pagination
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          filterModel={filterModel}
          sortModel={sortModel}
          onFilterModelChange={onFilterChange}
          onSortModelChange={onSortModelChange}
          sx={{
            "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
              borderBottomWidth: 0,
            },
            "& .MuiDataGrid-cell:hover": {
              // color: "primary.main",
              cursor: "pointer",
            },
            "& .MuiDataGrid-toolbarContainer": {
              "& .MuiButtonBase-root": { color: "#211551" },
            },

            "& .MuiDataGrid-columnHeaders": {
              bgcolor: "#211551",
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              "& .MuiIconButton-sizeSmall": {
                color: "white",
              },
            },
            "& .MuiDataGrid-row": { fontSize: "small" },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "4px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "10px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "17px",
            },
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none",
              },
          }}
          getRowHeight={() => "auto"}
          isCellEditable={() => false}
          columnHeaderHeight={44}
          disableRowSelectionOnClick
          onCellClick={handleClick}
        />
      </div>
    </SectionContainer>
  );
};

export default Historysection;
