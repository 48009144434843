import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridFilterModel,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridFilterInputSingleSelectProps,
  GridFilterInputSingleSelect,
  GridFilterInputDate,
  GridFilterInputDateProps,
  getGridDateOperators,
  getGridNumericOperators,
  GridRenderEditCellParams,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";

import StatusTitle from "../../components/ColumnHeaders/StatusTitle";
import TimeStampTitle from "../../components/ColumnHeaders/TimeStampTitle";
import TooltipContent from "../../components/ColumnHeaders/TooltipContent";
import Refresh from "../../components/RefreshButton";
import SectionConatiner from "../../components/SectionConatiner";
import APAndARStatuses from "../../data/APAndARStatuses.json";
import StatusDetails from "../../data/PWCStatusDetail.json";
import useStore from "../../store/useStore";
import Empty from "../S4Upload/components/Empty";
import Toolbar from "../S4Upload/components/Toolbar";

export interface historyProps {
  loading: boolean;
  getRunHistoryFunc: (pageNo: number) => void;
  runHistory: Array<{
    vmsId: number;
    taskId: string;
    taskType: string;
    status: string;
    startedBy: string;
    startedAt: {
      localized: string;
      value: string;
      culture: string;
      timeZone: string;
      raw: number;
    };
    finishedAt?: undefined;
    remarks?: undefined;
  }>;
  totalRowCount: number;
  paginationModel: GridPaginationModel;
  pageSize: number;
  pageNo: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
  onFilterChange: (filterModel: GridFilterModel) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

const columns: GridColDef[] = [
  {
    field: "id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 150,
    headerName: "Run Number",
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value === "=",
    ),
    valueFormatter: (params) => params.value,
  },
  {
    field: "taskId",
    width: 150,
    headerName: "Task ID",
    align: "left",
    headerAlign: "left",
    sortable: false,
    filterable: false,
  },
  {
    field: "col1",
    headerName: "Task Type",
    width: 200,
    type: "string",
    align: "left",
    sortable: false,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    type: "singleSelect",
    renderHeader: (params) => {
      return (
        <StatusTitle title={params.colDef.headerName} detail={StatusDetails} />
      );
    },
    sortable: false,
    filterOperators: getGridSingleSelectOperators()
      .filter((operator) => operator.value === "is")
      .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent
          ? (props: GridFilterInputSingleSelectProps) => {
              return (
                <GridFilterInputSingleSelect
                  sx={{
                    width: 190,
                  }}
                  {...props}
                />
              );
            }
          : undefined,
      })),
    renderCell: (params: GridRenderEditCellParams) => {
      return <TooltipContent title={params.value} />;
    },
    valueOptions: APAndARStatuses,
  },
  {
    field: "startedAt",
    headerName: "Run Time (UTC)",
    width: 200,
    sortable: false,
    filterOperators: getGridDateOperators()
      .filter((operator) => operator.value === "is")
      .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent
          ? (props: GridFilterInputDateProps) => (
              <GridFilterInputDate {...props} variant="standard" />
            )
          : undefined,
      })),
    renderHeader: (params) => {
      return <TimeStampTitle title={params.colDef.headerName} />;
    },
  },
  {
    field: "startedBy",
    headerName: "Initiated By",
    minWidth: 300,
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "equals",
    ),
  },
  {
    field: "remarks",
    headerName: "Remarks",
    minWidth: 300,
    sortable: false,
    filterable: false,
  },
];

const Historysection: React.FC<historyProps> = ({
  runHistory,
  getRunHistoryFunc,
  totalRowCount,
  paginationModel,
  pageNo,
  pageSize,
  filterModel,
  sortModel,
  onPaginationModelChange,
  onFilterChange,
  onSortModelChange,
}) => {
  // const [totalRowCountDetails, setTotalRowCountDetails] = useState<number>(0);

  // const [paginationModelDetails, setPaginationModelDetails] =
  //   useState<GridPaginationModel>({
  //     page: 0,
  //     pageSize: 20,
  //   });
  const [rowCountState, setRowCountState] = React.useState(totalRowCount || 0);
  const enableLoader = useStore((state) => state.enableLoader);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState,
    );
  }, [totalRowCount, setRowCountState]);

  const rows: GridRowsProp = runHistory?.map((item) => {
    const isErrorStatus = item?.status?.toLowerCase()?.includes("fail");

    return {
      id: item.vmsId,
      taskId: item.taskId,
      col1: item.taskType,
      status: [
        isErrorStatus ? "ERROR" : "-",
        item.status,
        isErrorStatus ? item.remarks : "",
      ]
        .filter(Boolean)
        .join(":"),
      startedAt: item?.startedAt?.value || "",
      col6: item.status,
      startedBy: item.startedBy,
      remarks: item.remarks,
    };
  });

  return (
    <>
      <SectionConatiner
        title="Run History"
        className="w-full mx-auto mt-16 px-10 relative"
      >
        <div className="flex justify-end">
          <Refresh refresh={() => getRunHistoryFunc(pageNo)} label="Refresh" />
        </div>
        <div
          className={[
            rowCountState < pageSize
              ? "max-h-[850px]"
              : pageSize === 5
                ? "min-h-[350px] max-h-[375px] h-[60vh] 2xl:h-[45vh]"
                : "min-h-[450px] max-h-[625px] h-[70vh] 2xl:h-[65vh]",
            "mx-auto my-5 border border-[#211551] rounded-md",
          ].join(" ")}
        >
          <DataGrid
            autoHeight={rowCountState < pageSize}
            hideFooter={rowCountState < pageSize}
            loading={enableLoader}
            sortingOrder={["desc", "asc"]}
            initialState={{
              sorting: {
                sortModel: [{ field: "id", sort: "desc" }],
              },
            }}
            rowCount={rowCountState}
            rows={rows}
            columns={columns}
            slots={{ toolbar: Toolbar, loadingOverlay: Empty }}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[5, 10]}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            filterModel={filterModel}
            sortModel={sortModel}
            onFilterModelChange={onFilterChange}
            onSortModelChange={onSortModelChange}
            sx={{
              "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
                borderBottomWidth: 0,
              },
              "& .MuiDataGrid-cell:hover": {
                // color: "primary.main",
                cursor: "pointer",
              },
              "& .MuiDataGrid-toolbarContainer": {
                "& .MuiButtonBase-root": { color: "#211551" },
              },

              "& .MuiDataGrid-columnHeaders": {
                bgcolor: "#211551",
                color: "white",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiIconButton-sizeSmall": {
                  color: "white",
                },
              },
              "& .MuiDataGrid-row": { fontSize: "small" },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "4px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "10px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "17px",
              },
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
                {
                  outline: "none",
                },
            }}
            getRowHeight={() => "auto"}
            isCellEditable={() => false}
            columnHeaderHeight={44}
            disableRowSelectionOnClick
          />
        </div>
      </SectionConatiner>
    </>
  );
};

export default Historysection;
