import { GridRowId } from "@mui/x-data-grid";

import { inputOutputType } from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";

export const downloadFile = async (
  runID: GridRowId,
  fileName: string,
  type?: inputOutputType,
): Promise<void> => {
  try {
    const getRequest = InitiateInvoiceApi.getDownloadFile(runID, type);
    const getResponse = await ResponseAPI.performRequest(getRequest);

    const newBlob = new Blob([getResponse.data], {
      type: "application/octet-stream",
    });

    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", fileName);

    link.setAttribute("target", "_blank");

    document.body.appendChild(link);

    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
