import React, { SyntheticEvent } from "react";

import { dropListProps, dropdownOptionProps } from "../../@types/assetTypes/ap";
import SearchClose from "../../assets/Icons/SearchClose";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown/index";
import FileUpload from "../../components/FileUpload";
import SearchableMultiSelectDropdown from "../../components/SearchableMultiSelectDropdown";
import Textlabel from "../../components/Textlabel";
import Tooltip from "../../components/Tooltip";

interface ArvmsProps {
  isLoadingClientList: boolean;
  arrayList: dropListProps[];
  clientList: dropdownOptionProps[];
  clientsSelected: Array<number | string>;
  handleDropdownChange: (v: dropListProps) => void;
  handleClientDropdownChange: (v: number | string) => void;
  inputVal: dropListProps;
  acceptedFormats: string[];
  handleSubmit: (e: SyntheticEvent) => void;
  buttonDisabled: boolean;
  handleFileUpload: (file: File) => void;
  closeFileUploaded: () => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InitiateSection: React.FC<ArvmsProps> = ({
  isLoadingClientList,
  arrayList,
  clientList,
  clientsSelected,
  handleDropdownChange,
  handleClientDropdownChange,
  inputVal,
  acceptedFormats,
  handleFileUpload,
  handleSubmit,
  buttonDisabled,
  closeFileUploaded,
  handleCheckboxChange,
}) => {
  return (
    <div className="flex flex-col py-5 shadow-custom justify-center items-stretch">
      <div className="text-center font-bold">
        <Textlabel label="VMS AR Invoices to run" />
      </div>
      <div className="flex flex-row justify-center space-x-5 px-5">
        <form className="mt-10 max-w-2xl">
          <div className="flex flex-col items-start w-fit mx-auto gap-7">
            <div className="w-full flex gap-44 items-center">
              <Textlabel label="VMS" />
              <Dropdown
                arrayList={arrayList}
                changeHandler={handleDropdownChange}
                value={inputVal}
              />
            </div>
            <div className="w-full flex items-center">
              <Textlabel label="Clients" styleVal="mr-auto" />
              <SearchableMultiSelectDropdown
                disabled={isLoadingClientList}
                options={clientList}
                selected={clientsSelected}
                onSelect={handleClientDropdownChange}
              />
            </div>
            <div>
              <div className="flex gap-12 items-center justify-start">
                <Textlabel label="Upload VMS Input File" />
                <FileUpload
                  acceptedFormats={acceptedFormats}
                  handleFileUpload={handleFileUpload}
                />
              </div>
              {inputVal.file && (
                <div className="w-full flex justify-end items-center">
                  {inputVal.file?.name}
                  <span
                    onClick={closeFileUploaded}
                    className="font-mono font-bold cursor-pointer text-[#211551]/40 leading-4 hover:text-[#211551] ml-3"
                  >
                    x
                  </span>
                </div>
              )}
            </div>
            <div className="flex gap-20 items- justify-start">
              <div className="flex items-center">
                <Textlabel label="Fix Issues" styleVal="pr-3" />
                <Tooltip title="Mark ZERR component to 0" />
              </div>
              <input
                type="checkbox"
                className="ml-14"
                onChange={handleCheckboxChange}
                name="fixIssues"
                checked={inputVal.fixIssues || false}
              />
            </div>
            <div className="mx-auto mt-5">
              <Button
                label="Initiate AR Run"
                type="submit"
                onClick={handleSubmit}
                disabled={buttonDisabled}
              />
            </div>
          </div>
        </form>
        {clientsSelected.length > 0 && (
          <div className="hidden lg:flex flex-col mt-2.5 space-y-2 flex-1 max-w-xs max-h-[425px] flex-shrink-0">
            <div className="font-bold text-sm w-full text-center">
              Selected Clients
            </div>
            <div className="flex flex-col overflow-auto space-y-2 w-full h-full">
              {clientsSelected.map((value) => {
                const c = clientList.find((c) => c.value === value);
                const label = c?.label;

                if (!label) return null;

                return (
                  <div
                    key={value}
                    className="flex space-x-5 w-full items-center justify-between bg-gray-100 rounded px-3 py-1.5"
                  >
                    <span className="text-sm">{label}</span>
                    <SearchClose
                      className="w-3 h-3 flex-shrink-0 cursor-pointer"
                      onClick={() => handleClientDropdownChange(value)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InitiateSection;
