import React from "react";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Textlabel from "../../components/Textlabel";
import { InitiateProps } from "../AP/Initiatesection";

const Initiatesection: React.FC<InitiateProps> = ({
  isIncludeError,
  inputVal,
  handleChange,
  isExcludeError,
  inputDisabled,
  handleSubmit,
  buttonDisabled,
}) => {
  return (
    <div className="py-7 shadow-custom">
      <div className="text-center font-semibold">
        <Textlabel label="AR Invoices to run" />
      </div>
      <form className="mt-10 flex flex-col items-center gap-14 w-full">
        <div className="w-full flex justify-center">
          <Input
            nameVal="includedInvoices"
            placeholder="Invoices range and/or comma separated eg: 100-200,304"
            inputValue={inputVal.includedInvoices || ""}
            changeHandler={handleChange}
            inputTitle="Invoices to Include"
            isInputError={isIncludeError}
          />
        </div>
        <div className="w-full flex justify-center">
          <Input
            isInputError={isExcludeError}
            nameVal="excludedInvoices"
            placeholder="Invoices range and/or comma separated eg: 100-200,304"
            inputValue={inputVal.excludedInvoices || ""}
            changeHandler={handleChange}
            inputTitle="Invoices to Exclude"
            inputDisable={inputDisabled}
          />
        </div>
        <div className="mx-auto -mt-3">
          <Button
            label="Initiate AR Run"
            type="submit"
            onClick={handleSubmit}
            disabled={buttonDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default Initiatesection;
