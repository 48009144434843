import { AiOutlineFileText } from "react-icons/ai";

import BigRightArrow from "../../../assets/Icons/BigRightArrow";

const Circle: React.FC<{
  title: string;
  amount?: string;
  file?: string;
  createdOn?: string;
  uploadedOn?: string;
  noFileIcon?: boolean;
  noRightArrow?: boolean;
  type?: number;
  showLeftConnector?: boolean;
  showRightConnector?: boolean;
  boxTitle?: string;
  boxAmount?: string;
  onFileClick?: () => void;
}> = ({
  title,
  amount,
  file,
  createdOn,
  uploadedOn,
  noFileIcon = false,
  noRightArrow = false,
  type,
  showLeftConnector = false,
  showRightConnector = false,
  boxTitle,
  boxAmount,
  onFileClick,
}) => {
  let bg = "bg-[#211551]";
  let color = "text-white";

  const showBothConnector = showLeftConnector && showRightConnector;

  if (type === 1) bg = "bg-orange-400";
  else if (type === 2) bg = "bg-red-600";
  else if (type === 3) {
    bg = "bg-gray-300";
    color = "text-black";
  } else if (type === 4) bg = "bg-green-600";

  return (
    <div
      className={`flex relative ${
        noRightArrow ? "w-48" : " w-[350px]"
      } h-80 items-center`}
    >
      {showLeftConnector && (
        <div
          className={`absolute ${
            showBothConnector ? "w-[94px]" : "w-[96px]"
          } bottom-0 h-1/2 mb-6 rounded-br-xl border-r border-b border-dashed border-[#211551]`}
        ></div>
      )}
      {showRightConnector && (
        <div
          className={`absolute ${
            showBothConnector ? "left-[98px] w-[94px]" : "left-[96px] w-[96px]"
          } bottom-0 h-1/2 mb-6 rounded-bl-xl border-l border-b border-dashed border-[#211551]`}
        ></div>
      )}
      {(boxTitle || boxAmount) && (
        <div className="absolute right-0 bottom-0 w-48 flex flex-col justify-center items-center text-[#211551] bg-white border border-[#211551] p-1.5 rounded-md">
          {boxTitle && <span className="font-bold text-sm">{boxTitle}</span>}
          {boxAmount && <span className="font-bold text-sm">{boxAmount}</span>}
        </div>
      )}
      {!noRightArrow && (
        <div className="absolute flex justify-center items-center left-[167px] text-[#211551]">
          <BigRightArrow className="h-48" />
          <div
            className={[
              "absolute left-10 top-1/2 -translate-y-1/2 flex flex-col items-center justify-center",
              onFileClick
                ? "transition ease-in-out delay-50 hover:scale-110 cursor-pointer"
                : "",
            ].join(" ")}
            onClick={onFileClick}
          >
            {!noFileIcon && <AiOutlineFileText className="w-8 h-8" />}
            {file && <span className="text-center text-xs">{file}</span>}
          </div>
        </div>
      )}
      <div className="relative flex justify-center items-center rounded-full w-48 h-48 border border-dashed border-[#211551] bg-white text-white text-center">
        <div
          className={`flex flex-col justify-center items-center rounded-full w-40 h-40 p-2.5 ${bg} ${color} border border-black`}
        >
          <span>{title}</span>
          {amount && <span className="font-bold">{amount}</span>}
          {createdOn && (
            <span className="text-xs">
              created on <br />
              {createdOn}
            </span>
          )}
          {uploadedOn && (
            <span className="text-xs">
              uploaded on <br />
              {uploadedOn}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Circle;
