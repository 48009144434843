import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid";
import * as React from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import {
  dropdownFieldsDataProps,
  fieldMapProps,
  validFieldPatternsProps,
} from "../../@types/assetTypes/ap";
import DeleteExpenseView from "../DeleteExpenseView";
import DuplicateExpenseView from "../DuplicateExpenseView";
import ConfirmModal from "../Modalwindow";
import QuickSearchToolbar from "../SearchToolbar";
import UpdateExpenseView from "../UpdateExpenseView";

interface TableProps {
  suffix: string;
  cols: GridColDef[];
  rows: GridRowsProp;
  primaryField: string;
  mandatoryFields: string[];
  upperCaseFields?: string[];
  hideFields: string[];
  hideAddFields?: string[];
  disableUpdateFields?: string[];
  hideAdd?: boolean;
  hideDelete?: boolean;
  disableAdd?: boolean;
  disableCSV?: boolean;
  duplicateCheckFields?: string[];
  dropdownFieldsData?: dropdownFieldsDataProps[];
  fieldMap?: fieldMapProps;
  validFieldPatterns?: validFieldPatternsProps;
  isShowActionsColumn?: boolean;
  csvFileName?: string;
  onRowIdChange?: (rowId: GridRowId | null) => void;
  onUpdate?: (
    rowId: GridRowId | null,
    data: { [k: string]: string | object },
  ) => Promise<void>;
  onDelete?: (id: GridRowId) => Promise<void>;
  HeaderComponent?: JSX.Element;
  FooterComponent?: JSX.Element;
}

const Table: React.FC<TableProps> = ({
  suffix,
  rows,
  cols,
  primaryField,
  mandatoryFields,
  hideFields,
  hideAddFields,
  upperCaseFields,
  disableUpdateFields,
  hideAdd,
  hideDelete,
  disableAdd,
  disableCSV,
  duplicateCheckFields,
  dropdownFieldsData,
  fieldMap,
  validFieldPatterns,
  isShowActionsColumn,
  csvFileName,
  onRowIdChange,
  onUpdate,
  onDelete,
  HeaderComponent,
  FooterComponent,
}) => {
  const [rowId, setRowId] = React.useState<GridRowId>("");
  const [deleteRowId, setDeleteRowId] = React.useState<GridRowId>("");
  const [duplicateRowId, setDuplicateRowId] = React.useState<GridRowId>("");
  const rowIdRef = React.useRef<GridRowId | null>(null);
  const updateRef = React.useRef<{ [k: string]: string | object } | null>(null);

  const rowToUpdate: GridValidRowModel | undefined | null =
    updateRef.current || rows.find(({ id }) => rowId === id);

  const rowToDelete: GridValidRowModel | undefined = rows.find(
    ({ id }) => deleteRowId === id,
  );

  const rowDuplicate: GridValidRowModel | undefined = rows.find(
    ({ id }) => duplicateRowId === id,
  );

  const rowToDeletePrimaryField = rowToDelete?.[primaryField];
  const rowDuplicatePrimaryField = rowDuplicate?.[primaryField];
  const field = cols.find(({ field: f }) => f === primaryField);

  let deleteKey;
  let duplicateKey;

  if (typeof rowToDeletePrimaryField === "object") {
    if (field?.type === "multi-select-dropdown")
      deleteKey = rowToDeletePrimaryField?.join("") || "";
    else if (field?.type === "search-dropdown")
      deleteKey = rowToDeletePrimaryField?.value || "";
    else deleteKey = rowToDeletePrimaryField?.id || "";
  } else {
    deleteKey = rowToDeletePrimaryField || "";
  }

  if (typeof rowDuplicatePrimaryField === "object") {
    if (field?.type === "multi-select-dropdown")
      duplicateKey = rowDuplicatePrimaryField?.join("") || "";
    else if (field?.type === "search-dropdown")
      duplicateKey = rowDuplicatePrimaryField?.value || "";
    else duplicateKey = rowDuplicatePrimaryField?.id || "";
  } else {
    duplicateKey = rowDuplicatePrimaryField || "";
  }

  const _dropdownFieldsData = [...(dropdownFieldsData || [])];

  const i = _dropdownFieldsData?.findIndex(
    ({ field }) => field === "pairedSpecialExpense",
  );

  if (i > -1) {
    const pseDropdown = { ..._dropdownFieldsData[i] };

    pseDropdown.data = pseDropdown.data?.filter(
      ({ id }) => id !== rowToUpdate?.id,
    );

    _dropdownFieldsData.splice(i, 1, pseDropdown);
  }

  const handleAddClick = (): void => {
    updateRef.current = null;
    setRowId("__open__");
  };

  const handleEditClick = (id: GridRowId) => () => {
    updateRef.current = null;
    setRowId(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    updateRef.current = null;
    setDeleteRowId(id);
  };

  const handleUpdate =
    (rowId: GridRowId | null) =>
    (data: { [k: string]: string | object }): Promise<void> => {
      const getDuplicateCheckFieldsValue = (
        row: GridValidRowModel,
      ): string[] | undefined => {
        return duplicateCheckFields?.map((field) => {
          if (typeof row[field] === "object") {
            const f = cols.find(({ field: f }) => f === primaryField);

            if (f?.type === "multi-select-dropdown") {
              const value = row[field] as Array<number | string>;

              return value.join("");
            } else if (f?.type === "search-dropdown") {
              const value = row[field] as { value: string };

              return value.value;
            } else {
              const value = row[field] as { id: string };

              return value.id;
            }
          } else {
            return row[field];
          }
        });
      };

      const duplicateRow: GridValidRowModel | undefined = rows.find((r) => {
        if (rowId === r.id) return false;
        const current = getDuplicateCheckFieldsValue(r)?.join("");
        const selected = getDuplicateCheckFieldsValue(data)?.join("");

        if (!current || !selected) return false;

        return current === selected;
      });

      updateRef.current = data;
      rowIdRef.current = rowId;

      if (duplicateRow) {
        setRowId("");
        setDuplicateRowId(duplicateRow?.id || "");

        return Promise.resolve();
      } else if (onUpdate) {
        return onUpdate(rowId === "__open__" ? null : rowId, data)
          .then(() => {
            updateRef.current = null;
            rowIdRef.current = null;
            setRowId("");
          })
          .catch(() => {});
      } else {
        return Promise.resolve();
      }
    };

  const handleUpdateYes = (rowId: GridRowId | null) => (): Promise<void> => {
    if (onUpdate) {
      return onUpdate(rowId, updateRef.current || {})
        .then(() => {
          updateRef.current = null;
          rowIdRef.current = null;
          setDuplicateRowId("");
        })
        .catch(() => {});
    } else {
      return Promise.resolve();
    }
  };

  const handleUpdateNo = (): void => {
    setRowId(rowIdRef.current || "__open__");
    setDuplicateRowId("");
  };

  const handleDelete = (): Promise<void> => {
    if (onDelete) {
      return onDelete(deleteRowId)
        .then(() => {
          setDeleteRowId("");
        })
        .catch(() => {});
    } else {
      return Promise.resolve();
    }
  };

  const columns = cols;

  const columnsWithActionColumn = [
    ...cols,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params: { id: string }): JSX.Element[] => {
        const { id } = params;

        const a = [
          <IconButton
            key={`${id}-edit`}
            size="small"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          >
            <MdEdit />
          </IconButton>,
        ];

        if (!hideDelete) {
          a.push(
            <IconButton
              key={`${id}-delete`}
              onClick={handleDeleteClick(id)}
              color="inherit"
            >
              <FaTrash fontSize="small" />
            </IconButton>,
          );
        }

        return a;
      },
    },
  ];

  React.useEffect(() => {
    if (onRowIdChange) onRowIdChange(rowId);
  }, [rowId, onRowIdChange]);

  return (
    <div className="w-full space-y-5">
      <div className="flex items-center justify-end">
        {HeaderComponent}
        {isShowActionsColumn && !hideAdd && (
          <button
            className="ml-5 my-1 cursor-pointer text-[white] text-sm font-bold w-fit flex items-center rounded px-3 py-1 bg-[#211551] space-x-2 disabled:bg-opacity-20 disabled:cursor-default"
            onClick={handleAddClick}
            disabled={disableAdd}
          >
            <span>Add {suffix}</span>
            <FaPlus />
          </button>
        )}
      </div>
      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: primaryField, sort: "asc" }],
            },
            pagination: { paginationModel: { page: 0, pageSize: 20 } },
          }}
          rows={rows}
          columns={isShowActionsColumn ? columnsWithActionColumn : columns}
          slots={{
            toolbar: () => (
              <QuickSearchToolbar
                processName={csvFileName || suffix}
                disableCSV={disableCSV}
              />
            ),
          }}
          pageSizeOptions={[20, 50, 100]}
          pagination
          sx={{
            "& .MuiDataGrid-cell:hover": {
              // color: "primary.main",
              cursor: "pointer",
            },
            "& .MuiDataGrid-toolbarContainer": {
              "& .MuiButtonBase-root": { color: "#211551" },
            },

            "& .MuiDataGrid-columnHeaders": {
              bgcolor: "#211551",
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              "& .MuiIconButton-sizeSmall": {
                color: "white",
              },
            },
            "& .MuiDataGrid-row": { fontSize: "small" },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "4px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "10px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "17px",
            },
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none",
              },
          }}
          getRowHeight={() => "auto"}
          isCellEditable={() => false}
          columnHeaderHeight={44}
          disableRowSelectionOnClick
        />
        {rowId !== "" && (
          <ConfirmModal closeModal={() => setRowId("")}>
            <UpdateExpenseView
              title={rowId === "__open__" ? `Add ${suffix}` : `Edit ${suffix}`}
              fields={columns}
              mandatoryFields={mandatoryFields}
              upperCaseFields={upperCaseFields}
              hideFields={
                rowId === "__open__"
                  ? [...hideFields, ...(hideAddFields || [])]
                  : hideFields
              }
              disableFields={rowId === "__open__" ? [] : disableUpdateFields}
              dropdownFieldsData={_dropdownFieldsData}
              fieldMap={fieldMap}
              validFieldPatterns={validFieldPatterns}
              data={rowToUpdate}
              onClose={() => setRowId("")}
              onSubmit={handleUpdate(rowId)}
            />
          </ConfirmModal>
        )}
        {deleteRowId !== "" && (
          <ConfirmModal closeModal={() => setDeleteRowId("")}>
            <DeleteExpenseView
              title={`Delete ${suffix}`}
              description={[
                `Are you sure you want to delete`,
                deleteKey ? ` "${deleteKey}"` : "",
                "?",
              ].join("")}
              onClose={() => setDeleteRowId("")}
              onSubmit={handleDelete}
            />
          </ConfirmModal>
        )}
        {duplicateRowId !== "" && (
          <ConfirmModal closeModal={handleUpdateNo}>
            <DuplicateExpenseView
              title={`Duplicate Found`}
              description={[
                duplicateKey ? ` "${duplicateKey}"` : "",
                columns.length > 1
                  ? `is existing, would you like to update?`
                  : `is already present.`,
              ].join(" ")}
              onClose={handleUpdateNo}
              onYes={
                columns.length > 1 ? handleUpdateYes(duplicateRowId) : undefined
              }
              onNo={handleUpdateNo}
              noText={columns.length > 1 ? undefined : "Ok"}
            />
          </ConfirmModal>
        )}
      </Box>
      {FooterComponent}
    </div>
  );
};

export default Table;
