import { Switch } from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";

import {
  clientListProps,
  dropListProps,
  dropdownFieldsDataProps,
  dropdownOptionProps,
  feeSplitConfigUpdate,
  fieldMapProps,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import ArrowDown from "../../assets/Icons/ArrowDown";
import { checkIfAdmin } from "../../auth/validateUser";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import DuplicateExpenseView from "../../components/DuplicateExpenseView";
import ConfirmModal from "../../components/Modalwindow";
import SearchableDropdown from "../../components/SearchableDropdown";
import SectionConatiner from "../../components/SectionConatiner";
import SpinnerComponent from "../../components/Spinner";
import Table from "../../components/Table";
import Textlabel from "../../components/Textlabel";
import ToastComponent from "../../components/Toast";
import useConfig, { Data, Row } from "../../hooks/useConfig";
import useStore from "../../store/useStore";
import { formatHour } from "../../utils";

const DEFAULT_TEMPLATE_NAME = "Invoice_Template_Default.docx";

const columns: GridColDef[] = [
  {
    field: "splitName",
    headerName: "Split Name",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 250,
    flex: 1,
    type: "textarea",
  },
  {
    field: "placement",
    headerName: "Split Placement",
    minWidth: 200,
    flex: 1,
    type: "dropdown",
    valueGetter: (params) => params?.value?.name || "",
  },
  {
    field: "codes",
    headerName: "Condition Codes",
    minWidth: 200,
    flex: 1,
    type: "multi-select-dropdown",
    valueGetter: (params) => params?.value?.join(", ") || "",
  },
  {
    field: "template",
    headerName: "Template",
    minWidth: 250,
    flex: 1,
    type: "search-dropdown",
    valueGetter: (params) => params?.value?.label || "",
  },
  {
    field: "lastUpdatedBy",
    headerName: "Last Updated by",
    minWidth: 300,
    flex: 1,
  },
  {
    field: "lastUpdatedOn",
    headerName: "Last Updated on",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "enableCode",
    headerName: "Enabled",
    width: 120,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    align: "center",
    disableExport: true,
  },
];

interface IBatchList {
  batchId: number;
  description: string;
  frequencyInHours: number;
  nextRunAt: string;
}

interface ITemplateList {
  templateName: string;
}

interface IConditionCodeList {
  attributionCode: string;
}

const FeeSplittingConfig: React.FC = () => {
  const { data, setData, handleError, handleResponse } =
    useConfig("feeSplitConfig");

  const enableToast = useStore((state) => state.enableToast);
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const [clientList, setClientList] = useState<clientListProps[]>([]);
  const [client, setClient] = useState<clientListProps | null>(null);

  const clientDropdown: dropdownOptionProps[] = clientList.map((c) => ({
    value: c.s4CompanyId,
    label: c.name,
  }));

  const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);
  const [batchList, setBatchList] = useState<IBatchList[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateList[]>([]);

  const [conditionCodeList, setConditionCodeList] = useState<
    IConditionCodeList[]
  >([]);

  const [newClient, setNewClient] = useState<boolean>(true);
  const [batchId, setBatchId] = useState<number | undefined>(undefined);

  const [templateName, setTemplateName] = useState<string | undefined>(
    undefined,
  );

  const [isShowBatchIDConfirmation, setShowBatchIDConfirmation] =
    useState<boolean>(false);

  const isAdmin = checkIfAdmin();
  const prevBatchIdRef = useRef(undefined);
  const prevTemplateNameRef = useRef(undefined);
  const title = "Invoice Splitting";

  const mandatoryFields: string[] = [
    "splitName",
    "placement",
    "codes",
    "template",
  ];

  const upperCaseFields: string[] = ["codes"];
  const hideFields: string[] = ["enableCode", "lastUpdatedBy", "lastUpdatedOn"];
  const disableUpdateFields: string[] = ["splitName"];
  const duplicateCheckFields: string[] = ["splitName"];
  const batch = batchList.find((b) => b.batchId === batchId) || null;

  const isUpdateButtonDisabled =
    batchId === undefined ||
    (prevBatchIdRef.current === batchId &&
      prevTemplateNameRef.current === templateName);

  const enableCodeColumn = columns.find((c) => c.field === "enableCode");

  if (enableCodeColumn) {
    enableCodeColumn.renderCell = (params) => {
      const onChange = (e: { target: { checked: boolean } }): void => {
        updateData(client)(params.row.id, {
          ...params.row,
          enableCode: e.target.checked,
        });
      };

      return (
        <Switch
          disabled={!isAdmin}
          checked={params.value}
          onChange={onChange}
        />
      );
    };
  }

  const batchListDropdown: dropListProps[] = batchList.map((b) => ({
    id: b.batchId.toString(),
    name: [b.batchId, b.description].filter(Boolean).join(" - "),
  }));

  const templateListDropdown: Array<{ value: string; label: string }> =
    templateList.map((t) => ({
      value: t.templateName,
      label: t.templateName,
    }));

  const splitPlacementDropdown: Array<{ id: string; name: string }> = [
    {
      id: "WITHIN_INVOICE",
      name: "Within Same Invoice",
    },
    {
      id: "AGGREGATE_INVOICE",
      name: "Aggregate Invoice",
    },
  ];

  const otherConditionCodes: string[] = [];

  data.forEach((d) => {
    const codes = d.codes as string[];

    if (d.id !== selectedRowId) {
      codes.forEach((c) => {
        if (!otherConditionCodes.includes(c)) {
          otherConditionCodes.push(c);
        }
      });
    }
  });

  const conditionCodesDropdown: dropdownOptionProps[] = conditionCodeList
    .filter((c) => {
      return !otherConditionCodes.includes(c.attributionCode);
    })
    .map((c) => ({ value: c.attributionCode, label: c.attributionCode }));

  const dropdownFieldsData: dropdownFieldsDataProps[] = [
    {
      field: "placement",
      data: splitPlacementDropdown,
    },
    {
      field: "codes",
      data1: conditionCodesDropdown,
    },
    {
      field: "template",
      mapToField: "placement",
      data1: templateListDropdown,
      defaultId: (inputs: {
        [k: string]: string | object | Array<number | string>;
      }) => {
        const placementInput = inputs.placement as { id?: string };

        return placementInput?.id === "WITHIN_INVOICE"
          ? "None"
          : DEFAULT_TEMPLATE_NAME;
      },
    },
  ];

  const fieldMap: fieldMapProps = {
    "": { template: ["None"], hide: true },
  };

  splitPlacementDropdown.forEach((s) => {
    if (s.id === "WITHIN_INVOICE") {
      fieldMap[s.id] ??= { template: ["None"], hide: true };
    } else {
      fieldMap[s.id] ??= { template: templateListDropdown.map((t) => t.label) };
    }
  });

  const dataToRow = (data: Data[]): Row[] => {
    return data.map((e: Data): Row => {
      const lastUpdatedDate = e.lastUpdatedDate as {
        value: string;
      };

      const template = e.template as {
        templateName: string;
      };

      const templateName = template?.templateName;

      return {
        id: e.splitId as number,
        splitName: e.splitName as string,
        description: e.description as string,
        enableCode: e.enabled as boolean,
        placement:
          splitPlacementDropdown.find((p) => p.id === e.splitPlacement) || {},
        codes: e.conditionCodes as string[],
        template:
          templateListDropdown.find((t) => t.label === templateName) ||
          templateName
            ? { value: templateName, label: templateName }
            : {},
        lastUpdatedBy: e.lastUpdatedBy as string,
        lastUpdatedOn: lastUpdatedDate?.value,
      };
    });
  };

  const getClientList = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getClientList();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse?.data?.errors?.length === 0) {
        const clientList = (getResponse?.data?.data?.clients || [])
          ?.filter((c: clientListProps) => !!c.s4CompanyId)
          ?.sort((a: clientListProps, b: clientListProps) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });

        setClientList(clientList);
      } else {
        enableToast({
          message:
            getResponse?.data?.errors?.[0]?.message || "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setClientList([]);
    }
  };

  const getBatchList = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getBatchList({
        isFilterEnabled: true,
      });

      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse?.data?.errors?.length === 0) {
        setBatchList(getResponse?.data?.data || []);
      } else {
        setBatchList([]);
        enableToast({
          message:
            getResponse?.data?.errors?.[0]?.message || "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setBatchList([]);
    }
  };

  const getTemplateList = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getTemplateList();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse?.data?.errors?.length === 0) {
        setTemplateList(getResponse?.data?.data || []);
      } else {
        setTemplateList([]);
        enableToast({
          message:
            getResponse?.data?.errors?.[0]?.message || "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setTemplateList([]);
    }
  };

  const getConditionCodeList = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getConditionCodes();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse?.data?.errors?.length === 0) {
        const conditionCodeList = getResponse?.data?.data?.attribution?.sort(
          (a: IConditionCodeList, b: IConditionCodeList) => {
            const nameA = a.attributionCode.toUpperCase();
            const nameB = b.attributionCode.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            return 0;
          },
        );

        setConditionCodeList(conditionCodeList || []);
      } else {
        enableToast({
          message:
            getResponse?.data?.errors?.[0]?.message || "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClientDropdownChange = (d: dropdownOptionProps): void => {
    setClient(clientList.find((c) => c.s4CompanyId === d.value) || null);

    if (!d.value && !d.label) {
      setNewClient(true);
      setBatchId(undefined);
      setTemplateName(undefined);
      prevBatchIdRef.current = undefined;
      prevTemplateNameRef.current = undefined;
    }
  };

  const handleBatchDropdownChange = (d: dropListProps): void => {
    setBatchId(d.id ? Number(d.id) : undefined);
  };

  const handleTemplateDropdownChange = (d: dropdownOptionProps): void => {
    setTemplateName(d.label);
  };

  const updateBatchTemplateData =
    (client: clientListProps | null) => (): Promise<void> => {
      setLoader(true);

      const _updateState: feeSplitConfigUpdate = {
        s4CompanyId: client?.s4CompanyId,
        s4CompanyName: client?.name,
        batchId,
        templateName,
      };

      const isNewTemplate =
        templateList.findIndex((t) => t.templateName === templateName) === -1;

      const updateRequest = InitiateInvoiceApi.batchTemplateFeeSplitConfig(
        "PUT",
        _updateState,
      );

      return ResponseAPI.performRequest(updateRequest)
        .then((res) => {
          const { batch, template } = res?.data?.data || {};
          const batchId = batch?.batchId;

          setNewClient(batchId === undefined);
          setBatchId(batchId);
          setTemplateName(template?.templateName);
          prevBatchIdRef.current = batchId;
          prevTemplateNameRef.current = template?.templateName;

          if (isNewTemplate)
            setTemplateList((templateList) => [...templateList, template]);

          enableToast({
            message: "Updated successfully!",
            type: "success",
          });
          setLoader(false);
          setShowBatchIDConfirmation(false);
        })
        .catch(handleError);
    };

  const getData = (clientId: string): Promise<void> => {
    const getRequest = InitiateInvoiceApi.batchTemplateFeeSplitConfig(
      "GET",
      null,
      clientId,
    );

    return ResponseAPI.performRequest(getRequest)
      .then((res) => {
        const data = res?.data?.data || {};
        const { batch, template } = data;
        const batchId = batch?.batchId;

        setNewClient(batchId === undefined);
        setBatchId(batchId);
        setTemplateName(template?.templateName || DEFAULT_TEMPLATE_NAME);
        prevBatchIdRef.current = batchId;
        prevTemplateNameRef.current = template?.templateName;

        res.data = { data };

        return res;
      })
      .then(handleResponse("__get__", dataToRow, ""))
      .catch(handleError);
  };

  const updateData =
    (client?: clientListProps | null) =>
    (rowId: string | number | null, data: Data): Promise<void> => {
      if (!client) return Promise.reject(new Error("Missing Client ID"));
      setLoader(true);

      const { s4CompanyId } = client;
      const splitName = data.splitName as string;
      const description = data.description as string;
      const conditionCodes = data.codes as string[];
      const splitPlacement = data.placement as { id: string };
      const template = data.template as { label: string };
      const templateName = template.label;
      const enabled = data.enableCode as boolean;

      const _updateState: feeSplitConfigUpdate = {
        s4CompanyId,
        splitName,
        description,
        conditionCodes,
        splitPlacement: splitPlacement.id,
        templateName,
        enabled,
      };

      if (rowId) _updateState.splitId = rowId as number;
      else _updateState.enabled = true;

      const isNewTemplate =
        templateList.findIndex((t) => t.templateName === templateName) === -1;

      const updateRequest = InitiateInvoiceApi.feeSplitConfig(
        "PUT",
        _updateState,
      );

      return ResponseAPI.performRequest(updateRequest)
        .then(
          handleResponse(
            rowId ? "__update__" : "__add__",
            dataToRow,
            `${rowId ? "Updated" : "Added"} successfully!`,
            { rowId },
          ),
        )
        .then((res) => {
          if (isNewTemplate)
            setTemplateList((templateList) => [
              ...templateList,
              { templateName },
            ]);

          return res;
        })
        .catch(handleError);
    };

  const deleteData =
    (client: clientListProps | null) =>
    async (feeSplitId: string | number): Promise<void> => {
      setLoader(true);

      const clientId = client?.s4CompanyId || "";

      const deleteRequest = InitiateInvoiceApi.feeSplitConfig(
        "DELETE",
        null,
        feeSplitId,
        clientId,
      );

      return await ResponseAPI.performRequest(deleteRequest)
        .then(handleResponse("__delete__", dataToRow, "Deleted successfully!"))
        .catch(handleError);
    };

  useEffect(() => {
    setLoader(true);
    Promise.all([
      getClientList(),
      getBatchList(),
      getTemplateList(),
      getConditionCodeList(),
    ]).finally(() => setLoader(false));
  }, []);

  useEffect(() => {
    if (client?.s4CompanyId) {
      setLoader(true);
      getData(client.s4CompanyId).finally(() => setLoader(false));
    } else {
      setNewClient(true);
      setBatchId(undefined);
      setTemplateName(undefined);
      setData([]);
      prevBatchIdRef.current = undefined;
      prevTemplateNameRef.current = undefined;
    }
  }, [client?.s4CompanyId, client?.id]);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <SectionConatiner
        title={
          <div className="flex gap-1.5 items-center">
            <span>Invoice Presentation</span>
            <ArrowDown className="-rotate-90" fill="currentColor" />
            <span>Client Config</span>
          </div>
        }
        className="w-full mx-auto px-10 py-5 relative"
      >
        <div className="flex items-center justify-end mb-6">
          <Textlabel label="Client:" />
          <SearchableDropdown
            options={clientDropdown}
            onSelect={handleClientDropdownChange}
            value={
              client?.s4CompanyId
                ? {
                    value: client.s4CompanyId,
                    label: client.name,
                  }
                : {
                    value: "",
                    label: "",
                  }
            }
          />
        </div>
        <div className="flex flex-col py-5 shadow-custom justify-center items-stretch rounded mb-12">
          <div className="max-w-2xl mx-auto">
            <div className="flex flex-col items-start w-fit mx-auto gap-7 py-2">
              <div className="w-full flex gap-44 items-center">
                <Textlabel label="Batch ID" />
                <Dropdown
                  arrayList={batchListDropdown}
                  changeHandler={handleBatchDropdownChange}
                  value={
                    batchListDropdown.find(
                      (b) => b.id === batchId?.toString(),
                    ) || {}
                  }
                  disabled={!isAdmin || !client?.s4CompanyId}
                />
              </div>
              <div className="w-full flex items-center">
                <Textlabel
                  label="Report Generation Frequency"
                  styleVal="mr-auto"
                />
                <Textlabel
                  label={formatHour(batch?.frequencyInHours || null)}
                  styleVal="w-96 px-0"
                />
              </div>
              <div className="w-full flex items-center">
                <Textlabel label="Next run at" styleVal="mr-auto" />
                <Textlabel
                  label={batch?.nextRunAt || ""}
                  styleVal="w-96 px-0"
                />
              </div>
              <div className="w-full flex items-center">
                <Textlabel label="Template" styleVal="mr-auto" />
                <div className="relative w-96 flex space-x-4 items-center">
                  <SearchableDropdown
                    options={templateListDropdown || []}
                    onSelect={handleTemplateDropdownChange}
                    value={
                      templateListDropdown.find(
                        (t) => t.label === templateName,
                      ) || {
                        value: templateName || "",
                        label: templateName || "",
                      }
                    }
                    fullWidth
                    disabled={!isAdmin || !client?.s4CompanyId}
                    allowCustomValue
                  />
                </div>
              </div>
              <Button
                label="Update"
                type="submit"
                styleVal="w-28 mx-auto mt-5 disabled:cursor-not-allowed"
                onClick={
                  (prevBatchIdRef.current === undefined &&
                    batchId !== undefined) ||
                  prevBatchIdRef.current === batchId
                    ? updateBatchTemplateData(client)
                    : () => setShowBatchIDConfirmation(true)
                }
                disabled={!isAdmin || isUpdateButtonDisabled}
              />
            </div>
          </div>
        </div>
        <SectionConatiner
          title={title}
          className="w-full mx-auto px-10 relative"
        >
          <Table
            suffix="Split"
            cols={columns}
            rows={data}
            primaryField="splitName"
            mandatoryFields={mandatoryFields}
            upperCaseFields={upperCaseFields}
            hideFields={hideFields}
            disableUpdateFields={disableUpdateFields}
            disableAdd={!client?.s4CompanyId || newClient}
            disableCSV={!client?.s4CompanyId || newClient}
            duplicateCheckFields={duplicateCheckFields}
            dropdownFieldsData={dropdownFieldsData}
            fieldMap={fieldMap}
            isShowActionsColumn={isAdmin}
            csvFileName={title}
            onRowIdChange={setSelectedRowId}
            onUpdate={updateData(client)}
            onDelete={deleteData(client)}
          />
        </SectionConatiner>
      </SectionConatiner>
      {isShowBatchIDConfirmation && (
        <ConfirmModal closeModal={() => setShowBatchIDConfirmation(false)}>
          <DuplicateExpenseView
            title="Confirm Batch ID Change"
            description={`Batch ID has been changed to "${[
              batchId,
              batch?.description,
            ]
              .filter(Boolean)
              .join(" - ")}". Would you like to proceed?`}
            yesText="Proceed"
            noText="Cancel"
            onYes={updateBatchTemplateData(client)}
            onNo={() => setShowBatchIDConfirmation(false)}
            onClose={() => setShowBatchIDConfirmation(false)}
          />
        </ConfirmModal>
      )}
    </>
  );
};

export default FeeSplittingConfig;
