import { includesStatus } from "../../../utils";

import Circle from "./Circle";

const Flow: React.FC<{
  vmsTotal: string;
  vmsEbdrDelta: string;
  vmsFileName: string;
  ebdrTotal: string;
  ebdrS4bdrDelta: string;
  ebdrFileName: string;
  s4BdrTotal: string;
  uploadedOn: string;
  dbStatus: string;
  onDownloadVMSFile: (fileName: string) => void;
  onDownloadEBDRFile: (fileName: string) => void;
}> = ({
  vmsTotal,
  vmsEbdrDelta,
  vmsFileName,
  ebdrTotal,
  ebdrS4bdrDelta,
  ebdrFileName,
  s4BdrTotal,
  uploadedOn,
  dbStatus,
  onDownloadVMSFile,
  onDownloadEBDRFile,
}) => {
  const getVMSInfo = (): {
    title: string;
    amount: string | undefined;
    file: string;
    noFileIcon: boolean;
    showRightConnector: boolean;
    boxTitle: string | undefined;
    boxAmount: string | undefined;
  } => {
    if (vmsFileName) {
      return {
        title: "VMS Input",
        amount: vmsTotal,
        file: "VMS File",
        noFileIcon: false,
        showRightConnector: !!vmsEbdrDelta,
        boxTitle: vmsEbdrDelta ? "Delta VMS to EBDR" : undefined,
        boxAmount: vmsEbdrDelta,
      };
    } else {
      return {
        title: "Non VMS Input",
        amount: vmsTotal,
        file: "Non VMS File",
        noFileIcon: true,
        showRightConnector: !!vmsEbdrDelta,
        boxTitle: vmsEbdrDelta ? "Delta Non VMS to EBDR" : undefined,
        boxAmount: vmsEbdrDelta,
      };
    }
  };

  const getEBDRInfo = (): {
    showRightConnector: boolean;
    boxTitle: string | undefined;
    boxAmount: string | undefined;
  } => {
    if (
      includesStatus(["BDR_CREATED_WITH_ERRORS"], dbStatus) ||
      !ebdrS4bdrDelta
    ) {
      return {
        showRightConnector: false,
        boxTitle: undefined,
        boxAmount: undefined,
      };
    }

    return {
      showRightConnector: true,
      boxTitle: "Delta EBDR to S/4 BDR",
      boxAmount: ebdrS4bdrDelta,
    };
  };

  const getS4Info = (): {
    title: string;
    amount: string | undefined;
    createdOn: string | undefined;
    type: number | undefined;
  } => {
    if (includesStatus(["BDR_CREATED_WITH_ERRORS"], dbStatus)) {
      return {
        title: "S/4 BDR Creation Failed",
        amount: undefined,
        createdOn: undefined,
        type: 2,
      };
    }

    if (includesStatus(["BDR_DELETE_INITIATED"], dbStatus)) {
      return {
        title: "S/4 BDR Delete Initiated",
        amount: undefined,
        createdOn: undefined,
        type: 1,
      };
    }

    if (includesStatus(["BDR_DELETED_PARTIALLY"], dbStatus)) {
      return {
        title: "S/4 BDR Partially Deleted",
        amount: undefined,
        createdOn: undefined,
        type: 2,
      };
    }

    if (includesStatus(["BDR_DELETED"], dbStatus)) {
      return {
        title: "S/4 BDR Deleted",
        amount: undefined,
        createdOn: undefined,
        type: 2,
      };
    }

    return {
      title: "S/4 BDR",
      amount: s4BdrTotal,
      createdOn: uploadedOn,
      type: undefined,
    };
  };

  const getInvoiceInfo = (): {
    title: string;
    type: number | undefined;
  } => {
    if (
      includesStatus(
        [
          "BDR_CREATED_SUCCESSFULLY",
          "BDR_DETAILS_READ_SUCCESSFULLY",
          "ERROR_IN_CALLING_BDR_READ",
          "BDR_DELETE_FAILED",
        ],
        dbStatus,
      )
    ) {
      return {
        title: "Invoice Pending",
        type: 1,
      };
    }

    if (
      includesStatus(
        [
          "BDR_CREATED_WITH_ERRORS",
          "BDR_DELETE_INITIATED",
          "BDR_DELETED_PARTIALLY",
          "BDR_DELETED",
        ],
        dbStatus,
      )
    ) {
      return {
        title: "Invoice can't be created",
        type: 3,
      };
    }

    if (
      includesStatus(
        ["INVOICE_CREATE_INITIATED", "INVOICE_CREATE_API_CALLED_SUCCESSFULLY"],
        dbStatus,
      )
    ) {
      return {
        title: "Invoice Creation In Progress",
        type: 1,
      };
    }

    if (includesStatus(["FAILED_TO_CALL_INVOICE_CREATE_API"], dbStatus)) {
      return {
        title: "Invoice Creation Failed, Retry",
        type: 2,
      };
    }

    if (includesStatus(["INVOICE_CREATED_WITH_ERRORS"], dbStatus)) {
      return {
        title: "Invoice Generated",
        type: 2,
      };
    }

    if (
      includesStatus(
        ["INVOICE_CREATED_SUCCESSFULLY", "INVOICE_CREATED_WITH_ERRORS"],
        dbStatus,
      )
    ) {
      return {
        title: "Invoice Generated",
        type: 4,
      };
    }

    return {
      title: "",
      type: undefined,
    };
  };

  const vmsInfo = getVMSInfo();
  const ebdrInfo = getEBDRInfo();
  const s4Info = getS4Info();
  const invoiceInfo = getInvoiceInfo();

  return (
    <div className="flex items-center justify-center overflow-auto w-fit mx-auto">
      <Circle
        {...vmsInfo}
        onFileClick={
          vmsFileName ? () => onDownloadVMSFile(vmsFileName) : undefined
        }
      />
      <Circle
        {...ebdrInfo}
        title="EBDR File"
        amount={ebdrTotal}
        file="EBDR File"
        uploadedOn={uploadedOn}
        noFileIcon={!ebdrFileName}
        showLeftConnector={vmsInfo.showRightConnector}
        onFileClick={
          ebdrFileName ? () => onDownloadEBDRFile(ebdrFileName) : undefined
        }
      />
      <Circle
        {...s4Info}
        file="Invoice Creation"
        noFileIcon
        showLeftConnector={ebdrInfo.showRightConnector}
      />
      <Circle
        title={invoiceInfo.title}
        createdOn={
          invoiceInfo.title === "Invoice Generated" ? uploadedOn : undefined
        }
        noRightArrow
        type={invoiceInfo.type}
      />
    </div>
  );
};

export default Flow;
