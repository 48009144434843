import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import {
  dropdownFieldsDataProps,
  dropdownOptionProps,
  fieldMapProps,
  globalConfigUpdate,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import { checkIfAdmin } from "../../auth/validateUser";
import SectionConatiner from "../../components/SectionConatiner";
import SpinnerComponent from "../../components/Spinner";
import Table from "../../components/Table";
import ToastComponent from "../../components/Toast";
import useConfig, { Data, Row } from "../../hooks/useConfig";
import useStore from "../../store/useStore";

const columns: GridColDef[] = [
  {
    field: "key",
    headerName: "Attribute Name",
    minWidth: 300,
    editable: true,
    flex: 1,
    type: "search-dropdown",
    valueGetter: (params) => params?.value?.label || "",
  },
  {
    field: "value",
    headerName: "Attribute Value",
    minWidth: 300,
    editable: true,
    flex: 1,
    type: "search-dropdown",
    valueGetter: (params) => params?.value?.label || "",
  },
  {
    field: "type",
    headerName: "Attribute Type",
    minWidth: 300,
    editable: true,
    flex: 1,
    type: "search-dropdown",
    valueGetter: (params) => params?.value?.label || "",
  },
  {
    field: "description",
    headerName: "Attribute Description",
    minWidth: 300,
    editable: true,
    flex: 1,
  },
  {
    field: "lastUpdatedBy",
    headerName: "Last Updated by",
    minWidth: 300,
    editable: false,
    flex: 1,
  },
  {
    field: "lastUpdatedOn",
    headerName: "Last Updated on",
    minWidth: 200,
    editable: false,
    flex: 1,
  },
];

const GlobalConfig: React.FC = () => {
  const { data, handleError, handleResponse } = useConfig("globalConfig");
  const enableToast = useStore((state) => state.enableToast);
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const [metadata, setMetadata] = useState([]);
  const isAdmin = checkIfAdmin();
  const mandatoryFields: string[] = ["key", "value", "type"];
  const hideFields: string[] = ["lastUpdatedBy", "lastUpdatedOn"];
  const disableUpdateFields: string[] = ["key"];
  const duplicateCheckFields: string[] = [];

  const attributeNameDropdown: dropdownOptionProps[] = [
    ...new Set(metadata.map((obj: { name: string }) => obj.name)),
  ]
    .filter(Boolean)
    .map((name) => ({
      value: name,
      label: name,
    }));

  const attributeValueDropdown: dropdownOptionProps[] = [
    ...new Set(metadata.map((obj: { value: string }) => obj.value)),
  ]
    .filter(Boolean)
    .map((value) => ({
      value,
      label: value,
    }));

  const attributeTypeDropdown: dropdownOptionProps[] = [
    ...new Set(metadata.map((obj: { type: string }) => obj.type)),
  ]
    .filter(Boolean)
    .map((type) => ({
      value: type,
      label: type,
    }));

  const dropdownFieldsData: dropdownFieldsDataProps[] = [
    {
      field: "key",
      data1: attributeNameDropdown,
    },
    {
      field: "value",
      mapToField: "key",
      data1: attributeValueDropdown,
    },
    {
      field: "type",
      mapToField: "key",
      data1: attributeTypeDropdown,
    },
  ];

  const fieldMap: fieldMapProps = {};

  metadata.forEach((obj: { name: string; value: string; type: string }) => {
    fieldMap[obj.name] ??= { value: [], type: [] };
    (fieldMap[obj.name].value as string[]).push(obj.value || "");
    (fieldMap[obj.name].type as string[]).push(obj.type || "");
  });

  const dataToRow = (data: Data[]): Row[] => {
    return data.map((e: Data): Row => {
      const lastUpdatedDate = e.lastUpdatedDate as {
        value: string;
      };

      return {
        id: e.id as string,
        key: { value: e.key, label: e.key },
        value: { value: e.value, label: e.value },
        type: { value: e.type, label: e.type },
        description: e.description as string,
        lastUpdatedBy: e.lastUpdatedBy as string,
        lastUpdatedOn: lastUpdatedDate.value,
      };
    });
  };

  const getData = (): Promise<void> => {
    const getRequest = InitiateInvoiceApi.importGlobalConfig("GET", null, null);

    return ResponseAPI.performRequest(getRequest)
      .then(handleResponse("__get__", dataToRow, "", {}))
      .catch(handleError);
  };

  const updateData = (
    rowId: string | number | null,
    data: Data,
  ): Promise<void> => {
    setLoader(true);

    const key = data.key as { value: string };
    const value = data.value as { value: string };
    const type = data.type as { value: string };

    const _updateState: globalConfigUpdate = {
      key: key.value,
      value: value.value,
      type: type.value,
      description: data.description as string,
    };

    if (rowId) _updateState.id = rowId.toString();

    const updateRequest = InitiateInvoiceApi.importGlobalConfig(
      "PUT",
      _updateState,
    );

    return ResponseAPI.performRequest(updateRequest)
      .then(
        handleResponse(
          rowId ? "__update__" : "__add__",
          dataToRow,
          `${
            rowId ? "Updated" : "Added"
          } successfully! The changes saved will be taken into account on the next AP/AR run.`,
        ),
      )
      .catch(handleError);
  };

  const deleteData = (id: string | number): Promise<void> => {
    setLoader(true);

    const deleteRequest = InitiateInvoiceApi.importGlobalConfig(
      "DELETE",
      null,
      id ? { configId: [Number(id)] } : undefined,
    );

    return ResponseAPI.performRequest(deleteRequest)
      .then(
        handleResponse(
          "__delete__",
          dataToRow,
          "Deleted successfully! The changes saved will be taken into account on the next AP/AR run.",
        ),
      )
      .catch(handleError);
  };

  const getAttributes = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getConfigMetadata("global-config");
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length === 0) {
        setMetadata(getResponse.data.data.metadata || []);
      } else {
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoader(true);
    Promise.all([getAttributes(), getData()]).finally(() => setLoader(false));
  }, []);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <SectionConatiner
        title="Global Config (non-client specific)"
        className="w-full mx-auto px-10 relative"
      >
        <Table
          suffix="Global Config"
          cols={columns}
          rows={data}
          primaryField="key"
          mandatoryFields={mandatoryFields}
          hideFields={hideFields}
          disableUpdateFields={disableUpdateFields}
          duplicateCheckFields={duplicateCheckFields}
          dropdownFieldsData={dropdownFieldsData}
          fieldMap={fieldMap}
          isShowActionsColumn={isAdmin}
          onUpdate={updateData}
          onDelete={deleteData}
        />
      </SectionConatiner>
    </>
  );
};

export default GlobalConfig;
