import React, { useState } from "react";

import ArrowDown from "../../assets/Icons/ArrowDown";

import "./SectionContainer.scss";

interface SectionConatinerProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  className?: string;
  collapse?: boolean;
  infoText?: string;
}

const SectionConatiner: React.FC<SectionConatinerProps> = ({
  children,
  className,
  title,
  collapse,
  infoText,
}) => {
  const [showContent, setShowContent] = useState(!collapse);

  return (
    <div className="w-full">
      <div
        className={`${
          !collapse
            ? `sectionContainer ${className || ""}`
            : `sectionContainer overflow-hidden ${
                collapse && !showContent ? "h-14" : "h-fit"
              } ${className || ""}`
        }`}
      >
        {showContent ? (
          children
        ) : (
          <div className="text-center font-bold text-[#211551]">{infoText}</div>
        )}
      </div>
      <div
        className={`title flex items-center gap-3 text-[#211551] ${
          collapse ? "cursor-pointer" : ""
        }`}
        onClick={() => {
          collapse && setShowContent(!showContent);
        }}
      >
        {title}
        {collapse && (
          <span
            className={`border rounded-full h-4 w-4 flex justify-center items-center bg-[#211551] transition-all duration-100  ${
              showContent ? "-rotate-180" : ""
            }`}
          >
            <ArrowDown fill="white" />
          </span>
        )}
      </div>
    </div>
  );
};

export default SectionConatiner;
