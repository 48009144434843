import React from "react";
import { BsArrowDownShort } from "react-icons/bs";

import useStore from "../../store/useStore";
import Tooltip from "../Tooltip";

interface statusDescProps {
  title?: string;
  detail?: string[];
  hideCustomSort?: boolean;
}

const StatusDesc: React.FC<statusDescProps> = ({
  title,
  detail,
  hideCustomSort,
}) => {
  const setStatusVisibility = useStore((state) => state.setStatusVisibility);
  const heightRef = React.useRef<HTMLDivElement>(null);
  const ascOrder = useStore((state) => state.ascOrder);

  const TooltipContent = (
    <>
      {detail?.map((item, index) => {
        return (
          <div key={item} className="font-bold py-1 text-sm">
            Step {index + 1}:<span className="pl-2 font-normal">{item}</span>
          </div>
        );
      })}
    </>
  );

  return (
    <div
      className="flex text-sm items-center gap-2 font-bold cursor-pointer relative"
      onMouseEnter={() => {
        const ylength = `${
          Number(heightRef.current?.getBoundingClientRect().left) +
          window.screenX -
          70
        }px`;

        setStatusVisibility({ visibility: true, ylength });
      }}
      onMouseLeave={() => setStatusVisibility({ visibility: false })}
      ref={heightRef}
    >
      {title}
      <Tooltip type={2} title={TooltipContent} placement="top" />
      {!hideCustomSort && ascOrder.ascField === "status" && (
        <BsArrowDownShort
          className={`h-6 w-6 ${ascOrder.ascValue ? "rotate-180" : ""}`}
        />
      )}
    </div>
  );
};

export default StatusDesc;
