export const ENV: { [k: string]: string } = {
  LOCAL: "LOCAL",
  DEV: "DEV",
  QA: "QA",
  PREPROD: "PREPROD",
  PROD: "PROD",
};

const HOST_ENV: { [k: string]: string } = {
  [process.env.REACT_APP_LOCAL_HOST || ""]: ENV.LOCAL,
  [process.env.REACT_APP_DEV_HOST || ""]: ENV.DEV,
  [process.env.REACT_APP_QA_HOST || ""]: ENV.QA,
  [process.env.REACT_APP_STAGING_HOST || ""]: ENV.PREPROD,
  [process.env.REACT_APP_PROD_HOST || ""]: ENV.PROD,
};

const CURRENT_HOST = `${window.location.origin}/`;

export const CURRENT_ENV = HOST_ENV[CURRENT_HOST] || ENV.PROD;

const enableFeaturesOn = (environments: string[]): boolean =>
  environments.includes(CURRENT_ENV);

export const features = {
  s4Upload: {
    isS4UploadRouteEnabled: enableFeaturesOn([
      ENV.LOCAL,
      ENV.DEV,
      ENV.QA,
      ENV.PREPROD,
      ENV.PROD,
    ]),
    isVMSSourceInvoicesEnabled: enableFeaturesOn([
      ENV.LOCAL,
      ENV.DEV,
      ENV.QA,
      ENV.PREPROD,
      ENV.PROD,
    ]),
  },
  ConsolidatedInvoice: {
    isConsolidatedInvoiceRouteEnabled: enableFeaturesOn([]),
  },
  configuration: {
    isConfigurationRouteEnabled: enableFeaturesOn([
      ENV.LOCAL,
      ENV.DEV,
      ENV.QA,
      ENV.PREPROD,
      ENV.PROD,
    ]),
    isGlobalConfigRouteEnabled: enableFeaturesOn([
      ENV.LOCAL,
      ENV.DEV,
      ENV.QA,
      ENV.PREPROD,
      ENV.PROD,
    ]),
    isInvoicePresentationRouteEnabled: enableFeaturesOn([
      ENV.LOCAL,
      ENV.DEV,
      ENV.QA,
      ENV.PREPROD,
      ENV.PROD,
    ]),
    isGenerateInvoicePDFRouteEnabled: enableFeaturesOn([]),
    isScheduleBatchRouteEnabled: enableFeaturesOn([
      ENV.LOCAL,
      ENV.DEV,
      ENV.QA,
      ENV.PREPROD,
      ENV.PROD,
    ]),
    isFeeSplittingForClientsRouteEnabled: enableFeaturesOn([
      ENV.LOCAL,
      ENV.DEV,
      ENV.QA,
      ENV.PREPROD,
      ENV.PROD,
    ]),
    isClientTemplateRouteEnabled: enableFeaturesOn([]),
  },
};

features.configuration.isInvoicePresentationRouteEnabled =
  features.configuration.isInvoicePresentationRouteEnabled &&
  (features.configuration.isGenerateInvoicePDFRouteEnabled ||
    features.configuration.isScheduleBatchRouteEnabled ||
    features.configuration.isFeeSplittingForClientsRouteEnabled ||
    features.configuration.isClientTemplateRouteEnabled);
