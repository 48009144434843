import React from "react";
import { BiRefresh } from "react-icons/bi";

import useStore from "../../store/useStore";

interface RefreshProps {
  refresh: (sortValue: string, sortOrder: string) => void;
  label?: string;
  disabled?: boolean;
}

const Refresh: React.FC<RefreshProps> = ({ refresh, label, disabled }) => {
  const setAsc = useStore((state) => state.setAsc);

  return (
    <div
      className={[
        "my-1 text-[white] text-sm font-bold w-fit flex items-center rounded border px-2 bg-[#211551]",
        disabled ? "opacity-50 cursor-default" : "cursor-pointer",
      ].join(" ")}
      onClick={
        disabled
          ? undefined
          : () => {
              refresh("id", "desc");
              setAsc({ ascValue: false, ascField: "id" });
            }
      }
    >
      {label || "Refresh"}
      <BiRefresh className="h-7 w-7 rotate-45 text-[white]" />
    </div>
  );
};

export default Refresh;
