import React from "react";
import { BsArrowDownShort } from "react-icons/bs";

import useStore from "../../store/useStore";

interface TimeStampTitleProps {
  title?: string;
}

const TimeStampTitle: React.FC<TimeStampTitleProps> = ({ title }) => {
  const ascOrder = useStore((state) => state.ascOrder);

  return (
    <div className="flex justify-center items-center gap-2 font-bold cursor-pointer">
      {title}
      {ascOrder.ascField === "startedAt" && (
        <BsArrowDownShort
          className={`h-6 w-6 ${ascOrder.ascValue ? "rotate-180" : ""}`}
        />
      )}
    </div>
  );
};

export default TimeStampTitle;
