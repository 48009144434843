import styled from "@emotion/styled";
import { TooltipProps, Tooltip, tooltipClasses, Box } from "@mui/material";
import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";

import { s4RunHistoryShowRedIcon } from "../../constants/s4upload";
import { includesStatus } from "../../utils";

interface IProps {
  title: string;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "inherit",
    border: "1px solid #211451",
    borderRadius: 5,
    fontSize: 13,
    fontFamily: "inherit",
    fontWeight: "inherit",
    whiteSpace: "pre",
    maxHeight: 250,
    overflow: "scroll",
  },
}));

const TooltipContent: React.FC<IProps> = ({ title }) => {
  const [dbStatus, value, ...message] = title.split(":");
  const m = message.join(":");

  const isShowRedIcon =
    dbStatus === "ERROR" || includesStatus(s4RunHistoryShowRedIcon, dbStatus);

  const content = (
    <Box className="flex justify-start items-center gap-3 cursor-pointer">
      {value}
      {m && (
        <BsFillInfoCircleFill
          className={["h-4 w-4", isShowRedIcon ? "text-red-500" : ""].join(" ")}
        />
      )}
    </Box>
  );

  return (
    <>
      {value && m && (
        <HtmlTooltip
          title={<span className="whitespace-pre-line">{m}</span>}
          placement="right"
        >
          {content}
        </HtmlTooltip>
      )}
      {value && !m && content}
    </>
  );
};

export default TooltipContent;
