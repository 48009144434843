import Header from "../../components/Header";

import "./unauthorized.scss";

const UnAuthorized: React.FC = () => (
  <>
    <div className="bg-[#211551] text-white py-3">
      <Header unauthorized />
    </div>
    <div className="parent403">
      <div className="lock" />
      <div className="message">
        <h1>Application Access is Restricted</h1>
        <p>Please Contact Administrator.</p>
      </div>
    </div>
  </>
);

export default UnAuthorized;
