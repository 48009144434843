import React, { useEffect, useRef, useState } from "react";

import ErrorComponent from "../ErrorNotifier";

interface inputProps {
  placeholder: string;
  inputValue?: number | string;
  changeHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  nameVal?: string;

  inputDisable?: boolean;
  inputTitle: string;
  isInputError: boolean;
}

const Input: React.FC<inputProps> = ({
  placeholder,
  inputValue,
  changeHandler,
  nameVal,
  inputDisable,
  inputTitle,
  isInputError,
}) => {
  const [isFocused, setFocused] = useState(false);
  const textAreaRef = useRef(null);

  function handlePlaceholderClick(): void {
    const textArea = textAreaRef.current as HTMLTextAreaElement | null;

    if (!textArea) return;

    setFocused(true);
    setTimeout(() => {
      textArea.focus();
    });
  }

  useEffect(() => {
    const textArea = textAreaRef.current as HTMLTextAreaElement | null;

    if (!textArea) return;

    function handleFocusIn(): void {
      setFocused(true);
    }

    function handleFocusOut(): void {
      setFocused(false);
    }

    textArea.addEventListener("focus", handleFocusIn);
    textArea.addEventListener("blur", handleFocusOut);

    return () => {
      textArea.removeEventListener("focus", handleFocusIn);
      textArea.addEventListener("blur", handleFocusOut);
    };
  }, []);

  return (
    <div
      className={`border-2 w-1/2 flex justify-between rounded bg-[#211551] border-[#211551]`}
    >
      {isInputError && <ErrorComponent value="Please provide valid values" />}
      <div className="text-white font-bold px-2 w-fit flex-shrink-0 mt-11">
        {inputTitle}
      </div>
      <div className={["flex relative flex-auto"].join(" ")}>
        <textarea
          ref={textAreaRef}
          name={nameVal}
          className={[
            "outline-none p-3 w-full h-28 min-h-[112px]",
            inputDisable ? "bg-gray-100" : "bg-white",
          ].join(" ")}
          disabled={inputDisable}
          value={inputValue || ""}
          onChange={changeHandler}
        />
        <div
          className={[
            "text-gray-400 pl-3 mt-11 truncate absolute top-0 max-w-full",
            inputDisable ? "cursor-default" : "cursor-text",
            !isFocused && !inputValue ? "flex" : "hidden",
          ].join(" ")}
          onClick={inputDisable ? undefined : handlePlaceholderClick}
        >
          {placeholder}
        </div>
      </div>
    </div>
  );
};

export default Input;
