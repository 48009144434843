import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";

import { IPwcRunHistoryProps } from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import SpinnerComponent from "../../components/Spinner";
import ToastComponent from "../../components/Toast";
import useStore from "../../store/useStore";

import Historysection from "./Historysection";
import InitiateSection from "./InitiateSection";

export interface dropListProps {
  id?: string;
  name?: string;
  file?: File;
}

const PwCTE: React.FC = () => {
  const enableLoader = useStore((state) => state.enableLoader);
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);
  const [runHistory, setRunHistory] = useState<IPwcRunHistoryProps[]>([]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const [pageSize, setPageSize] = useState<number>(5);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "desc" },
  ]);

  const pageNo = paginationModel.page;
  const pageNoRef = useRef(pageNo);
  const filterModelRef = useRef(filterModel);
  const sortModelRef = useRef(sortModel);

  const getStatusUpdate = async (
    _pageNo?: number,
    _filterModel?: GridFilterModel,
    _sortModel?: GridSortModel,
  ): Promise<void> => {
    setLoader(true);

    const pNo = _pageNo ?? pageNoRef.current;
    const fModel = _filterModel ?? filterModelRef.current;
    const sModel = _sortModel ?? sortModelRef.current;
    const idFilter = fModel?.items?.find(({ field }) => field === "id");

    const startedByFilter = fModel?.items?.find(
      ({ field }) => field === "startedBy",
    );

    const statusFilter = fModel?.items?.find(({ field }) => field === "status");

    const startedAtFilter = fModel?.items?.find(
      ({ field }) => field === "startedAt",
    );

    const filterId: string = [undefined, null, ""].includes(idFilter?.value)
      ? ""
      : idFilter?.value;

    const filterStatus: string = [undefined, null, ""].includes(
      statusFilter?.value,
    )
      ? ""
      : statusFilter?.value;

    const filterStartedAt: string = [undefined, null, ""].includes(
      startedAtFilter?.value,
    )
      ? ""
      : startedAtFilter?.value;

    const filterStartedBy: string = [undefined, null, ""].includes(
      startedByFilter?.value,
    )
      ? ""
      : startedByFilter?.value;

    const sortValue: string = sModel?.[0]?.field ? sModel[0].field : "id";
    const sortOrder: string = sModel?.[0]?.sort ? sModel[0].sort : "desc";

    try {
      const fId = filterId ? `run_id in (${filterId})` : "";
      const fStatus = filterStatus ? `status in (${filterStatus})` : "";

      const fStartedAt = filterStartedAt
        ? `STARTED_DATE range (${filterStartedAt},${filterStartedAt})`
        : "";

      const fStartedBy = filterStartedBy
        ? `started_by in (${filterStartedBy})`
        : "";

      const pageInfo = { pageNo: pNo, pageSize };

      console.log(pageSize);

      const getRequest = InitiateInvoiceApi.getStatusUpdate(
        sortValue,
        sortOrder,
        {
          fId,
          fStartedBy,
          fStatus,
          fStartedAt,
        },
        pageInfo,
      );

      const getResponse = await ResponseAPI.performRequest(getRequest);

      const {
        totalCount = 0,
        tasks = [],
      }: { totalCount: number; tasks: IPwcRunHistoryProps[] } =
        getResponse?.data?.data || {};

      setPaginationModel({ page: pNo, pageSize });
      setFilterModel(fModel);
      setSortModel(sModel);
      setTotalRowCount(totalCount);
      getResponse?.data?.errors?.length === 0
        ? setRunHistory(tasks)
        : enableToast({
            message: getResponse.data.errors[0].message,
            type: "error",
          });
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  useEffect(() => {
    if (toastMessage.message === "") {
      enableToast({ message: "", type: "" });
    }
  }, [toastMessage.message]);

  useEffect(() => {
    getStatusUpdate(pageNumber);
  }, [pageSize, pageNumber]);

  useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  useEffect(() => {
    filterModelRef.current = filterModel;
  }, [filterModel]);

  useEffect(() => {
    sortModelRef.current = sortModel;
  }, [sortModel]);

  console.log(pageSize);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <InitiateSection getStatusUpdate={getStatusUpdate} />
      <Historysection
        loading={enableLoader}
        runHistory={runHistory}
        getRunHistoryFunc={getStatusUpdate}
        totalRowCount={totalRowCount}
        pageNo={pageNo}
        pageSize={pageSize}
        filterModel={filterModel}
        sortModel={sortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={({ page, pageSize }) => {
          setPageNumber(page);
          setPageSize(pageSize);
        }}
        onFilterChange={(filterModel) => {
          getStatusUpdate(0, filterModel);
        }}
        onSortModelChange={(sortModel) => {
          getStatusUpdate(undefined, undefined, sortModel);
        }}
      />
    </>
  );
};

export default PwCTE;
