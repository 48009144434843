import { SyntheticEvent } from "react";

import ConfirmModal from "../../components/Modalwindow";

interface Iprops {
  setShowModal: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      initiateAction: ((e: SyntheticEvent) => Promise<void>) | null;
      title: React.ReactNode;
    }>
  >;
  modal: {
    show: boolean;
    initiateAction: ((e: SyntheticEvent) => Promise<void>) | null;
    title: React.ReactNode;
  };
}

const resetModalState = {
  show: false,
  initiateAction: null,
  title: null,
};

const ActionComfirmation: React.FC<Iprops> = ({ setShowModal, modal }) => {
  return (
    <ConfirmModal closeModal={() => setShowModal(resetModalState)}>
      <>
        <div className="flex items-center justify-center">
          <div className="bg-white p-10">
            <div className="mb-4">{modal.title}</div>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                onClick={(e) => {
                  setShowModal(resetModalState);

                  if (modal.initiateAction) {
                    modal.initiateAction(e);
                  }
                }}
              >
                Yes
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => setShowModal(resetModalState)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </>
    </ConfirmModal>
  );
};

export default ActionComfirmation;
