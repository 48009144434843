import React, { SyntheticEvent } from "react";

import Button from "../../components/Button";
import Datepicker from "../../components/Datepicker";
import DropDown from "../../components/Dropdown";
import SectionConatiner from "../../components/SectionConatiner";
import Tooltip from "../../components/Tooltip";

import { inputProps } from "./InitiateSection";

export interface section2Props {
  inputVal: inputProps;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange: (e: Date, name: string) => void;
  handleValidateInvoice?: (e: SyntheticEvent) => void;
  dropDownData: Array<{
    id: string;
    name: string;
  }>;
  handleGenerateInvoice?: (e: SyntheticEvent) => Promise<void>;
  setInputVal: (val: inputProps) => void;
}

const Section2: React.FC<section2Props> = ({
  inputVal,
  handleInputChange,
  handleValidateInvoice,
  dropDownData,
  setInputVal,
  handleDateChange,
}) => {
  return (
    <div className="w-5/6 mx-auto">
      <SectionConatiner
        title="Validate Invoices"
        collapse={true}
        className="px-10 overflow-visible "
        infoText="Expand to validate Invoices"
      >
        <form className="flex flex-col gap-10 my-10 items-center">
          <div className="flex flex-row   items-baseline justify-between  p-2 px-5 w-[90%] gap-5 flex-wrap">
            <div className="flex gap-3 items-center justify-between ">
              Invoice Type:
              <DropDown
                changeHandler={(value) =>
                  setInputVal({ ...inputVal, dropDownValidate: value })
                }
                arrayList={dropDownData}
                value={inputVal.dropDownValidate}
                styleVal={{ width: "150px" }}
              />
            </div>
            <div className="flex gap-3 justify-between items-center">
              <h1>From Date:</h1>
              <Tooltip title="Time/Expense/Milestone that are created in JobDiva on or after this date will be considered for validation." />
              <Datepicker
                value={inputVal.fromDateValidate || null}
                onChange={(e) => handleDateChange(e, "fromDateValidate")}
                className="shadow-custom px-1 py-1.5 shadow-blue-200"
                maxDate={inputVal.toDateValidate}
                showClearIcon={true}
                placeholderText="mm / dd / yyyy"
              />
            </div>
            <div className="flex gap-3 relative  justify-between items-center">
              To Date:
              <Tooltip title="Time/Expense/Milestone that are created in JobDiva on or before this date will be considered for validation." />
              <Datepicker
                value={inputVal.toDateValidate || null}
                onChange={(e) => handleDateChange(e, "toDateValidate")}
                className="shadow-custom px-1 py-1.5 shadow-blue-200"
                showClearIcon={false}
                placeholderText="mm / dd / yyyy"
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between  items-baseline  p-2 px-5 w-[90%] gap-10 flex-wrap">
            <div className="flex gap-3 justify-between items-center">
              Mock Validation:
              <Tooltip title="Check this if you just want to create a report of the records that are to be validated with PwC." />
              <input
                type="checkbox"
                onChange={handleInputChange}
                name="mockValidate"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <div className="flex gap-3 justify-between items-center">
              InvoiceFile WeekStart date:
              <Tooltip title="Invoice week start date, this is used in 1st level validation. All 1st level validation of a particular invoice cycle should have same value." />
              <Datepicker
                value={inputVal.batchId || null}
                onChange={(e) => handleDateChange(e, "batchId")}
                className="shadow-custom px-1 py-1.5 shadow-blue-200"
                minDate={new Date("1970-01-05")}
                showClearIcon={true}
                placeholderText="mm / dd / yyyy"
                showonlyMondays={true}
              />
            </div>
          </div>

          <div className="flex justify-center ">
            <Button
              disabled={false}
              label="Validate Invoices"
              type="submit"
              onClick={handleValidateInvoice}
            />
          </div>
        </form>
      </SectionConatiner>
    </div>
  );
};

export default Section2;
