import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  IARvmsRunHistoryDetails,
  clientListProps,
  dropListProps,
  dropdownOptionProps,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import SpinnerComponent from "../../components/Spinner";
import ToastComponent from "../../components/Toast";
import _VMSAcceptedFileFormats from "../../data/VMSAcceptedFileFormats.json";
import useStore from "../../store/useStore";

import Historysection from "./Historysection";
import InitiateSection from "./InitiateSection";

const VMSAcceptedFileFormats: {
  [key: string]: { formats: string[] };
} = _VMSAcceptedFileFormats;

const Arvms: React.FC = () => {
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);
  const [inputVal, setInputVal] = useState<dropListProps>({ fixIssues: false });

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const [pageSize, setPageSize] = useState<number>(5);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "desc" },
  ]);

  const [runHistory, setRunHistory] = useState<IARvmsRunHistoryDetails[]>([]);
  const [vmsList, setVMSList] = useState([{ id: "", name: "" }]);
  const [isLoadingClientList, setLoadingClientList] = useState(false);

  const [clientList, setClientList] = useState<dropdownOptionProps[]>([
    { value: "", label: "" },
  ]);

  const [clientsSelected, setClientsSelected] = useState<
    Array<number | string>
  >([]);

  const [allVMSList, setAllVMSList] = useState<dropdownOptionProps[]>([
    { value: "", label: "" },
  ]);

  const [allClientsList, setAllClientsList] = useState<dropdownOptionProps[]>([
    { value: "", label: "" },
  ]);

  const pageNo = paginationModel.page;
  const pageNoRef = useRef(pageNo);
  const filterModelRef = useRef(filterModel);
  const sortModelRef = useRef(sortModel);
  const vmsName = inputVal?.name || "";
  const acceptedFormats = VMSAcceptedFileFormats[vmsName]?.formats || [];
  const buttonDisabled = clientsSelected.length === 0 || !inputVal.file;

  const getRunHistory = useCallback(
    async (
      _pageNo?: number,
      _filterModel?: GridFilterModel,
      _sortModel?: GridSortModel,
    ): Promise<void> => {
      setLoader(true);

      const pNo = _pageNo ?? pageNoRef.current;
      const fModel = _filterModel ?? filterModelRef.current;
      const sModel = _sortModel ?? sortModelRef.current;
      const idFilter = fModel?.items?.find(({ field }) => field === "id");

      const fileNameFilter = fModel?.items?.find(
        ({ field }) => field === "col1",
      );

      const statusFilter = fModel?.items?.find(
        ({ field }) => field === "status",
      );

      const vmsFilter = fModel?.items?.find(({ field }) => field === "vms");

      const clientsFilter = fModel?.items?.find(
        ({ field }) => field === "clients",
      );

      const startedAtFilter = fModel?.items?.find(
        ({ field }) => field === "startedAt",
      );

      const startedByFilter = fModel?.items?.find(
        ({ field }) => field === "col6",
      );

      const filterId: string = [undefined, null, ""].includes(idFilter?.value)
        ? ""
        : idFilter?.value;

      const filterFileName: string = [undefined, null, ""].includes(
        fileNameFilter?.value,
      )
        ? ""
        : fileNameFilter?.value;

      const filterStatus: string = [undefined, null, ""].includes(
        statusFilter?.value,
      )
        ? ""
        : statusFilter?.value;

      const filterVMS: string = [undefined, null, ""].includes(vmsFilter?.value)
        ? ""
        : vmsFilter?.value;

      const filterClients: string = [undefined, null, ""].includes(
        clientsFilter?.value,
      )
        ? ""
        : clientsFilter?.value;

      const filterStartedAt: string = [undefined, null, ""].includes(
        startedAtFilter?.value,
      )
        ? ""
        : startedAtFilter?.value;

      const filterStartedBy: string = [undefined, null, ""].includes(
        startedByFilter?.value,
      )
        ? ""
        : startedByFilter?.value;

      const sortValue: string = sModel?.[0]?.field ? sModel[0].field : "id";
      const sortOrder: string = sModel?.[0]?.sort ? sModel[0].sort : "desc";

      try {
        const fId = filterId ? `run_id in (${filterId})` : "";

        const fFileName = filterFileName
          ? `VMS_FILE_NAME in (${filterFileName})`
          : "";

        const fStatus = filterStatus ? `status in (${filterStatus})` : "";
        const fVMS = filterVMS ? `vms_name in (${filterVMS})` : "";

        const fClients = filterClients
          ? `client_name in (${filterClients})`
          : "";

        const fStartedAt = filterStartedAt
          ? `STARTED_DATE range (${filterStartedAt},${filterStartedAt})`
          : "";

        const fStartedBy = filterStartedBy
          ? `started_by in (${filterStartedBy})`
          : "";

        const pageInfo = { pageNo: pNo, pageSize };

        const getRequest = InitiateInvoiceApi.getRunHistory(
          "AR_VMS",
          sortValue,
          sortOrder,
          {
            fId,
            fFileName,
            fStatus,
            fStartedAt,
            fStartedBy,
            fVMS,
            fClients,
          },
          pageInfo,
        );

        const getResponse = await ResponseAPI.performRequest(getRequest);

        const {
          executionDetails = [],
          totalCount = 0,
        }: { executionDetails: IARvmsRunHistoryDetails[]; totalCount: number } =
          getResponse?.data?.data?.process || {};

        setRunHistory(executionDetails);
        setPaginationModel({ page: pNo, pageSize });
        setFilterModel(fModel);
        setSortModel(sModel);
        setTotalRowCount(totalCount);

        if (getResponse.data.errors.length > 0) {
          enableToast({
            pos: "top",
            message: getResponse.data.errors[0].message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoader(false);
    },
    [pageSize],
  );

  const getDropdownList = async (loader: boolean = true): Promise<void> => {
    if (loader) setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.getDropdownList();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse?.data?.errors?.length === 0) {
        setVMSList(getResponse?.data?.data?.vms || []);
        setAllVMSList(
          getResponse?.data?.data?.vms?.map((vms: clientListProps) => {
            return {
              value: vms.id,
              label: vms.name,
            };
          }) || [],
        );
      } else {
        enableToast({
          message: getResponse?.data?.errors?.[0]?.message,
          type: "error",
        });
      }

      setInputVal({
        name: getResponse?.data?.data?.vms?.[0]?.name || "",
        id: getResponse?.data?.data?.vms?.[0]?.id || "",
        fixIssues: false,
      });
    } catch (error) {
      console.log(error);
    }

    if (loader) setLoader(false);
  };

  const getClientList = useCallback(async (vmsName?: string): Promise<void> => {
    setLoadingClientList(true);

    try {
      const getRequest = InitiateInvoiceApi.getClientList(vmsName);
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse?.data?.errors?.length === 0) {
        const setList = vmsName ? setClientList : setAllClientsList;

        setList(
          (getResponse?.data?.data?.clients || [])
            .sort((a: clientListProps, b: clientListProps) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();

              if (nameA < nameB) {
                return -1;
              }

              if (nameA > nameB) {
                return 1;
              }

              return 0;
            })
            .map((c: clientListProps) => ({
              value: c.id,
              label: c.name,
            })),
        );
      } else {
        enableToast({
          message:
            getResponse?.data?.errors?.[0]?.message || "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }

    setLoadingClientList(false);
  }, []);

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    const formData = new FormData();

    const data = {
      vms: { id: inputVal.id, name: inputVal.name },
      clients: clientsSelected
        .map((value) => {
          const selected = clientList.find((c) => c.value === value);

          if (!selected) return null;

          return { id: selected.value, name: selected.label };
        })
        .filter(Boolean),
      filename: inputVal.file?.name,
      fixIssues: inputVal.fixIssues || false,
    };

    const json = JSON.stringify(data);

    const jsonData = new Blob([json], {
      type: "application/json",
    });

    formData.append("arVmsS4Request", jsonData);
    formData.append("file", inputVal.file || "");

    try {
      const request = InitiateInvoiceApi.InitiateARvmsInvoice(formData);
      const response = await ResponseAPI.performRequest(request);

      if (response.data.errors.length !== 0) {
        setLoader(false);
        enableToast({
          message: "Invoices not initiated",
          type: "error",
        });

        return;
      }

      setLoader(false);

      const runID: number = response.data.data.process.executionDetail.id;

      enableToast({
        message: `AR VMS Invoices Initiated, Run ID: ${runID}`,
        type: "success",
      });

      getRunHistory();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }

    setInputVal({
      ...inputVal,
      file: undefined,
      fixIssues: false,
    });
    setClientsSelected([]);
  };

  const handleDropdownChange = (value: dropListProps): void => {
    const _inputVal = {
      ...inputVal,
      name: value.name,
      id: value.id,
    };

    if (value.name !== inputVal.name) {
      _inputVal.file = undefined;
      setClientsSelected([]);
      setClientList([]);
    }

    setInputVal(_inputVal);
  };

  const handleClientDropdownChange = (value: number | string): void => {
    setClientsSelected((clientsSelected) => {
      if (value === "__none__") return [];

      const _clientsSelected = [...clientsSelected];
      const i = _clientsSelected.findIndex((id) => id === value);

      if (i > -1) _clientsSelected.splice(i, 1);
      else _clientsSelected.push(value);

      return _clientsSelected;
    });
  };

  const handleFileUpload = (file: File): void => {
    setInputVal({ ...inputVal, file });
  };

  const closeFileUploaded = (): void => {
    setInputVal({ ...inputVal, file: undefined });
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = e.target.type === "checkbox" && e.target.checked;

    setInputVal({ ...inputVal, fixIssues: value || false });
  };

  useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  useEffect(() => {
    filterModelRef.current = filterModel;
  }, [filterModel]);

  useEffect(() => {
    sortModelRef.current = sortModel;
  }, [sortModel]);

  useEffect(() => {
    if (toastMessage.message === "") {
      enableToast({ message: "", type: "" });
    }
  }, [toastMessage.message]);

  useEffect(() => {
    getDropdownList(false);
  }, []);

  useEffect(() => {
    getRunHistory();
    getClientList();
  }, [getRunHistory, getClientList]);

  useEffect(() => {
    if (vmsName) {
      getClientList(vmsName);
    }
  }, [vmsName, getClientList]);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <InitiateSection
        isLoadingClientList={isLoadingClientList}
        arrayList={vmsList}
        clientList={clientList}
        clientsSelected={clientsSelected}
        handleDropdownChange={handleDropdownChange}
        handleClientDropdownChange={handleClientDropdownChange}
        inputVal={inputVal}
        acceptedFormats={acceptedFormats}
        handleFileUpload={handleFileUpload}
        handleSubmit={handleSubmit}
        buttonDisabled={buttonDisabled}
        closeFileUploaded={closeFileUploaded}
        handleCheckboxChange={handleCheckboxChange}
      />
      <Historysection
        runHistory={runHistory}
        totalRowCount={totalRowCount}
        pageNo={pageNo}
        pageSize={pageSize}
        filterModel={filterModel}
        sortModel={sortModel}
        getRunHistoryFunc={getRunHistory}
        paginationModel={paginationModel}
        allVMSList={allVMSList}
        allClientsList={allClientsList}
        onPaginationModelChange={({ page, pageSize }) => {
          getRunHistory(page);
          setPageSize(pageSize);
        }}
        onFilterChange={(filterModel) => {
          getRunHistory(0, filterModel);
        }}
        onSortModelChange={(sortModel) => {
          getRunHistory(undefined, undefined, sortModel);
        }}
      />
    </>
  );
};

export default Arvms;
