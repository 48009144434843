import React from "react";

interface textProps {
  label: string;
  styleVal?: string;
  mandatory?: boolean;
}

const Textlabel: React.FC<textProps> = ({ label, styleVal, mandatory }) => {
  return (
    <label className={`px-5 whitespace-nowrap  ${styleVal || ""}`}>
      {label} {mandatory && <span className="text-red-600">*</span>}
    </label>
  );
};

export default Textlabel;
