import React from "react";

interface ErrorComponentProps {
  value: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ value }) => {
  return (
    <div
      className="w-fit absolute -top-8 max-w-7xl bg-pink-100 border-t-4 border-pink-500 rounded-b text-pink-900 px-4 py-1 shadow-md animate-bounce"
      role="alert"
    >
      <div className="flex">
        <div>
          <p className="text-xs">{value}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
