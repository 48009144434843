import { LoginCallback } from "@okta/okta-react";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { features } from "../constants/features";
import UnAuthorized from "../pages/403page/unauthorized";
import Ap from "../pages/AP";
import Arnonvms from "../pages/ARnon";
import Arvms from "../pages/ARvms";
import CompanyConfig from "../pages/CompanyConfig";
import FeeSplittingConfig from "../pages/FeeSplittingConfig";
import GenerateInvoiceByS4Id from "../pages/GenerateInvoiceByS4Id";
import GenerateManualInvoice from "../pages/GenerateManualInvoice";
import GlobalConfig from "../pages/GlobalConfig";
import Home from "../pages/Home";
import NormalExpense from "../pages/NormalExpense";
import NotFound from "../pages/NotFound";
import PwC from "../pages/PwC-TE";
import S4Upload from "../pages/S4Upload";
import ScheduleBatch from "../pages/ScheduleBatch";
import SpecialExpense from "../pages/SpecialExpense";
import TimeMapping from "../pages/TimeMapping";

import ProtectedRoutes from "./ProtectedRoutes";

const { isS4UploadRouteEnabled } = features.s4Upload;

const {
  isConfigurationRouteEnabled,
  isGlobalConfigRouteEnabled,
  isInvoicePresentationRouteEnabled,
  isGenerateInvoicePDFRouteEnabled,
  isScheduleBatchRouteEnabled,
  isFeeSplittingForClientsRouteEnabled,
  isClientTemplateRouteEnabled,
} = features.configuration;

const { isConsolidatedInvoiceRouteEnabled } = features.ConsolidatedInvoice;

const MainRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="invoices"
          element={<ProtectedRoutes isConfigRoute={false} />}
        >
          <Route path="ap" element={<Ap />} />
          <Route path="arnonvms" element={<Arnonvms />} />
          <Route path="arvms" element={<Arvms />} />
          <Route path="pwc-te" element={<PwC />} />
          {isS4UploadRouteEnabled && (
            <Route path="s4-upload" element={<S4Upload />} />
          )}
          <Route path="gahi" element={<GenerateInvoiceByS4Id />} />
          {isConsolidatedInvoiceRouteEnabled && (
            <Route
              path="generate-invoice"
              element={<GenerateManualInvoice />}
            />
          )}
        </Route>
        {isConfigurationRouteEnabled && (
          <Route
            path="config"
            element={<ProtectedRoutes isConfigRoute={true} />}
          >
            <Route path="normal_expense" element={<NormalExpense />} />
            <Route path="special_expense" element={<SpecialExpense />} />
            <Route path="company_config" element={<CompanyConfig />} />
            <Route path="time_mapping" element={<TimeMapping />} />
            {isGlobalConfigRouteEnabled && (
              <Route path="global_config" element={<GlobalConfig />} />
            )}
            {isInvoicePresentationRouteEnabled && (
              <>
                {isGenerateInvoicePDFRouteEnabled && (
                  <Route
                    path="generate_invoice_pdf"
                    element={<CompanyConfig />}
                  />
                )}
                {isScheduleBatchRouteEnabled && (
                  <Route path="schedule_batch" element={<ScheduleBatch />} />
                )}
                {isFeeSplittingForClientsRouteEnabled && (
                  <Route
                    path="fee_splitting"
                    element={<FeeSplittingConfig />}
                  />
                )}
                {isClientTemplateRouteEnabled && (
                  <Route path="client_template" element={<CompanyConfig />} />
                )}
              </>
            )}
          </Route>
        )}
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route path="unauthorized" element={<UnAuthorized />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
