import Button from "../Button";

interface DeleteExpenseViewProps {
  title: string;
  description: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const DeleteExpenseView: React.FC<DeleteExpenseViewProps> = ({
  title,
  description,
  onClose,
  onSubmit,
}) => {
  return (
    <>
      <header className="flex justify-between items-center px-5">
        <h1 className="font-bold text-lg mx-auto ">{title}</h1>
        <button
          onClick={onClose}
          className="font-mono font-bold text-xl border-0 text-[#211551] leading-4 "
        >
          x
        </button>
      </header>
      <div className="border border-[#211551] w-full my-3"></div>
      <div className="px-5 w-[400px] pt-4">
        <div className="text-center w-full">{description}</div>
        <footer className="flex w-full justify-center mt-8">
          <Button
            type="submit"
            label="Delete"
            onClick={onSubmit}
            disabled={false}
            styleVal="transition ease-in-out delay-50 hover:scale-110"
          />
        </footer>
      </div>
    </>
  );
};

export default DeleteExpenseView;
