import styled, { StyledComponent } from "@emotion/styled";
import {
  TooltipProps,
  Tooltip as TooltipMui,
  tooltipClasses,
  ThemeWithProps,
} from "@mui/material";
import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";

interface IProps {
  type?: 1 | 2;
  title: React.ReactNode;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  TooltipComponent?: StyledComponent<
    TooltipProps & {
      theme?: ThemeWithProps | undefined;
    },
    {},
    {}
  >;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipMui {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "inherit",
    border: "1px solid #211451",
    borderRadius: 5,
    fontSize: 13,
    fontFamily: "inherit",
    fontWeight: "inherit",
  },
}));

const HtmlTooltip2 = styled(({ className, ...props }: TooltipProps) => (
  <TooltipMui {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "inherit",
    borderRadius: 5,
    fontSize: 13,
    fontFamily: "inherit",
    fontWeight: "inherit",
    boxShadow: "0 3px 10px rgb(14 14 14 / 20%)",
    padding: 10,
  },
}));

const Tooltip: React.FC<IProps> = ({
  type,
  title,
  placement,
  TooltipComponent,
}) => {
  let TC = HtmlTooltip;

  if (TooltipComponent) TC = TooltipComponent;
  else if (type === 2) TC = HtmlTooltip2;

  return (
    <TC title={title} placement={placement || "right"}>
      <div>
        <BsFillInfoCircleFill className="h-4 w-4" />
      </div>
    </TC>
  );
};

export default Tooltip;
