import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import React, { useEffect } from "react";

import MainRoutes from "./Routes/MainRoutes";
import { oktaAuth } from "./auth/oktaConfig";
import SessionExpiredPopup from "./components/SessionExpiredPopup";

import "./App.scss";

const App: React.FC = () => {
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ): Promise<void> => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin),
    );
  };

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <MainRoutes />
      <SessionExpiredPopup />
    </Security>
  );
};

export default App;
