import styled from "@emotion/styled";
import { TooltipProps, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import { CiCircleMore } from "react-icons/ci";

interface IProps {
  list: string;
  maxChar?: number;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "inherit",
    border: "1px solid #211451",
    borderRadius: 5,
    fontSize: 13,
    fontFamily: "inherit",
    fontWeight: "inherit",
    whiteSpace: "pre",
    maxHeight: 250,
    overflow: "scroll",
  },
}));

const TooltipList: React.FC<IProps> = ({ list, maxChar = 70 }) => {
  const tooltipMessage = list
    .split(";")
    .filter(Boolean)
    .map((l) => l.slice(1, -1))
    .join("\n");

  let value = list
    .split(";")
    .filter(Boolean)
    .map((l) => l.slice(1, -1))
    .join(", ");

  let isMore = false;

  if (value.length > maxChar) {
    isMore = true;
    value = value.slice(0, 70);
  }

  const content = (
    <p>
      {value}
      {isMore && (
        <span className="relative ml-0.5">
          <CiCircleMore size={20} className="inline-block align-top" />
        </span>
      )}
    </p>
  );

  return (
    <>
      {isMore && (
        <HtmlTooltip title={tooltipMessage} placement="right">
          {content}
        </HtmlTooltip>
      )}
      {!isMore && content}
    </>
  );
};

export default TooltipList;
