import { GridToolbar } from "@mui/x-data-grid";

const Toolbar: React.FC = () => (
  <GridToolbar
    printOptions={{ disableToolbarButton: true }}
    csvOptions={{ disableToolbarButton: true }}
  />
);

export default Toolbar;
