import React, { CSSProperties, useEffect, useState } from "react";

import { dropListProps } from "../../@types/assetTypes/ap";
import ArrowDown from "../../assets/Icons/ArrowDown";

interface IProps {
  arrayList: dropListProps[];
  changeHandler: (vals: dropListProps) => void;
  value?: dropListProps;
  styleVal?: CSSProperties;
  fullWidth?: boolean;
  disabled?: boolean;
}

const MBODropDown: React.FC<IProps> = (props) => {
  const { arrayList, changeHandler, value, styleVal, fullWidth, disabled } =
    props;

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const dropDownRef = React.useRef<HTMLUListElement | null>(null);

  const handleSelect = (val: dropListProps): void => {
    changeHandler(val);
    setShowDropDown(false);
  };

  const toggleDropDown = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    setShowDropDown(!showDropDown);
  };

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent | TouchEvent): void => {
      if (
        showDropDown &&
        dropDownRef?.current instanceof Node &&
        !dropDownRef?.current?.contains(event.target as Node)
      ) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDropDown]);

  return (
    <div className="relative">
      <div
        role="presentation"
        id="dropdownMenuButton"
        onClick={disabled ? undefined : (e) => toggleDropDown(e)}
        className={`${fullWidth ? "w-full" : "w-96"} ${
          disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
        } h-10 flex justify-between gap-3 border items-center shadow-custom shadow-blue-200 px-5 py-1 capitalize`}
        style={styleVal}
      >
        <span className="truncate">{value?.name || ""}</span>
        <span
          className={`ml-2 transition-all duration-500 ${
            showDropDown ? "-rotate-180" : ""
          }`}
        >
          {<ArrowDown />}
        </span>
      </div>
      <ul
        ref={dropDownRef}
        aria-labelledby="dropdownMenuButton"
        className="shadow-custom shadow-blue-200 rounded-md absolute bg-white w-full z-50 max-h-60 overflow-auto"
      >
        {showDropDown &&
          arrayList?.map((item) => (
            <li
              className="cursor-pointer capitalize px-5 py-1 hover:bg-[#211551] hover:text-white"
              key={item.id}
              onClick={() => {
                handleSelect(item);
              }}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MBODropDown;
