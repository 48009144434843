import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import InvoiceTabs from "../components/Tabs";

interface IProps {
  isConfigRoute: boolean;
}

const InvoicesMain: React.FC<IProps> = ({ isConfigRoute }) => {
  return (
    <div className="min-h-screen antialiased">
      <div className="bg-[#211551] text-white py-3">
        <Header />
      </div>
      <div className="w-11/12 mx-auto py-20">
        {!isConfigRoute && <InvoiceTabs />}

        <Outlet />
      </div>
    </div>
  );
};

export default InvoicesMain;
