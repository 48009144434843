import { useEffect, useRef, useState } from "react";

import useStore from "../../store/useStore";

import "./Toast.scss";

const ToastComponent: React.FC = () => {
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);
  const delayTime = 5000;
  const [timer, setTimer] = useState(true);
  const timerId = useRef<ReturnType<typeof setTimeout> | number>(1);

  useEffect(() => {
    if (timer && toastMessage.message !== "") {
      const id = setTimeout(() => {
        enableToast({ message: "", type: "", pos: "bottom" });
      }, delayTime);

      if (timerId && timerId.current) {
        timerId.current = id;
      }
    } else if (!timer && timerId && timerId.current) {
      clearTimeout(timerId.current);
    }

    return () => {
      if (timerId && timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [timer]);

  return (
    <div
      className={`toast toast_pos_${toastMessage.pos || "bottom"} ${
        toastMessage.type !== "" ? `toast_bg_${toastMessage.type}` : ""
      } show_toast`}
      aria-hidden="true"
      onMouseEnter={() => {
        setTimer(false);
      }}
      onMouseLeave={() => {
        setTimer(true);
      }}
    >
      <span className="flex w-full h-full items-center my-1">
        {toastMessage.message}
      </span>
      <span
        className="toast__close"
        onClick={() => {
          enableToast({ message: "", type: "", pos: "bottom" });
        }}
      />
    </div>
  );
};

export default ToastComponent;
