/**
 * Singleton OktaAuthAPI for handling Okta Actions
 * This module should be used to get access to Okta Auth Client
 */

import {
  OktaAuth,
  OktaAuthOptions,
  TokenResponse,
  Tokens,
} from "@okta/okta-auth-js";

interface initializeAuthClientProps {
  getAuthClient: () => OktaAuth;
  signOut: () => Promise<void>;
  getTokens: () => Promise<Tokens>;
  getTokensWithoutPrompt: () => Promise<TokenResponse>;
}

const OktaAuthAPI = (): {
  getAuthInstance: () => initializeAuthClientProps;
} => {
  let instance: initializeAuthClientProps;
  let oktaAuthInstance: OktaAuth;

  const initializeAuthClient = (): initializeAuthClientProps => {
    /**
     * Returns the current auth client instance
     * This method will create the instance if not created already,
     * else this will return the existing initialized instance
     */
    const { protocol, host } = window.location;

    let environment =
      (host.includes("localhost") && "local") ||
      (host.includes("vmsops-dev") && "dev") ||
      (host.includes("vmsops-qa") && "qa") ||
      (host.includes("vmsops-preprod") && "staging") ||
      (host.includes("vmsops.mbopartners") && "prod");

    environment = environment.toString().toUpperCase();

    const getAuthClient = (): OktaAuth => {
      const config = (): OktaAuthOptions => {
        // setting default issuer, cleintid and redirectURL to local setup

        const redirectUri = `${protocol}//${host}${
          process.env.REACT_APP_CALLBACK_PATH || ""
        }`;

        const issuer = process.env[`REACT_APP_${environment || ""}_ISSUER`];

        const clientID =
          process.env[`REACT_APP_${environment || ""}_CLIENT_ID`];

        return {
          issuer: `${issuer || ""}`,
          clientId: `${clientID || ""}`,
          redirectUri: `${redirectUri}`,
          scopes: ["openid", "profile", "email"],
          pkce: true,
          // tokenManager: {
          //   expireEarlySeconds: 3300, // 55 mins - works only on localhost
          // },
        };
      };

      if (!oktaAuthInstance) {
        oktaAuthInstance = new OktaAuth(config());
      }

      return oktaAuthInstance;
    };

    /**
     * Closes and signs out the current session by clearing all the tokens
     */
    const signOut = async (): Promise<void> => {
      const oktaAuth = getAuthClient();

      const logoutURI = (): string | undefined => {
        return process.env[`REACT_APP_${environment || ""}_LOGOUT_URI`];
      };

      await oktaAuth.signOut({
        revokeAccessToken: false,
        postLogoutRedirectUri: logoutURI(),
      });

      await oktaAuth.closeSession();

      localStorage.clear();
      sessionStorage.clear();
      oktaAuth.tokenManager.clear();
    };

    const getTokens = async (): Promise<Tokens> => {
      const oktaAuth = getAuthClient();
      const tokens = await oktaAuth.tokenManager.getTokens();

      return tokens;
    };

    const getTokensWithoutPrompt = async (): Promise<TokenResponse> => {
      const oktaAuth = getAuthClient();
      const tokens = await oktaAuth.token.getWithoutPrompt();

      return tokens;
    };

    return {
      getAuthClient,
      signOut,
      getTokens,
      getTokensWithoutPrompt,
    };
  };

  return {
    getAuthInstance(): initializeAuthClientProps {
      if (!instance) {
        return (instance = initializeAuthClient());
      }

      return instance;
    },
  };
};

const authInstance = OktaAuthAPI().getAuthInstance();
const oktaAuth = authInstance.getAuthClient();
const { signOut } = authInstance;
const { getTokens } = authInstance;
const { getTokensWithoutPrompt } = authInstance;

export { oktaAuth, signOut, getTokens, getTokensWithoutPrompt };
