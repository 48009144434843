import { create } from "zustand";

import { invoicesListProps } from "../@types/assetTypes/ap";
import { InvoicesProps } from "../API/InvoicesApi";

interface ToastTypeProp {
  message: string;
  type: string;
  pos?: string;
}

interface ToastProps {
  enableToast: (c: ToastTypeProp) => void;
  toastMessage: ToastTypeProp;
  enableLoader: boolean;
  setLoader: (value: boolean) => void;
  enablePopup: boolean;
  setPopup: (value: boolean) => void;
  ascOrder: { ascValue: boolean; ascField: string };
  setAsc: (value: { ascValue: boolean; ascField: string }) => void;
  convertData: (objectData: invoicesListProps) => string | string[] | undefined;
  arrayConvert: (val?: string) => InvoicesProps;
  getEnv: string;
  statusVisibility: { visibility: boolean; ylength?: string };
  setStatusVisibility: (value: {
    visibility: boolean;
    ylength?: string;
  }) => void;
}

const { host } = window.location;

const useStore = create<ToastProps>((set) => ({
  toastMessage: { message: "", type: "", pos: "" },

  enableToast: (value: ToastTypeProp) => {
    set({ toastMessage: value });
  },

  enableLoader: false,
  setLoader: (value: boolean) => {
    set({ enableLoader: value });
  },
  enablePopup: false,
  setPopup: (value: boolean) => {
    set({ enablePopup: value });
  },
  ascOrder: { ascValue: false, ascField: "id" },
  setAsc: (value) => {
    set({ ascOrder: { ascValue: value.ascValue, ascField: value.ascField } });
  },
  statusVisibility: { visibility: false, ylength: "663px" },
  setStatusVisibility: (value) => {
    set({
      statusVisibility: {
        visibility: value.visibility,
        ylength: value.ylength,
      },
    });
  },
  convertData: (objectData: invoicesListProps) => {
    const convertRange = (
      objectData: invoicesListProps,
    ): string[] | undefined => {
      const convertedData = objectData?.ranges?.map((item) => {
        const { startInvoiceId, endInvoiceId } = item;

        if (startInvoiceId.length < 3 || endInvoiceId.length < 3) {
          return [
            `00${startInvoiceId}`.slice(-3),
            `00${endInvoiceId}`.slice(-3),
          ].join("-");
        }

        const range = [startInvoiceId, endInvoiceId].join("-");

        return range;
      });

      return convertedData;
    };

    let finalData;

    if (objectData?.ranges?.length === 0 || !objectData?.ranges) {
      finalData = objectData?.invoiceNumbers?.join(", ");

      return finalData;
    }

    if (
      objectData?.invoiceNumbers?.length === 0 ||
      !objectData?.invoiceNumbers
    ) {
      finalData = convertRange(objectData)?.join(", ");

      return finalData;
    }

    const rangeData = convertRange(objectData);

    finalData = [rangeData, objectData?.invoiceNumbers?.join(", ")].join(", ");

    return finalData;
  },
  arrayConvert: (val?: string) => {
    const arrayData: Array<{ startInvoiceId: number; endInvoiceId: number }> =
      [];

    const data = val?.split(",").filter((item) => item);
    const rangeData = data?.filter((item) => item.includes("-"));

    const singleData =
      data
        ?.filter((item: string) => !item.includes("-"))
        ?.map((item: string) => Number(item)) || [];

    rangeData?.forEach((item) => {
      const splitData = item.split("-");

      if (
        !(Number(splitData[1]) > Number(splitData[0])) ||
        splitData.length !== 2
      ) {
        return;
      }

      arrayData.push({
        startInvoiceId: Number(splitData[0]),
        endInvoiceId: Number(splitData[1]),
      });
    });
    const convertedData = { ranges: arrayData, invoiceNumbers: singleData };

    return convertedData;
  },
  getEnv: (
    (host.includes("localhost") && "local") ||
    (host.includes("vmsops-dev") && "dev") ||
    (host.includes("vmsops-qa") && "qa") ||
    (host.includes("vmsops-preprod") && "staging") ||
    (host.includes("vmsops.mbopartners") && "prod")
  )
    .toString()
    .toUpperCase(),
}));

export default useStore;
