import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridFilterModel,
  getGridStringOperators,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";

import { IUnpaidInvoiceDetails } from "../../@types/assetTypes/ap";
import SectionConatiner from "../../components/SectionConatiner";
import useStore from "../../store/useStore";
import Empty from "../S4Upload/components/Empty";
import Toolbar from "../S4Upload/components/Toolbar";

interface UnpaidSectionProps {
  unpaidData: IUnpaidInvoiceDetails[];

  totalRowCount: number;
  paginationModel: GridPaginationModel;
  pageSize: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
  onFilterChange: (filterModel: GridFilterModel) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

const columns: GridColDef[] = [
  {
    field: "invoice_id",
    align: "left",
    headerAlign: "left",
    width: 120,
    headerName: "Invoice Id",
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "equals",
    ),
    valueFormatter: (params) => params.value,
  },
  {
    field: "col1",
    headerName: "Emp. Name",
    width: 150,
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains",
    ),
  },
  {
    field: "empCompany",
    width: 250,
    headerName: "Emp. Company",
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains",
    ),
  },
  {
    field: "col2",
    width: 220,
    headerName: "Emp. Category",
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains",
    ),
    filterable: false,
  },
  {
    field: "col3",
    width: 120,
    headerName: "Invoice Type",
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "equals",
    ),
    filterable: false,
  },
  {
    field: "col4",
    width: 150,
    headerName: "SOW ID",
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "equals",
    ),
  },
  {
    field: "col5",
    width: 150,
    headerName: "Amount",
    sortable: false,
    filterable: false,
  },
  {
    field: "col6",
    width: 150,
    headerName: "Vendor ID",
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "equals",
    ),
    filterable: false,
  },
  {
    field: "col7",
    width: 300,
    headerName: "Email ID",
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains",
    ),
    filterable: false,
  },
  // {
  //   field: "col8",
  //   width: 150,
  //   headerName: "Status",
  //   sortable: false,
  //   filterOperators: getGridStringOperators().filter(
  //     (operator) => operator.value === "equals",
  //   ),
  // },
];

const UnpaidSection: React.FC<UnpaidSectionProps> = ({
  unpaidData,
  totalRowCount,
  paginationModel,
  pageSize,
  filterModel,
  sortModel,
  onPaginationModelChange,
  onFilterChange,
  onSortModelChange,
}) => {
  const enableLoader = useStore((state) => state.enableLoader);
  const [rowCountState, setRowCountState] = React.useState(totalRowCount || 0);

  const invoiceTypeChange = (invoiceType: string): string => {
    if (invoiceType.toLowerCase() === "ea") {
      return "Expense";
    }

    if (["hourly", "weekly", "monthly"].includes(invoiceType.toLowerCase())) {
      return "Timesheet";
    }

    return invoiceType;
  };

  const rows: GridRowsProp = unpaidData.map((item, i) => {
    return {
      id: `${item?.invoice.id}${i}`,
      invoice_id: item?.invoice.id || "",
      col2: item?.candidate?.employmentCategory || "",
      col3: invoiceTypeChange(item?.invoice?.type || ""),
      col1: [item?.candidate?.firstName, item?.candidate?.lastName]
        .filter(Boolean)
        .join(" "),
      col4: item?.sowId || "",
      col5: item?.invoice?.amount?.value || "",
      col6: item?.vendorId || "",
      col7: item?.candidate?.emailId || "",
      col8: item?.invoice?.status || "",
      empCompany: item?.company?.name || "",
    };
  });

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState,
    );
  }, [totalRowCount, setRowCountState]);

  return (
    <SectionConatiner
      title="Unpaid Invoices"
      className="w-full mx-auto mt-14 px-10"
      collapse={true}
      infoText="Expand to view Unpaid invoices
    "
    >
      <div
        className={[
          rowCountState < pageSize
            ? "max-h-[850px]"
            : pageSize === 5
              ? "min-h-[350px] max-h-[375px] h-[60vh] 2xl:h-[45vh]"
              : "min-h-[450px] max-h-[625px] h-[70vh] 2xl:h-[65vh]",
          "mx-auto my-5 border border-[#211551] rounded-md",
        ].join(" ")}
      >
        <DataGrid
          autoHeight={rowCountState < pageSize}
          hideFooter={rowCountState < pageSize}
          loading={enableLoader}
          sortingOrder={["desc", "asc"]}
          initialState={{
            sorting: {
              sortModel: [{ field: "invoice_id", sort: "desc" }],
            },
          }}
          rowCount={rowCountState}
          rows={rows}
          columns={columns}
          slots={{ toolbar: Toolbar, loadingOverlay: Empty }}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[5, 10]}
          pagination
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          filterModel={filterModel}
          sortModel={sortModel}
          onFilterModelChange={onFilterChange}
          onSortModelChange={onSortModelChange}
          sx={{
            "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
              borderBottomWidth: 0,
            },
            "& .MuiDataGrid-cell:hover": {
              // color: "primary.main",
              cursor: "pointer",
            },
            "& .MuiDataGrid-toolbarContainer": {
              "& .MuiButtonBase-root": { color: "#211551" },
            },

            "& .MuiDataGrid-columnHeaders": {
              bgcolor: "#211551",
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              "& .MuiIconButton-sizeSmall": {
                color: "white",
              },
            },
            "& .MuiDataGrid-row": { fontSize: "small" },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "4px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "10px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "17px",
            },
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none",
              },
          }}
          getRowHeight={() => "auto"}
          isCellEditable={() => false}
          columnHeaderHeight={44}
          disableRowSelectionOnClick
        />
      </div>
    </SectionConatiner>
  );
};

export default UnpaidSection;
