import React, { useEffect, useRef, useState } from "react";

import { dropdownOptionProps } from "../../@types/assetTypes/ap";
import ArrowDown from "../../assets/Icons/ArrowDown";
import SearchClose from "../../assets/Icons/SearchClose";

interface IProps {
  disabled: boolean;
  options: dropdownOptionProps[];
  selected: Array<number | string>;
  onSelect: (value: number | string) => void;
  alterStyle?: string;
  fullWidth?: boolean;
}

const SearchableMultiSelectDropdown: React.FC<IProps> = ({
  disabled,
  options,
  selected,
  onSelect,
  alterStyle,
  fullWidth = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const filteredOptions: dropdownOptionProps[] = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) dropdownRef.current?.querySelector("input")?.focus();
  }, [isOpen]);

  useEffect(() => {
    try {
      const handleClickOutside = (event: MouseEvent): void => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
          setInputValue("");
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } catch (e) {
      // showBoundary("Something went wrong, please try again later");
    }
  }, []);

  return (
    <div
      className={[
        fullWidth ? "w-full" : "w-96",
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
        "h-10 flex justify-between gap-3 border items-center shadow-custom shadow-blue-200 capitalize",
        alterStyle && alterStyle !== "" ? alterStyle : "",
      ].join(" ")}
      ref={dropdownRef}
      data-testid="form-drp"
    >
      <div className="relative w-full h-full">
        <div className="flex w-full h-full justify-between">
          <div
            className="flex w-full pl-5 py-1 items-center"
            onClick={disabled ? undefined : () => setIsOpen(true)}
          >
            {selected.length === 0 ? "" : `${selected.length} selected`}
          </div>
          {selected.length > 0 && (
            <div
              className="flex justify-center items-center py-1 px-2.5"
              onClick={
                disabled
                  ? undefined
                  : () => {
                      setIsOpen(true);
                      onSelect("__none__");
                      dropdownRef.current?.querySelector("input")?.focus();
                    }
              }
            >
              <SearchClose className="w-4 h-4" />
            </div>
          )}
          <div
            className="flex justify-center items-center py-1 px-2.5 pr-5"
            onClick={disabled ? undefined : () => setIsOpen(true)}
          >
            <ArrowDown
              className={`transition-all duration-500 ${
                isOpen ? "-rotate-180" : ""
              }`}
            />
          </div>
        </div>
        {isOpen && (
          <div className="shadow-custom shadow-blue-200 rounded-md overflow-hidden absolute bg-white w-full z-50">
            <input
              type="text"
              data-testid="inputparent"
              className={"pl-5 py-1 w-full outline-none border-b"}
              placeholder="Search"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              autoComplete="off"
            />
            <ul className="w-full max-h-60 overflow-auto">
              {filteredOptions.length === 0 && (
                <li className="capitalize px-5 py-2 text-gray-500">
                  Not Found
                </li>
              )}
              {filteredOptions.map((option) => (
                <li
                  className="cursor-pointer capitalize px-5 py-1 hover:bg-[#211551] hover:text-white group"
                  key={option.value}
                  onClick={() => {
                    onSelect(option.value);
                    dropdownRef.current?.querySelector("input")?.focus();
                  }}
                >
                  <div className="flex w-full items-center space-x-2">
                    <input
                      checked={selected.includes(option.value)}
                      type="checkbox"
                      readOnly
                      className="w-4 h-4 accent-[#211551] group-hover:accent-white"
                    />
                    <span>{option.label}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchableMultiSelectDropdown;
