import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate, useLocation } from "react-router-dom";

import { isAuthorizedRoute } from "../auth/validateUser";
import SpinnerComponent from "../components/Spinner";
import InvoicesMain from "../pages/InvoicesMain";
import useStore from "../store/useStore";

interface ProtectedProps {
  isConfigRoute: boolean;
}

const ProtectedRoutes: React.FC<ProtectedProps> = ({ isConfigRoute }) => {
  const { pathname } = useLocation();
  const { oktaAuth, authState } = useOktaAuth();
  const setLoader = useStore((state) => state.setLoader);

  const login = async (): Promise<void> => {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin,
    );

    oktaAuth.setOriginalUri(originalUri);
    await oktaAuth.signInWithRedirect();
  };

  if (!authState) {
    setLoader(true);

    return <SpinnerComponent />;
  }

  if (!authState.isAuthenticated) {
    login();

    return <></>;
  }

  const isAuthorized = isAuthorizedRoute(pathname);

  return (
    <>
      {!isAuthorized && <Navigate to="/unauthorized" replace />}
      {isAuthorized && <InvoicesMain isConfigRoute={isConfigRoute} />}
    </>
  );
};

export default ProtectedRoutes;
