import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import {
  clientListProps,
  dropdownOptionProps,
  clientConfigUpdate,
  fieldMapProps,
  dropdownFieldsDataProps,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import { checkIfAdmin } from "../../auth/validateUser";
import SearchableDropdown from "../../components/SearchableDropdown";
import SectionConatiner from "../../components/SectionConatiner";
import SpinnerComponent from "../../components/Spinner";
import Table from "../../components/Table";
import Textlabel from "../../components/Textlabel";
import ToastComponent from "../../components/Toast";
import useConfig, { Data, Row } from "../../hooks/useConfig";
import useStore from "../../store/useStore";

const columns: GridColDef[] = [
  {
    field: "key",
    headerName: "Attribute Name",
    minWidth: 300,
    editable: true,
    flex: 1,
    type: "search-dropdown",
    valueFormatter: (params) => params?.value?.label || "",
  },
  {
    field: "value",
    headerName: "Attribute Value",
    minWidth: 300,
    editable: true,
    flex: 1,
    type: "search-dropdown",
    valueFormatter: (params) => params?.value?.label || "",
  },
  {
    field: "lastUpdatedBy",
    headerName: "Last Updated by",
    minWidth: 300,
    editable: false,
    flex: 1,
  },
  {
    field: "lastUpdatedOn",
    headerName: "Last Updated on",
    minWidth: 200,
    editable: false,
    flex: 1,
  },
];

const CompanyConfig: React.FC = () => {
  const { data, setData, handleError, handleResponse } =
    useConfig("companyConfig");

  const enableToast = useStore((state) => state.enableToast);
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const [clientList, setClientList] = useState([]);
  const [metadata, setMetadata] = useState([]);

  const [client, setClient] = useState<dropdownOptionProps>({
    value: "",
    label: "",
  });

  const isAdmin = checkIfAdmin();
  const title = "Company Config";
  const mandatoryFields: string[] = ["key", "value"];
  const hideFields: string[] = ["lastUpdatedBy", "lastUpdatedOn"];
  const disableUpdateFields: string[] = ["key"];
  const duplicateCheckFields: string[] = ["key"];

  const attributeNameDropdown: dropdownOptionProps[] = [
    ...new Set(metadata.map((obj: { name: string }) => obj.name)),
  ]
    .filter(Boolean)
    .map((name) => ({
      value: name,
      label: name,
    }));

  const attributeValueDropdown: dropdownOptionProps[] = [
    ...new Set(metadata.map((obj: { value: string }) => obj.value)),
  ]
    .filter(Boolean)
    .map((value) => ({
      value,
      label: value,
    }));

  const dropdownFieldsData: dropdownFieldsDataProps[] = [
    {
      field: "key",
      data1: attributeNameDropdown,
    },
    {
      field: "value",
      mapToField: "key",
      data1: attributeValueDropdown,
      allowCustomValue: ({ mapToField, data1 }) =>
        !mapToField || (data1 || []).length === 0,
      selectOnly: ({ mapToField, data1 }) =>
        !!mapToField && (data1 || []).length > 0,
    },
  ];

  const fieldMap: fieldMapProps = {};

  metadata.forEach((obj: { name: string; value: string }) => {
    fieldMap[obj.name] ??= { value: [] };
    (fieldMap[obj.name].value as string[]).push(obj.value || "");
  });

  const dataToRow = (data: Data[]): Row[] => {
    return data.map((e: Data): Row => {
      const lastUpdatedDate = e.lastUpdatedDate as {
        value: string;
      };

      return {
        id: e.key as string,
        key: { value: e.key, label: e.key },
        value: { value: e.value, label: e.value },
        lastUpdatedBy: e.lastUpdatedBy as string,
        lastUpdatedOn: lastUpdatedDate.value,
      };
    });
  };

  const getData = (clientId: string): Promise<void> => {
    setLoader(true);

    const getRequest = InitiateInvoiceApi.importClientConfig(
      "GET",
      null,
      null,
      clientId,
    );

    return ResponseAPI.performRequest(getRequest)
      .then(handleResponse("__get__", dataToRow, "", { clientId }))
      .catch(handleError);
  };

  const updateData =
    (clientId: string) =>
    (rowId: string | number | null, data: Data): Promise<void> => {
      setLoader(true);
      const key = data.key as { value: string };
      const value = data.value as { value: string };

      const _updateState: clientConfigUpdate = {
        clientId,
        clientName: client.label,
        key: key.value,
        value: value.value,
      };

      const updateRequest = InitiateInvoiceApi.importClientConfig(
        "PUT",
        _updateState,
      );

      return ResponseAPI.performRequest(updateRequest)
        .then(
          handleResponse(
            rowId ? "__update__" : "__add__",
            dataToRow,
            `${
              rowId ? "Updated" : "Added"
            } successfully! The changes saved will be taken into account on the next AP/AR run.`,
            { clientId },
          ),
        )
        .catch(handleError);
    };

  const deleteData =
    (clientId: string) =>
    async (id: string | number): Promise<void> => {
      setLoader(true);

      const deleteRequest = InitiateInvoiceApi.importClientConfig(
        "DELETE",
        null,

        id
          ? {
              configs: [
                {
                  clientId,
                  key: id as string,
                },
              ],
            }
          : undefined,
      );

      return await ResponseAPI.performRequest(deleteRequest)
        .then(
          handleResponse(
            "__delete__",
            dataToRow,
            "Deleted successfully! The changes saved will be taken into account on the next AP/AR run.",
            {
              clientId,
            },
          ),
        )
        .catch(handleError);
    };

  const getClientList = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getClientList();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length === 0) {
        const clientList = (getResponse?.data?.data?.clients || [])
          .sort((a: clientListProps, b: clientListProps) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            return 0;
          })
          .map((c: clientListProps) => ({
            value: c.id,
            label: c.name,
          }));

        setClientList(clientList);
      } else {
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAttributes = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getConfigMetadata("client-config");
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length === 0) {
        setMetadata(getResponse.data.data.metadata || []);
      } else {
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownChange = (value: dropdownOptionProps): void => {
    setClient(value);
  };

  useEffect(() => {
    setLoader(true);
    Promise.all([getClientList(), getAttributes()]).finally(() =>
      setLoader(false),
    );
  }, []);

  useEffect(() => {
    if (client.value) getData(client.value);
    else setData([]);
  }, [client.value]);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <SectionConatiner title={title} className="w-full mx-auto px-10 relative">
        <Table
          suffix="Attribute"
          cols={columns}
          rows={data}
          primaryField="key"
          mandatoryFields={mandatoryFields}
          hideFields={hideFields}
          disableUpdateFields={disableUpdateFields}
          disableAdd={!client.value}
          disableCSV={!client.value}
          duplicateCheckFields={duplicateCheckFields}
          dropdownFieldsData={dropdownFieldsData}
          fieldMap={fieldMap}
          isShowActionsColumn={isAdmin}
          csvFileName={title}
          onUpdate={updateData(client.value)}
          onDelete={deleteData(client.value)}
          HeaderComponent={
            <div className="flex items-center">
              <Textlabel label="Client:" />
              <SearchableDropdown
                options={clientList}
                onSelect={handleDropdownChange}
                value={client}
              />
            </div>
          }
        />
      </SectionConatiner>
    </>
  );
};

export default CompanyConfig;
