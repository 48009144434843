import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";

import { getUserRoles } from "../auth/validateUser";
import SpinnerComponent from "../components/Spinner";
import useStore from "../store/useStore";

const Home: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const setLoader = useStore((state) => state.setLoader);
  const userRoles = getUserRoles();

  const login = async (): Promise<void> => {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin,
    );

    oktaAuth.setOriginalUri(originalUri);
    await oktaAuth.signInWithRedirect();
  };

  if (!authState) {
    setLoader(true);

    return <SpinnerComponent />;
  }

  if (!authState.isAuthenticated) {
    login();

    return <></>;
  }

  let navigateTo = "/unauthorized";

  if (userRoles.includes("vmsops_admin")) {
    navigateTo = "/invoices/ap";
  } else if (userRoles.includes("vmsops_ap")) {
    navigateTo = "/invoices/ap";
  } else if (userRoles.includes("vmsops_ar")) {
    navigateTo = "/invoices/arnonvms";
  } else if (userRoles.includes("vmsops_pwc")) {
    navigateTo = "/invoices/pwc-te";
  } else if (userRoles.includes("vmsops_s4")) {
    navigateTo = "/invoices/s4-upload";
  }

  return <Navigate to={navigateTo} replace />;
};

export default Home;
