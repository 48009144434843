import React, { SyntheticEvent } from "react";

import Button from "../../components/Button";
import Datepicker from "../../components/Datepicker";
import DropDown from "../../components/Dropdown";
import SectionConatiner from "../../components/SectionConatiner";
import Tooltip from "../../components/Tooltip";

import { inputProps } from "./InitiateSection";

export interface section2Props {
  inputVal: inputProps;
  handleValidateInvoice?: (e: SyntheticEvent) => Promise<void>;
  dropDownData: Array<{
    id: string;
    name: string;
  }>;
  handleGenerateInvoice?: (e: SyntheticEvent) => void;
  setInputVal: (val: inputProps) => void;
  handleDateChange: (e: Date, name: string) => void;
}

const Section3: React.FC<section2Props> = ({
  inputVal,
  handleGenerateInvoice,
  dropDownData,
  setInputVal,
  handleDateChange,
}) => {
  return (
    <div className="w-5/6 mx-auto">
      <SectionConatiner
        title="Generate Invoices"
        collapse={true}
        className="px-10 overflow-visible"
        infoText="Expand to generate Invoices"
      >
        <form className="flex flex-col gap-10 my-10 items-center">
          <div className="flex flex-row justify-between items-baseline p-2 w-[90%] gap-20 ">
            <div className="flex gap-3 relative justify-between items-center">
              InvoiceFile WeekEnd date :
              <Tooltip title="Invoice week end date, invoice file will be created using this name." />
              <Datepicker
                value={inputVal.invoiceFileDate || null}
                onChange={(e) => handleDateChange(e, "invoiceFileDate")}
                showClearIcon={false}
                placeholderText="mm / dd / yyyy"
                className="shadow-custom px-3 py-1.5 shadow-blue-200"
                showonlySundays={true}
              />
            </div>
            <div className="flex gap-3 items-center justify-between">
              Invoice Type:
              <DropDown
                arrayList={dropDownData}
                changeHandler={(value) =>
                  setInputVal({ ...inputVal, dropDownGenerate: value })
                }
                value={inputVal.dropDownGenerate}
                styleVal={{ width: "150px" }}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              disabled={false}
              label="Generate Invoices"
              type="submit"
              onClick={handleGenerateInvoice}
            />
          </div>
        </form>
      </SectionConatiner>
    </div>
  );
};

export default Section3;
