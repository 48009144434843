import { EVENT_EXPIRED } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { usePageVisibility } from "react-page-visibility";

import { signOut } from "../../auth/oktaConfig";
import useStore from "../../store/useStore";
import Button from "../Button";
import ConfirmModal from "../Modalwindow";

const SessionExpiredPopup: React.FC<{}> = () => {
  const enableLoader = useStore((state) => state.enableLoader);
  const { oktaAuth } = useOktaAuth();
  const isVisible = usePageVisibility();
  const [, setExpiredTime] = useState(0);

  const token =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

  const now: number = Date.now() / 1000;
  const tokenExpiry: number = token?.accessToken?.expiresAt;

  const isShowPopup =
    isVisible && tokenExpiry && now > tokenExpiry && !enableLoader;

  const logout = async (): Promise<void> => {
    try {
      signOut();
    } catch (e) {
      console.log("something went wrong ");
    }
  };

  useEffect(() => {
    const expired = (): void => {
      setTimeout(() => {
        setExpiredTime(Date.now());
      }, 31 * 1000);
    };

    oktaAuth.tokenManager.on(EVENT_EXPIRED, expired);

    return () => {
      oktaAuth.tokenManager.off(EVENT_EXPIRED, expired);
    };
  }, []);

  if (!isShowPopup) return <></>;

  return (
    <ConfirmModal>
      <header className="flex justify-between items-center px-5">
        <h1 className="font-bold text-lg mx-auto ">Session Expired</h1>
      </header>
      <div className="border border-[#211551] w-full my-3"></div>
      <div className="px-5 w-[400px] pt-4">
        <div className="text-center w-full">
          It appears that your session has expired. Please login to continue.
        </div>
        <footer className="flex w-full justify-center mt-8 space-x-2.5">
          <Button
            type="submit"
            label="Login"
            onClick={logout}
            disabled={false}
            styleVal="w-24 transition ease-in-out delay-150 hover:scale-110"
          />
        </footer>
      </div>
    </ConfirmModal>
  );
};

export default SessionExpiredPopup;
