import React, { useEffect, useState } from "react";
import { BsGear } from "react-icons/bs";
import { MdOutlineHelpOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";

import MBOLogo from "../../assets/MBO-logo-symbol.svg";
import { signOut } from "../../auth/oktaConfig";
import { getUserRoles } from "../../auth/validateUser";
import { features } from "../../constants/features";
import Help from "../../data/Help.json";

const {
  isConfigurationRouteEnabled,
  isGlobalConfigRouteEnabled,
  isInvoicePresentationRouteEnabled,
  isGenerateInvoicePDFRouteEnabled,
  isScheduleBatchRouteEnabled,
  isFeeSplittingForClientsRouteEnabled,
  isClientTemplateRouteEnabled,
} = features.configuration;

interface IProps {
  unauthorized?: boolean;
}

const Header: React.FC<IProps> = ({ unauthorized }) => {
  const [userName, setUserName] = useState<string | null>("");
  const userRoles = getUserRoles();

  const HelpMenu = Help.map((h) => {
    return {
      ...h,
      children: h.children.filter(({ url, roles }) => {
        if (!url) return false;

        return userRoles.some((element) => roles.includes(element));
      }),
    };
  }).filter(({ children }) => children.length);

  const logout = async (): Promise<void> => {
    try {
      signOut();
    } catch (e) {
      console.log("something went wrong ");
    }
  };

  useEffect(() => {
    let profileName = localStorage.getItem("okta-token-storage");

    if (profileName) {
      profileName = JSON.parse(profileName).idToken?.claims?.name;
      setUserName(profileName);
    }
  }, []);

  return (
    <div className="w-11/12 mx-auto flex justify-between">
      <NavLink to={"/"} className="flex gap-4 items-center">
        <img src={MBOLogo} alt="MBOLogo" className="w-10" />
        <span>VMS-Ops Application</span>
      </NavLink>
      <div className="flex gap-20 items-center">
        <h1>
          Hello,
          <span className="font-bold tracking-wider capitalize pl-2">
            {userName}
          </span>
        </h1>
        {!unauthorized && isConfigurationRouteEnabled && (
          <div className="relative group">
            <div className="flex space-x-2 justify-center items-center">
              <BsGear className="w-5 h-5" />
              <h1>Configuration</h1>
            </div>
            <div className="hidden group-hover:block absolute top-6 left-0 text-sm text-black w-48 bg-white shadow-custom z-50 p-2 rounded-sm">
              <ul>
                <div className="w-7 h-7 bg-white absolute -top-2 left-5 transform -translate-x-2 -rotate-45"></div>
                <div className="hover:bg-[#211551] hover:text-white p-2 relative group/expense cursor-default whitespace-nowrap">
                  Expense Type Mapping
                  <ul className="hidden group-hover/expense:block absolute right-full -top-2 min-w-[125px] text-black bg-white shadow-custom z-50 p-2 rounded-sm">
                    <div className="w-7 h-7 bg-white absolute top-3 -right-2 transform -translate-x-2 -rotate-45"></div>
                    <NavLink
                      to={"/config/normal_expense"}
                      className="hover:bg-[#211551] hover:text-white p-2 block"
                    >
                      Normal Expense
                    </NavLink>
                    <NavLink
                      to={"/config/special_expense"}
                      className="hover:bg-[#211551] hover:text-white p-2 block"
                    >
                      Special Expense
                    </NavLink>
                  </ul>
                </div>
                <NavLink
                  to={"/config/company_config"}
                  className="hover:bg-[#211551] hover:text-white p-2 block"
                >
                  Company Config
                </NavLink>
                <NavLink
                  to={"/config/time_mapping"}
                  className="hover:bg-[#211551] hover:text-white p-2 block"
                >
                  Time Mapping
                </NavLink>
                {isGlobalConfigRouteEnabled && (
                  <NavLink
                    to={"/config/global_config"}
                    className="hover:bg-[#211551] hover:text-white p-2 block"
                  >
                    Global Config
                  </NavLink>
                )}
                {isInvoicePresentationRouteEnabled && (
                  <div className="hover:bg-[#211551] hover:text-white p-2 relative group/invoice cursor-default whitespace-nowrap">
                    Invoice Presentation
                    <ul className="hidden group-hover/invoice:block absolute right-full -top-2 min-w-[125px] text-black bg-white shadow-custom z-50 p-2 rounded-sm">
                      <div className="w-7 h-7 bg-white absolute top-3 -right-2 transform -translate-x-2 -rotate-45"></div>
                      {isGenerateInvoicePDFRouteEnabled && (
                        <NavLink
                          to={"/config/generate_invoice_pdf"}
                          className="hover:bg-[#211551] hover:text-white p-2 block"
                        >
                          Generate Invoice PDF
                        </NavLink>
                      )}
                      {isScheduleBatchRouteEnabled && (
                        <NavLink
                          to={"/config/schedule_batch"}
                          className="hover:bg-[#211551] hover:text-white p-2 block"
                        >
                          Batch Config
                        </NavLink>
                      )}
                      {isFeeSplittingForClientsRouteEnabled && (
                        <NavLink
                          to={"/config/fee_splitting"}
                          className="hover:bg-[#211551] hover:text-white p-2 block"
                        >
                          Client Config
                        </NavLink>
                      )}
                      {isClientTemplateRouteEnabled && (
                        <NavLink
                          to={"/config/client_template"}
                          className="hover:bg-[#211551] hover:text-white p-2 block"
                        >
                          Client Template
                        </NavLink>
                      )}
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          </div>
        )}
        {!unauthorized && HelpMenu.length > 0 && (
          <div className="relative group">
            <div className="flex space-x-2 justify-center items-center">
              <MdOutlineHelpOutline className="w-5 h-5" />
              <h1>Help</h1>
            </div>
            <ul className="hidden group-hover:block absolute top-6 left-2 min-w-[125px] text-sm text-black bg-white shadow-custom z-50 p-2 rounded-sm">
              <div className="w-7 h-7 bg-white absolute top-0 left-5 transform -translate-x-2 -rotate-45"></div>
              {HelpMenu.map(({ id, title, children }) => {
                if (children.length > 1) {
                  return (
                    <div
                      key={id}
                      className="hover:bg-[#211551] hover:text-white p-2 relative group/menu cursor-default whitespace-nowrap"
                    >
                      {title}
                      <ul className="hidden group-hover/menu:block absolute right-full -top-2 min-w-[125px] text-black bg-white shadow-custom z-50 p-2 rounded-sm">
                        <div className="w-7 h-7 bg-white absolute top-3 -right-2 transform -translate-x-2 -rotate-45"></div>
                        {children.map(({ id, title, url }) => {
                          return (
                            <NavLink
                              key={id}
                              to={url}
                              target="_blank"
                              className="hover:bg-[#211551] hover:text-white p-2 block whitespace-nowrap"
                            >
                              {title}
                            </NavLink>
                          );
                        })}
                      </ul>
                    </div>
                  );
                } else if (children.length === 1) {
                  return (
                    <NavLink
                      key={id}
                      to={children[0].url}
                      target="_blank"
                      className="hover:bg-[#211551] hover:text-white p-2 block whitespace-nowrap"
                    >
                      {title}
                    </NavLink>
                  );
                } else return null;
              })}
            </ul>
          </div>
        )}
        <button onClick={logout}>Log out</button>
      </div>
    </div>
  );
};

export default Header;
