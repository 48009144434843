const ArrowDown: React.FC<IconProp> = (props) => (
  <svg
    fill="blue"
    height={6}
    width={9}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m.977 1.648 3.375 3.374c.22.22.576.22.796 0l3.375-3.374a.563.563 0 1 0-.796-.796L4.75 3.83 1.773.852a.563.563 0 1 0-.796.796Z" />
  </svg>
);

export default ArrowDown;
