import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridPaginationModel,
  getGridStringOperators,
  GridFilterModel,
} from "@mui/x-data-grid";
import JSPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import React from "react";

import { detailRecordProps } from "../../@types/assetTypes/ap";
import MBOLogo from "../../assets/MBOParners.png";
import Empty from "../../pages/S4Upload/components/Empty";
import RadioButton from "../../pages/S4Upload/components/RadioButton";
import Toolbar from "../../pages/S4Upload/components/Toolbar";
import useStore from "../../store/useStore";
import Button from "../Button";
import ExpandableCell from "../ExpandableCell";
import SectionConatiner from "../SectionConatiner";

interface TableDataProps {
  [key: string]: string | number;
}

interface detailProps {
  records: Array<{
    id: number;
    recordId: number;
    status: string;
    remarks: string;
  }>;
  processId: GridRowId;
  totalRowCount: number;
  paginationModel: GridPaginationModel | undefined;
  filterModel: GridFilterModel;
  issuesFilter: string;
  getDetailData: (processId: GridRowId) => Promise<detailRecordProps[]>;
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
  onFilterChange: (filterModel: GridFilterModel) => void;
  onIssuesFilterChange: (id: string) => void;
  onClose: () => void;
}

const modifyData = (remarks: string): string => {
  let hyperlinkText = remarks.split("\n").join("<br/>");

  hyperlinkText = hyperlinkText.split(";").join("<br/>");
  hyperlinkText = hyperlinkText.replace(
    /https?:\/\/\S+/g,
    (url) =>
      `<a href="${url}" target="_blank" style="color:blue; text-decoration:underline">here</a>`,
  );

  return hyperlinkText;
};

const modifyPDFData = (remarks: string): string => {
  return remarks.split(";").join("\n");
};

const columns: GridColDef[] = [
  {
    field: "invoiceID",
    flex: 1,
    sortable: false,
    filterable: false,
    headerName: "Invoice Id",
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.4,
    cellClassName: "capitalize",
    sortable: false,
    filterable: false,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    flex: 1,
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains",
    ),
    renderCell: (params: GridRenderCellParams) => (
      <ExpandableCell {...params} />
    ),
  },
];

const index: React.FC<detailProps> = ({
  records,
  processId,
  totalRowCount,
  paginationModel,
  filterModel,
  issuesFilter,
  getDetailData,
  onPaginationModelChange,
  onFilterChange,
  onIssuesFilterChange,
  onClose,
}) => {
  const setLoader = useStore((state) => state.setLoader);
  const enableToast = useStore((state) => state.enableToast);
  const [rowCountState, setRowCountState] = React.useState(totalRowCount || 0);

  const rows: GridRowsProp = records.map((item) => {
    return {
      id: item.id,
      invoiceID: item.recordId,
      status: item.status
        ?.toLowerCase()
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase()),
      remarks: modifyData(
        item.remarks
          .replace(/([A-Z])/g, "$1")
          .replace(/^./, (str) => str.toUpperCase()),
      ),
    };
  });

  const addRulesTablesToPdf = (rules: TableDataProps[]): void => {
    const doc = new JSPDF("p", "px");

    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text(`Process Run Number: ${processId}`, 30, 45);

    const rawDate = new Date().getTime();
    const dateFormat = moment(rawDate).format("YYYY-MMM-DD, h:mm:ss A");

    autoTable(doc, {
      theme: "grid",
      margin: { top: 55, bottom: 30 },
      horizontalPageBreak: false,
      headStyles: {
        minCellWidth: 120,
        minCellHeight: 12,
        fillColor: [33, 21, 81],
      },
      bodyStyles: {
        cellWidth: "auto",
        minCellWidth: 12,
        minCellHeight: 12,
      },
      body: rules,
      columns: Object.keys(rules[0]).map((e) => ({
        header: e
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase()),
        dataKey: e,
      })),

      didDrawCell: function (data) {
        if (
          data.column.dataKey === "remarks" &&
          data.section === "body" &&
          data.cell.raw?.toString().includes("Click") &&
          data.cell.text.includes("")
        ) {
          const lastValue =
            typeof data.cell.raw === "string"
              ? data.cell.raw.split(/https?:\/\/\S+/)
              : "";

          doc.text(
            "Click",
            data.cell.x + 4,
            data.cell.y + data.cell.contentHeight - 15,
          );
          doc.setTextColor("blue");
          const onlyLink =
            typeof data.cell.raw === "string" &&
            data.cell.raw &&
            data.cell.raw.match(/(https?:\/\/\S+)/g);

          doc.textWithLink(
            " here ",
            data.cell.x + 19,
            data.cell.y + data.cell.contentHeight - 15,
            {
              url: onlyLink && onlyLink[onlyLink.length - 1],
            },
          );
          doc.setTextColor(40);
          doc.text(
            lastValue[lastValue.length - 1].slice(0, 28),
            data.cell.x + 36,
            data.cell.y + data.cell.contentHeight - 15,
          );
          doc.text(
            lastValue[lastValue.length - 1].slice(28, lastValue[1].length),
            data.cell.x + 3,
            data.cell.y + data.cell.contentHeight - 5,
          );
        }
      },

      didParseCell: function (data) {
        if (
          data.column.dataKey === "remarks" &&
          data.section === "body" &&
          data.cell.raw?.toString().includes("Click")
        ) {
          data.cell.text.pop();
          data.cell.text.push("", "", "");
        }
      },

      didDrawPage: function () {
        // Header
        doc.setTextColor(40);

        doc.addImage(MBOLogo, "png", 30, 10, 60, 22);

        doc.text(
          dateFormat,
          doc.internal.pageSize.width - doc.getTextWidth(dateFormat) - 30,
          25,
        );

        // Footer

        const footerText =
          "MBO Partners, 20405 Exchange Street,Suite 301, Ashburn,VA 20147 USA, Phone: 1-703-793-6000";

        doc.setFontSize(9);

        doc.text(
          footerText,
          doc.internal.pageSize.width / 2 - doc.getTextWidth(footerText) / 2,
          doc.internal.pageSize.height - 20,
          { baseline: "bottom" },
        );
        const pageNums: string = doc.getNumberOfPages().toString();
        const str = `Page ${pageNums}`;

        doc.text(
          str,
          doc.internal.pageSize.width / 2 - doc.getTextWidth(str) / 2,
          doc.internal.pageSize.height - 10,
          { baseline: "bottom" },
        );
      },
    });

    doc.save(`MBO RunHistory ${dateFormat}`);
  };

  const downloadToPdf = (): void => {
    setLoader(true);
    getDetailData(processId)
      .then((records) => {
        if (records?.length) {
          addRulesTablesToPdf(
            records?.map((item) => {
              return {
                invoiceId: item.recordId,
                status: item.status
                  ?.toLowerCase()
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase()),
                remarks: modifyPDFData(
                  item.remarks
                    .replace(/([A-Z])/g, "$1")
                    .replace(/^./, (str) => str.toUpperCase()),
                ),
              };
            }),
          );
        }

        setLoader(false);
      })
      .catch((e: { message: string }) => {
        enableToast({
          message: e?.message || "Unable to generate PDF",
          type: "error",
        });
        setLoader(false);
      });
  };

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState,
    );
  }, [totalRowCount, setRowCountState]);

  return (
    <>
      <header className="flex justify-between items-center px-5">
        <h1 className="font-bold text-lg mx-auto ">Detailed Run Report</h1>
        <button
          onClick={onClose}
          className="font-mono font-bold text-xl border-0 text-[#211551] leading-4 "
        >
          x
        </button>
      </header>
      <div className="border border-[#211551] w-full my-3"></div>
      <div className="px-5">
        <SectionConatiner
          title={`Process Run Number: ${processId}`}
          className="w-full mx-auto mt-14 px-5"
        >
          <div className="flex items-center justify-between">
            <div className="flex space-x-5 items-center py-2">
              <RadioButton
                id="all-radio"
                name="all-radio-group"
                label="All"
                checked={issuesFilter === "all"}
                onChange={() => onIssuesFilterChange("all")}
              />
              <RadioButton
                id="issues-radio"
                name="issues-radio-group"
                label="Issues"
                checked={issuesFilter === "issues"}
                onChange={() => onIssuesFilterChange("issues")}
              />
              <RadioButton
                id="noissues-radio"
                name="noissues-radio-group"
                label="No Issues"
                checked={issuesFilter === "noissues"}
                onChange={() => onIssuesFilterChange("noissues")}
              />
            </div>
          </div>
          <div className="flex-grow">
            <DataGrid
              rowCount={rowCountState}
              rows={rows}
              columns={columns}
              slots={{ toolbar: Toolbar, loadingOverlay: Empty }}
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              pageSizeOptions={[20, 50, 100]}
              pagination
              paginationMode="server"
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={onFilterChange}
              sx={{
                marginTop: 2,
                marginX: "auto",
                border: 0.5,
                borderColor: "gray",
                width: 1100,
                height: 384,
                "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
                  borderBottomWidth: 0,
                },
                "& .MuiDataGrid-cell:hover": {
                  // color: "primary.main",
                  cursor: "pointer",
                },
                "& .MuiDataGrid-toolbarContainer": {
                  "& .MuiButtonBase-root": { color: "#211551" },
                },

                "& .MuiDataGrid-columnHeaders": {
                  bgcolor: "#211551",
                  color: "white",
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                  },
                  "& .MuiIconButton-sizeSmall": {
                    color: "white",
                  },
                },
                "& .MuiDataGrid-row": { fontSize: "small" },
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "4px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "10px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "17px",
                },
                "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
                  {
                    outline: "none",
                  },
              }}
              getRowHeight={() => "auto"}
              isCellEditable={() => false}
              columnHeaderHeight={44}
              getEstimatedRowHeight={() => 100}
              disableRowSelectionOnClick
              disableColumnMenu
            />
          </div>
        </SectionConatiner>
      </div>

      <footer className="flex w-full justify-center mt-8">
        <Button
          type="submit"
          label="Generate PDF"
          disabled={false}
          onClick={downloadToPdf}
          styleVal="transition ease-in-out delay-50 hover:scale-110"
        />
      </footer>
    </>
  );
};

export default index;
