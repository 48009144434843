import React, { SyntheticEvent } from "react";

import { uploadEBDRForm } from "../../@types/assetTypes/ap";
import Button from "../../components/Button";
import FileUpload from "../../components/FileUpload";
import Textlabel from "../../components/Textlabel";

interface ArvmsProps {
  inputVal: uploadEBDRForm;
  handleFileUpload: (file: File) => void;
  handleDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleSubmit: (e: SyntheticEvent) => void;
  buttonDisabled: boolean;
  closeFileUploaded: () => void;
}

const InitiateSection: React.FC<ArvmsProps> = ({
  inputVal,
  handleFileUpload,
  handleDescriptionChange,
  handleSubmit,
  buttonDisabled,
  closeFileUploaded,
}) => {
  return (
    <div className="py-5 shadow-custom">
      <form className="my-10 px-5 mx-auto">
        <div className="flex flex-col items-center w-full gap-7">
          <div className="flex flex-wrap w-full gap-10 items-center justify-center">
            <div className="relative">
              <div className="flex gap-4 items-center justify-between">
                <Textlabel label="Upload EBDR File" mandatory />
                <FileUpload
                  acceptedFormats={[".xlsx"]}
                  handleFileUpload={handleFileUpload}
                />
              </div>
              {inputVal.file && (
                <div className="absolute -bottom-8 right-0 w-96 flex justify-end items-center">
                  <span className="w-full truncate">{inputVal.file?.name}</span>
                  <span
                    onClick={closeFileUploaded}
                    className="font-mono font-bold cursor-pointer text-[#211551]/40 leading-4 hover:text-[#211551] ml-3"
                  >
                    x
                  </span>
                </div>
              )}
            </div>
            <div className="flex justify-between w-[574px] xl:w-auto gap-4 items-center">
              <Textlabel label="Comments" />
              <textarea
                value={inputVal.remarks}
                onChange={handleDescriptionChange}
                className="w-96 h-32 resize-none flex justify-between border shadow-custom shadow-blue-200 px-5 py-2 max-w-full outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              ></textarea>
            </div>
          </div>
          <div className="flex mt-5 w-full justify-center pl-48">
            <Button
              label="Create Billing Document Request"
              type="submit"
              onClick={handleSubmit}
              disabled={buttonDisabled}
              customWidth="w-96 max-w-full"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InitiateSection;
