import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";

import { S4RunHistoryDetails } from "../../@types/assetTypes/ap";
import Button from "../../components/Button";
import SectionContainer from "../../components/SectionConatiner";
import {
  s4DetailsShowCreateInvoiceButton,
  s4DetailsShowDeleteBDRButton,
  s4DetailsShowErrorTable,
} from "../../constants/s4upload";
import { includesStatus } from "../../utils";

import ConfirmationModal from "./components/ConfirmationModal";
import Flow from "./components/Flow";

interface detailProps {
  details: S4RunHistoryDetails | undefined;
  onBackClick: () => void;
  onDeleteBDRs: () => Promise<void>;
  onCreateInvoice: () => Promise<void>;
  onDownloadVMSFile: (fileName: string) => Promise<void>;
  onDownloadEBDRFile: (fileName: string) => Promise<void>;
}

const bdrTableColumns: GridColDef[] = [
  {
    field: "bdrId",
    headerName: "BDR ID",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "referenceDocument",
    headerName: "Reference Document",
    minWidth: 175,
    flex: 1,
  },
  {
    field: "billingDateFormatted",
    headerName: "Billing Date (UTC)",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "bdrCreationDate",
    headerName: "BDR Creation Date (UTC)",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "grossAmount",
    headerName: "Gross Amount",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "invoices",
    headerName: "Invoice IDs",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    minWidth: 225,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 150,
    flex: 1,
  },
];

const errorTableColumns: GridColDef[] = [
  {
    field: "referenceDocument",
    headerName: "Reference Document",
    width: 250,
  },
  {
    field: "referenceDocumentItem",
    headerName: "Reference Document Item",
    width: 250,
  },
  {
    field: "log",
    headerName: "Error Description",
    minWidth: 350,
    flex: 1,
  },
];

const DetailSection: React.FC<detailProps> = ({
  details,
  onBackClick,
  onDeleteBDRs,
  onCreateInvoice,
  onDownloadVMSFile,
  onDownloadEBDRFile,
}) => {
  const [isShowDeletePopup, setShowDeletePopup] = useState(false);
  const [isShowCreateInvoicePopup, setShowCreateInvoicePopup] = useState(false);

  let columns: GridColDef[] = [];
  let rows: GridRowsProp = [];
  let idField: string = "brdId";

  const vmsTotal = details?.vmsTotal?.value || "";
  const vmsEbdrDelta = details?.vmsEbdrDelta?.value || "";
  const vmsFileName = details?.vmsFileName || "";
  const ebdrTotal = details?.ebdrTotal?.value || "";
  const ebdrS4bdrDelta = details?.ebdrS4bdrDelta?.value || "";
  const ebdrFileName = details?.ebdrFileName || "";
  const s4BdrTotal = details?.s4BdrTotal?.value || "";

  const referenceErrorDetails = (details?.referenceErrorDetails || []).map(
    (r, i) => ({ ...r, customId: `${i}-${r.referenceDocument}` }),
  );

  const uploadedOn: string = details?.uploadedAt?.value || "";
  const [dbStatus = "", displayStatus = ""] = details?.status?.split(":") || [];

  const isShowDeleteBDRButton: boolean = includesStatus(
    s4DetailsShowDeleteBDRButton,
    dbStatus,
  );

  const isShowCreateInvoiceButton: boolean = includesStatus(
    s4DetailsShowCreateInvoiceButton,
    dbStatus,
  );

  if (
    includesStatus(
      [
        "BDR_CREATED_SUCCESSFULLY",
        "BDR_DETAILS_READ_SUCCESSFULLY",
        "ERROR_IN_CALLING_BDR_READ",
        "INVOICE_CREATE_INITIATED",
        "INVOICE_CREATE_API_CALLED_SUCCESSFULLY",
        "FAILED_TO_CALL_INVOICE_CREATE_API",
        "BDR_DELETE_INITIATED",
        "BDR_DELETED_PARTIALLY",
        "BDR_DELETE_FAILED",
        "BDR_DELETED",
      ],
      dbStatus,
    )
  ) {
    columns = bdrTableColumns.filter(
      ({ field }) => !["invoices", "remarks"].includes(field),
    );

    rows = (details?.bdrDetails || []).map((item) => {
      return {
        ...item,
        bdrId: item.bdrId ? Number(item.bdrId) : null,
        billingDateFormatted: item.billingDate?.value || "",
        bdrCreationDate: item.bdrCreationDate?.value || "",
        grossAmount: item.bdrTotal?.value || "",
      };
    });
    idField = "bdrId";
  } else if (
    includesStatus(
      [
        "INVOICE_CREATED_SUCCESSFULLY",
        "INVOICE_CREATED_WITH_ERRORS",
        "BDR_CREATED_WITH_ERRORS",
      ],
      dbStatus,
    )
  ) {
    if (includesStatus(["BDR_CREATED_WITH_ERRORS"], dbStatus)) {
      columns = bdrTableColumns.filter(
        ({ field }) => !["invoices", "remarks"].includes(field),
      );
    } else if (includesStatus(["INVOICE_CREATED_WITH_ERRORS"], dbStatus)) {
      columns = bdrTableColumns;
    } else {
      columns = bdrTableColumns.filter(({ field }) => field !== "remarks");
    }

    rows = (details?.bdrDetails || []).map((item) => {
      let remarks = "";

      if (includesStatus(["INVOICE_CREATED_WITH_ERRORS"], dbStatus)) {
        remarks = (details?.invoiceDetails || [])
          .filter(({ bdrId }) => bdrId === item.bdrId)
          .map(({ log }) => (log ? `- ${log}` : ""))
          .filter(Boolean)
          .join("\n");
      }

      const invoices = [
        ...new Set(
          (details?.invoiceDetails || [])
            .filter(({ bdrId }) => bdrId === item.bdrId)
            .map(({ invoiceId }) => invoiceId)
            .filter(Boolean),
        ),
      ].join(", ");

      return {
        ...item,
        bdrId: item.bdrId ? Number(item.bdrId) : null,
        billingDateFormatted: item.billingDate?.value || "",
        bdrCreationDate: item.bdrCreationDate?.value || "",
        grossAmount: item.bdrTotal?.value || "",
        invoices,
        remarks,
      };
    });
    idField = "bdrId";
  }

  return (
    <>
      <div className="flex flex-col px-5 space-y-10">
        <div className="flex w-full py-5">
          <Button
            label="Back"
            disabled={false}
            onClick={onBackClick}
            type={"button"}
            Icon={<BiArrowBack />}
            styleVal="border border-neutral-300 bg-white"
            customFontColor="text-[#211551]"
          />
        </div>
        <SectionContainer
          title="Upload Details"
          className="w-full mx-auto py-10"
        >
          <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-center w-full space-y-5 lg:space-x-20 lg:space-y-0 px-10">
            <div className="flex flex-col space-y-5 w-full lg:w-1/2 max-w-md">
              <div className="flex justify-between gap-3 items-center">
                <div className="flex-shrink-0">EBDR Run ID</div>
                <input
                  type="text"
                  className="w-full h-10 border shadow-custom shadow-blue-200 px-5 py-1 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  value={details?.id}
                  readOnly
                />
              </div>
              <div className="flex justify-between gap-3 items-center">
                <div className="flex-shrink-0">File Name</div>
                <input
                  type="text"
                  className="w-full h-10 border shadow-custom shadow-blue-200 px-5 py-1 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  value={details?.ebdrFileName}
                  readOnly
                />
              </div>
              <div className="flex justify-between gap-3 items-center">
                <div className="flex-shrink-0">Status</div>
                <input
                  type="text"
                  className="w-full h-10 border shadow-custom shadow-blue-200 px-5 py-1 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  value={displayStatus}
                  readOnly
                />
              </div>
              <div className="flex justify-between gap-3 items-center">
                <div className="flex-shrink-0">Uploaded By</div>
                <input
                  type="text"
                  className="w-full h-10 border shadow-custom shadow-blue-200 px-5 py-1 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  value={details?.uploadedBy}
                  readOnly
                />
              </div>
            </div>
            <div className="flex flex-col space-y-5 w-full lg:w-1/2 max-w-md">
              <div className="flex justify-between gap-3 items-center">
                <div className="flex-shrink-0">Last Action By</div>
                <input
                  type="text"
                  className="w-full h-10 border shadow-custom shadow-blue-200 px-5 py-1 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  value={details?.lastActionBy}
                  readOnly
                />
              </div>
              <div className="flex justify-between gap-3 items-center">
                <div className="flex-shrink-0">Comments</div>
                <textarea
                  className="w-full h-32 resize-none border shadow-custom shadow-blue-200 px-5 py-2 max-w-xs outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                  value={details?.remarks}
                  readOnly
                ></textarea>
              </div>
            </div>
          </div>
          <div className="!mt-0 w-full overflow-auto">
            <Flow
              vmsTotal={vmsTotal}
              vmsEbdrDelta={vmsEbdrDelta}
              vmsFileName={vmsFileName}
              ebdrTotal={ebdrTotal}
              ebdrS4bdrDelta={ebdrS4bdrDelta}
              ebdrFileName={ebdrFileName}
              s4BdrTotal={s4BdrTotal}
              uploadedOn={uploadedOn}
              dbStatus={dbStatus}
              onDownloadVMSFile={onDownloadVMSFile}
              onDownloadEBDRFile={onDownloadEBDRFile}
            />
          </div>
        </SectionContainer>
        {columns.length > 0 && (
          <div className="!mt-0">
            <SectionContainer
              title="Billing Document Request Details"
              className="w-full mx-auto mt-14 px-5"
              infoText="Expand to view Billing Document Request Details"
            >
              <div
                className={[
                  rows.length < 20
                    ? "max-h-[850px]"
                    : "min-h-[450px] max-h-[850px] h-[70vh] 2xl:h-[80vh]",
                  "mx-auto my-5 border border-[#211551] rounded-md",
                ].join(" ")}
              >
                <DataGrid
                  autoHeight={rows.length < 20}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: idField, sort: "asc" }],
                    },
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  pageSizeOptions={[20]}
                  pagination
                  sx={{
                    "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
                      borderBottomWidth: 0,
                    },
                    "& .MuiDataGrid-cell:hover": {
                      // color: "primary.main",
                      cursor: "pointer",
                    },
                    "& .MuiDataGrid-toolbarContainer": {
                      "& .MuiButtonBase-root": { color: "#211551" },
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      bgcolor: "#211551",
                      color: "white",
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                      },
                      "& .MuiIconButton-sizeSmall": {
                        color: "white",
                      },
                    },
                    "& .MuiDataGrid-row": { fontSize: "small" },
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "4px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "10px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "17px",
                      },
                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within":
                      {
                        outline: "none",
                      },
                    "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
                      {
                        outline: "none",
                      },
                  }}
                  getRowHeight={() => "auto"}
                  isCellEditable={() => false}
                  columnHeaderHeight={44}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  getRowId={(row) => row[idField]}
                />
              </div>
            </SectionContainer>
          </div>
        )}
        {includesStatus(s4DetailsShowErrorTable, dbStatus) &&
          referenceErrorDetails.length > 0 && (
            <div className="!mt-0">
              <SectionContainer
                title="Errors while creating BDR"
                className="w-full mx-auto mt-14 px-5"
                infoText="Expand to view Billing Document Request Details"
              >
                <div
                  className={[
                    referenceErrorDetails.length < 20
                      ? "max-h-[850px]"
                      : "min-h-[450px] max-h-[850px] h-[70vh] 2xl:h-[80vh]",
                    "mx-auto my-5 border border-[#211551] rounded-md",
                  ].join(" ")}
                >
                  <DataGrid
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                      },
                    }}
                    autoHeight={referenceErrorDetails.length < 20}
                    rows={referenceErrorDetails}
                    columns={errorTableColumns}
                    pageSizeOptions={[20]}
                    pagination
                    sx={{
                      "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
                        borderBottomWidth: 0,
                      },
                      "& .MuiDataGrid-cell:hover": {
                        // color: "primary.main",
                        cursor: "pointer",
                      },
                      "& .MuiDataGrid-toolbarContainer": {
                        "& .MuiButtonBase-root": { color: "#211551" },
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        bgcolor: "#211551",
                        color: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                        "& .MuiIconButton-sizeSmall": {
                          color: "white",
                        },
                      },
                      "& .MuiDataGrid-row": { fontSize: "small" },
                      "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                        py: "4px",
                      },
                      "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                        py: "10px",
                      },
                      "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                        {
                          py: "17px",
                        },
                      "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within":
                        {
                          outline: "none",
                        },
                      "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
                        {
                          outline: "none",
                        },
                    }}
                    getRowHeight={() => "auto"}
                    isCellEditable={() => false}
                    columnHeaderHeight={44}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    getRowId={(row) => row.customId}
                  />
                </div>
              </SectionContainer>
            </div>
          )}
        <div className="flex w-full space-x-10 justify-between">
          <Button
            label="Back"
            disabled={false}
            onClick={onBackClick}
            type={"button"}
            Icon={<BiArrowBack />}
            styleVal="border border-neutral-300 bg-white"
            customFontColor="text-[#211551]"
          />
          <div className="flex space-x-5">
            {isShowDeleteBDRButton && (
              <Button
                label="Delete BDRs"
                type="submit"
                disabled={false}
                styleVal="bg-red-600"
                customWidth="w-36"
                onClick={() => setShowDeletePopup(true)}
              />
            )}
            {isShowCreateInvoiceButton && (
              <Button
                label="Create Invoice"
                type="submit"
                disabled={false}
                customWidth="w-36"
                onClick={() => setShowCreateInvoicePopup(true)}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isShowDeletePopup}
        title="Delete BDRs"
        description="Are you sure you want to delete BDRs?"
        onClose={() => {
          setShowDeletePopup(false);
        }}
        onSubmit={() => {
          onDeleteBDRs();
          setShowDeletePopup(false);
        }}
        primaryButtonProps={{
          label: "Delete",
          styleVal: "bg-red-600",
        }}
      />
      <ConfirmationModal
        isOpen={isShowCreateInvoicePopup}
        title="Create Invoice"
        description="Are you sure you want to create invoice?"
        onClose={() => {
          setShowCreateInvoicePopup(false);
        }}
        onSubmit={() => {
          onCreateInvoice();
          setShowCreateInvoicePopup(false);
        }}
        primaryButtonProps={{
          label: "Create",
          styleVal: "bg-[#211551]",
        }}
      />
    </>
  );
};

export default DetailSection;
