import React, { ChangeEvent, DragEvent } from "react";

import AcceptedFiles from "../../data/AcceptedFileUploads.json";
import fileFormats from "../../data/fileFormats.json";
import useStore from "../../store/useStore";
import Button from "../Button";

interface fileUploadProps {
  acceptedFormats?: string[];
  handleFileUpload: (file: File) => void;
}

const FileUpload: React.FC<fileUploadProps> = ({
  acceptedFormats = [],
  handleFileUpload,
}) => {
  const [dragActive, setDragActive] = React.useState(false);
  const enableToast = useStore((state) => state.enableToast);
  const toastMessage = useStore((state) => state.toastMessage);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const uploadFile = (files: FileList): void => {
    if (files.length > 1) {
      enableToast({
        message: `Please upload only one file at a time`,
        pos: toastMessage.pos || "bottom",
        type: "error",
      });

      return;
    }

    const file = files[0];
    const { type } = file;

    if (acceptedFormats.length > 0) {
      const ext = fileFormats.find(({ type: t }) => t === type)?.ext || "";
      const t1 = acceptedFormats.slice(0, -1);
      const t2 = acceptedFormats.slice(-1);

      let text = "";

      if (t1.length > 0) {
        text = [t1.join(", "), "or", t2[0]].join(" ");
      } else {
        text = t2[0];
      }

      if (!acceptedFormats.includes(ext)) {
        enableToast({
          message: `Please upload ${text} files only`,
          pos: toastMessage.pos || "bottom",
          type: "error",
        });

        return;
      }
    } else {
      if (!AcceptedFiles.includes(type)) {
        enableToast({
          message: `Please upload .xls, .xlsx or .xml files only`,
          pos: toastMessage.pos || "bottom",
          type: "error",
        });

        return;
      }
    }

    handleFileUpload(file);
  };

  const handleDrag = (e: DragEvent<HTMLDivElement | HTMLFormElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files) uploadFile(e.dataTransfer.files);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();

    if (e.target.files) uploadFile(e.target.files);
  };

  const onButtonClick = (): void => {
    inputRef.current && inputRef.current.click();
  };

  const handleClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ): void => {
    const element = e.target as HTMLInputElement;

    element.value = "";
  };

  return (
    <>
      <div
        className="h-32 w-96 max-w-full text-center relative mx-auto"
        onDragEnter={(e) => handleDrag(e)}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          className="hidden"
          multiple={false}
          onChange={(e) => handleChange(e)}
          onClick={(e) => handleClick(e)}
        />
        <label
          htmlFor="input-file-upload"
          className={`h-full flex items-center justify-center border-2 rounded border-dashed   ${
            dragActive ? "border-green-600" : "border-black"
          }`}
        >
          <div className="flex flex-col justify-center items-center">
            <p>Drag and drop your file here or</p>
            <Button
              styleVal="cursor-pointer p-1 !text-black bg-transparent hover:underline"
              type="button"
              label="Upload a file"
              disabled={false}
              onClick={onButtonClick}
            />
          </div>
        </label>
        {dragActive && (
          <div
            className="absolute w-full h-full rounded-2xl top-0 left-0 right-0 bottom-0"
            onDragEnter={(e) => handleDrag(e)}
            onDragLeave={(e) => handleDrag(e)}
            onDragOver={(e) => handleDrag(e)}
            onDrop={(e) => handleDrop(e)}
          ></div>
        )}
      </div>
    </>
  );
};

export default FileUpload;
