import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";

import { normalExpenseUpdate } from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import ArrowDown from "../../assets/Icons/ArrowDown";
import { checkIfAdmin } from "../../auth/validateUser";
import SectionConatiner from "../../components/SectionConatiner";
import SpinnerComponent from "../../components/Spinner";
import Table from "../../components/Table";
import ToastComponent from "../../components/Toast";
import useConfig, { Data, Row } from "../../hooks/useConfig";
import useStore from "../../store/useStore";

const columns: GridColDef[] = [
  {
    field: "type",
    headerName: "Expense Type",
    minWidth: 200,
    editable: true,
    flex: 1,
  },
  {
    field: "materialCode",
    headerName: "Material Code",
    minWidth: 150,
    editable: true,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 300,
    editable: true,
    flex: 1,
  },
  {
    field: "lastUpdatedBy",
    headerName: "Last Updated by",
    minWidth: 300,
    editable: false,
    flex: 1,
  },
  {
    field: "lastUpdatedOn",
    headerName: "Last Updated on",
    minWidth: 150,
    editable: false,
    flex: 1,
  },
];

const NormalExpense: React.FC = () => {
  const { data, handleError, handleResponse } = useConfig("normalExpense");
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const mandatoryFields: string[] = ["type", "materialCode"];
  const hideFields: string[] = ["lastUpdatedBy", "lastUpdatedOn"];
  const disableUpdateFields: string[] = ["type"];
  const duplicateCheckFields: string[] = ["type"];
  const isAdmin = checkIfAdmin();

  const dataToRow = (data: Data[]): Row[] => {
    return data.map((e: Data): Row => {
      const lastUpdatedDate = e.lastUpdatedDate as {
        value: string;
      };

      return {
        id: e.id as string,
        type: e.type as string,
        materialCode: e.materialCode as string,
        description: e.description as string,
        lastUpdatedBy: e.lastUpdatedBy as string,
        lastUpdatedOn: lastUpdatedDate.value,
      };
    });
  };

  const getData = (): Promise<void> => {
    setLoader(true);

    const getRequest = InitiateInvoiceApi.importNormalExpenses("GET");

    return ResponseAPI.performRequest(getRequest)
      .then(handleResponse("__get__", dataToRow, ""))
      .catch(handleError);
  };

  const updateData = (
    rowId: string | number | null,
    data: Data,
  ): Promise<void> => {
    setLoader(true);

    const _updateState: normalExpenseUpdate = {
      type: data.type as string,
      materialCode: data.materialCode as string,
      description: data.description as string,
    };

    if (rowId) _updateState.id = rowId.toString();

    const updateRequest = InitiateInvoiceApi.importNormalExpenses(
      "PUT",
      _updateState,
    );

    return ResponseAPI.performRequest(updateRequest)
      .then(
        handleResponse(
          rowId ? "__update__" : "__add__",
          dataToRow,
          `${
            rowId ? "Updated" : "Added"
          } successfully! The changes saved will be taken into account on the next AP/AR run.`,
        ),
      )
      .catch(handleError);
  };

  const deleteData = (id: string | number): Promise<void> => {
    setLoader(true);

    const deleteRequest = InitiateInvoiceApi.importNormalExpenses(
      "DELETE",
      null,
      id ? { expenseId: [Number(id)] } : undefined,
    );

    return ResponseAPI.performRequest(deleteRequest)
      .then(
        handleResponse(
          "__delete__",
          dataToRow,
          "Deleted successfully! The changes saved will be taken into account on the next AP/AR run.",
        ),
      )
      .catch(handleError);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <SectionConatiner
        title={
          <div className="flex gap-1.5 items-center">
            <span>Expense Type Mapping</span>
            <ArrowDown className="-rotate-90" fill="currentColor" />
            <span>Normal Expense</span>
          </div>
        }
        className="w-full mx-auto px-10 relative"
      >
        <Table
          suffix="Normal Expense"
          cols={columns}
          rows={data}
          primaryField="type"
          mandatoryFields={mandatoryFields}
          hideFields={hideFields}
          disableUpdateFields={disableUpdateFields}
          duplicateCheckFields={duplicateCheckFields}
          isShowActionsColumn={isAdmin}
          onUpdate={updateData}
          onDelete={deleteData}
        />
      </SectionConatiner>
    </>
  );
};

export default NormalExpense;
