import React from "react";

import useStore from "../../store/useStore";

import "./spinner.scss";

const SpinnerComponent: React.FC = () => {
  const enableLoader = useStore((state) => state.enableLoader);

  return (
    <>
      {enableLoader && (
        <div className="parent">
          <div className="parent__loader">Loading...</div>
        </div>
      )}
    </>
  );
};

export default SpinnerComponent;
