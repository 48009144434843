import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";

import CustomInput from "./dateCustomComponent";

import "./index.scss";

interface DateProps {
  value: null | Date;
  onChange: (date: Date) => void;
  className?: string;
  placeholderText?: string;
  errorStyle?: string;
  errorText?: string;
  write?: boolean;
  topPlaceholderText?: boolean;
  minDate?: Date;
  maxDate?: Date;
  showClearIcon?: boolean;
  showonlyMondays?: boolean;
  showonlySundays?: boolean;
}

const Date: React.FC<DateProps> = ({
  value,
  onChange,
  className,
  placeholderText,
  minDate,
  maxDate,
  showClearIcon = false,
  showonlyMondays = false,
  showonlySundays = false,
}) => {
  return (
    <div className={`datePicker1 ${className || ""}`} data-testid="date_parent">
      <DatePicker
        dateFormat="MM / dd / YYYY"
        data-testid="datepicker"
        onChange={onChange}
        selected={value}
        placeholderText={placeholderText}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={minDate}
        maxDate={maxDate}
        isClearable={showClearIcon}
        filterDate={
          showonlyMondays
            ? (date) => date.getDay() === 1
            : showonlySundays
              ? (date) => date.getDay() === 0
              : () => true
        }
        customInput={
          <CustomInput
            value={moment(value).format("mm | dd | yyyy")}
            onClick={() => onchange}
            placeholder={placeholderText || ""}
          />
        }
        className={`test_class ${value ? "selected_fill" : ""}`}
      />
    </div>
  );
};

export default Date;
