interface IObjectKeys {
  [key: string]: string;
}

export const s4RunHistoryStatusFilter: IObjectKeys = {
  ALL: "all",
  IN_PROGRESS: "in progress",
  COMPLETED: "completed",
};

// Note: Enable following code to do filtering in UI.
// interface s4RunHistoryStatusFilterGroupingObjectKeys {
//   [key: string]: string[];
// }

// export const s4RunHistoryStatusFilterGrouping: s4RunHistoryStatusFilterGroupingObjectKeys =
//   {
//     IN_PROGRESS: [
//       "FILE_UPLOADED_SUCCESSFULLY",
//       "BDR_CREATE_API_CALLED_SUCCESSFULLY",
//       "BDR_CREATED_SUCCESSFULLY",
//       "BDR_CREATED_WITH_ERRORS",
//       "BDR_DETAILS_READ_SUCCESSFULLY",
//       "ERROR_IN_CALLING_BDR_READ",
//       "INVOICE_CREATE_INITIATED",
//       "INVOICE_CREATE_API_CALLED_SUCCESSFULLY",
//       "EBDR_PROCESS_IN_PROGRESS",
//     ],
//     COMPLETED: [
//       "ERROR_IN_FILE_PROCESSING",
//       "FAILED_TO_CALL_BDR_CREATE_API",
//       "BDR_DELETE_INITIATED",
//       "BDR_DELETE_FAILED",
//       "BDR_DELETED",
//       "INVOICE_CREATED_SUCCESSFULLY",
//       "INVOICE_CREATED_WITH_ERRORS",
//       "FAILED_TO_CALL_INVOICE_CREATE_API",
//       "EBDR_DUPLICATE_",
//       "FAILED_TO_GET_BDR_CREATE_CONFIRMATION",
//     ],
//   };

export const s4RunHistoryShowRedIcon: string[] = [
  "ERROR_IN_FILE_PROCESSING",
  "FAILED_TO_CALL_BDR_CREATE_API",
  "BDR_CREATED_WITH_ERRORS",
  "ERROR_IN_CALLING_BDR_READ",
  "BDR_DELETED_PARTIALLY",
  "BDR_DELETE_FAILED",
  "INVOICE_CREATED_WITH_ERRORS",
  "FAILED_TO_CALL_INVOICE_CREATE_API",
  "FAILED_TO_GET_BDR_CREATE_CONFIRMATION",
];

export const s4RunHistoryShowDetails: string[] = [
  "BDR_CREATED_SUCCESSFULLY",
  "BDR_CREATED_WITH_ERRORS",
  "BDR_DETAILS_READ_SUCCESSFULLY",
  "ERROR_IN_CALLING_BDR_READ",
  "BDR_DELETE_INITIATED",
  "BDR_DELETED_PARTIALLY",
  "BDR_DELETE_FAILED",
  "BDR_DELETED",
  "INVOICE_CREATE_INITIATED",
  "INVOICE_CREATE_API_CALLED_SUCCESSFULLY",
  "INVOICE_CREATED_SUCCESSFULLY",
  "INVOICE_CREATED_WITH_ERRORS",
  "FAILED_TO_CALL_INVOICE_CREATE_API",
];

export const s4DetailsShowErrorTable: string[] = [
  "BDR_CREATED_WITH_ERRORS",
  "BDR_DELETE_INITIATED",
  "BDR_DELETED_PARTIALLY",
  "BDR_DELETE_FAILED",
  "BDR_DELETED",
];

export const s4DetailsShowDeleteBDRButton: string[] = [
  "BDR_CREATED_SUCCESSFULLY",
  "BDR_DETAILS_READ_SUCCESSFULLY",
  "ERROR_IN_CALLING_BDR_READ",
  "BDR_DELETED_PARTIALLY",
  "BDR_DELETE_FAILED",
  "FAILED_TO_CALL_INVOICE_CREATE_API",
];

export const s4DetailsShowCreateInvoiceButton: string[] = [
  "BDR_CREATED_SUCCESSFULLY",
  "BDR_DETAILS_READ_SUCCESSFULLY",
  "ERROR_IN_CALLING_BDR_READ",
  "BDR_DELETE_FAILED",
  "FAILED_TO_CALL_INVOICE_CREATE_API",
];
