import React from "react";

interface detailProps {
  children: React.ReactNode;
  closeModal?: () => void;
}

const ModalWindow: React.FC<detailProps> = ({ children, closeModal }) => {
  const confirmRef = React.useRef<HTMLDivElement | null>(null);

  const checkIfClickedOutside = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (
      confirmRef?.current instanceof Node &&
      !confirmRef?.current?.contains(e.target as Node)
    ) {
      closeModal && closeModal();
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-75 grid place-content-center z-50"
      onClick={(e) => checkIfClickedOutside(e)}
    >
      <div
        className="h-auto text-[#211551] mx-auto w-auto py-5 bg-white rounded overflow-scroll shadow-sm shadow-white"
        ref={confirmRef}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalWindow;
