import Button from "../Button";

interface DuplicateExpenseViewProps {
  title: string;
  description: string;
  yesText?: string;
  noText?: string;
  onClose: () => void;
  onYes?: () => Promise<void>;
  onNo?: () => void;
}

const DuplicateExpenseView: React.FC<DuplicateExpenseViewProps> = ({
  title,
  description,
  yesText,
  noText,
  onClose,
  onYes,
  onNo,
}) => {
  return (
    <>
      <header className="flex justify-between items-center px-5">
        <h1 className="font-bold text-lg mx-auto ">{title}</h1>
        <button
          onClick={onClose}
          className="font-mono font-bold text-xl border-0 text-[#211551] leading-4 "
        >
          x
        </button>
      </header>
      <div className="border border-[#211551] w-full my-3"></div>
      <div className="px-5 w-[400px] pt-4">
        <div className="text-center w-full">{description}</div>
        <footer className="flex w-full justify-center mt-8 space-x-2.5">
          {onNo && (
            <Button
              type="submit"
              label={noText || "No"}
              onClick={onNo}
              disabled={false}
              styleVal="w-24 transition ease-in-out delay-150 hover:scale-110"
            />
          )}
          {onYes && (
            <Button
              type="submit"
              label={yesText || "Yes"}
              onClick={onYes}
              disabled={false}
              styleVal="w-24 transition ease-in-out delay-150 hover:scale-110"
            />
          )}
        </footer>
      </div>
    </>
  );
};

export default DuplicateExpenseView;
