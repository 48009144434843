import React, { useCallback, useEffect, useRef, useState } from "react";

import { dropdownOptionProps } from "../../@types/assetTypes/ap";
import ArrowDown from "../../assets/Icons/ArrowDown";
import SearchClose from "../../assets/Icons/SearchClose";

interface SearchableDropdownDropdownProps {
  options: dropdownOptionProps[];
  onSelect: (selectedOption: dropdownOptionProps) => void;
  alterStyle?: string;
  value: dropdownOptionProps;
  fullWidth?: boolean;
  disabled?: boolean;
  uniqueKey?: string;
  showAllOnExactMatch?: boolean;
  allowEmptyValue?: boolean;
  allowCustomValue?: boolean;
  selectOnly?: boolean;
}

const SearchableDropdown: React.FC<SearchableDropdownDropdownProps> = ({
  options,
  onSelect,
  alterStyle,
  value,
  fullWidth = false,
  disabled = false,
  showAllOnExactMatch = false,
  allowEmptyValue = true,
  allowCustomValue = false,
  selectOnly = false,
}) => {
  const [inputValue, setInputValue] = useState<string>(value?.label || "");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [filteredOptions, setFilteredOptions] = useState<dropdownOptionProps[]>(
    [],
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<dropdownOptionProps[]>(options);
  const inputValueRef = useRef<string>(inputValue);

  const handleInputChange = (value: string): void => {
    const { exactMatchOption, hasExactMatch } = filterOptions(value);

    if (hasExactMatch) {
      setInputValue(exactMatchOption.label);

      onSelect(exactMatchOption);
    } else {
      if (allowCustomValue) {
        setInputValue(value);

        onSelect({ label: value, value });
      } else {
        setInputValue(value);

        if (allowEmptyValue && value === "") {
          onSelect({ label: "", value: "" });
        }
      }
    }

    setIsOpen(true);
  };

  const findExactMatch = useCallback(
    (options: dropdownOptionProps[], value: string): dropdownOptionProps => {
      return options.find((option) =>
        !isNaN(+option.label) && !isNaN(+option.value)
          ? option.label === value
          : option.label.toLowerCase() === value?.toLowerCase(),
      ) as dropdownOptionProps;
    },
    [],
  );

  const filterOptions = (
    value: string,
  ): {
    filteredOptions: dropdownOptionProps[];
    hasExactMatch: boolean;
    exactMatchOption: dropdownOptionProps;
  } => {
    let filteredOptions: dropdownOptionProps[] = [];

    const exactMatchOption = findExactMatch(options, value);
    const hasExactMatch = !!exactMatchOption;

    if (hasExactMatch || value === "") {
      filteredOptions = options;

      if (selectOnly || showAllOnExactMatch) {
        setFilteredOptions(options);
      } else {
        setFilteredOptions(exactMatchOption ? [exactMatchOption] : options);
      }
    } else {
      filteredOptions = options.filter((option) =>
        !isNaN(+option.label) && !isNaN(+option.value)
          ? option.label.includes(value)
          : option.label.toLowerCase().includes(value?.toLowerCase()),
      );

      setFilteredOptions(filteredOptions || []);
    }

    return {
      filteredOptions,
      hasExactMatch,
      exactMatchOption,
    };
  };

  const handleOptionClick = (option: dropdownOptionProps): void => {
    setInputValue(option.label);
    onSelect(option);
    setIsOpen(false);
  };

  const open = (value: string): void => {
    filterOptions(value);
    setIsOpen(true);
  };

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value?.label || "");
  }, [value?.label]);

  useEffect(() => {
    if (isOpen) dropdownRef.current?.querySelector("input")?.focus();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    try {
      const handleClickOutside = (event: MouseEvent): void => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
          const inputValue = inputValueRef.current || "";

          if (!allowCustomValue) {
            const exactMatchOption = findExactMatch(
              optionsRef.current,
              inputValue,
            );

            if (!exactMatchOption) {
              setInputValue(value?.label || "");
            }
          }
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } catch (e) {
      // showBoundary("Something went wrong, please try again later");
    }
  }, [isOpen, value?.label, allowCustomValue]);

  return (
    <div
      className={[
        fullWidth ? "w-full" : "w-96",
        "h-10 flex justify-between gap-3 border items-center shadow-custom shadow-blue-200",
        alterStyle && alterStyle !== "" ? alterStyle : "",
      ].join(" ")}
      ref={dropdownRef}
      data-testid={`form-drp`}
    >
      <div className={`relative w-full ${disabled ? "h-full" : ""}`}>
        <div
          className={`flex w-full h-full ${selectOnly ? "cursor-pointer" : ""}`}
        >
          <input
            type="text"
            data-testid="inputparent"
            className={`pl-5 py-1 w-full outline-none ${
              disabled
                ? "opacity-50 cursor-not-allowed"
                : selectOnly
                  ? "cursor-pointer"
                  : ""
            }`}
            value={inputValue}
            onChange={(e) => handleInputChange(e?.target?.value || "")}
            onFocus={(e) => {
              open(inputValue);
              if (!selectOnly) e?.target?.select();
            }}
            autoComplete="off"
            disabled={disabled}
            readOnly={selectOnly}
          />
          {!selectOnly && !disabled && inputValue && (
            <div
              className={[
                "flex pl-2 py-1 pr-5 items-center",
                disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
              ].join(" ")}
              onClick={disabled ? undefined : () => handleInputChange("")}
            >
              <SearchClose className="w-4 h-4" />
            </div>
          )}
          {(!allowCustomValue || options.length > 0) && (
            <div
              className={`flex pl-2 py-1 pr-5 items-center ${
                disabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => {
                !disabled && open(inputValue);
              }}
            >
              {
                <ArrowDown
                  className={`transition-all duration-500 ${
                    isOpen ? "-rotate-180" : ""
                  }`}
                />
              }
            </div>
          )}
        </div>
        {isOpen && (
          <ul className="shadow-custom shadow-blue-200 rounded-md absolute bg-white w-full z-50 max-h-60 overflow-auto">
            {(!allowCustomValue || options.length > 0) &&
              filteredOptions.length === 0 && (
                <li className="px-5 py-2 text-gray-500">Not Found</li>
              )}
            {filteredOptions.map((option) => (
              <li
                className="cursor-pointer px-5 py-1 hover:bg-[#211551] hover:text-white"
                key={option.value}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchableDropdown;
