import React from "react";
import { BsCalendar4 } from "react-icons/bs";

import "./index.scss";

interface iProps {
  onClick: React.MouseEventHandler;
  value: string;
  placeholder: string;
}

const CustomInput: React.FC<iProps> = ({ onClick, value, placeholder }) => {
  return (
    <div
      className={`datepicker1_custom_input ${
        value && value !== "" ? "datePicker1_selected" : ""
      }`}
      onClick={onClick}
    >
      <label
        className={`datepicker1_custom_input_label placeholder_styling`}
        data-testid="datepicker1_custom_input"
      >
        {value || placeholder}
      </label>
      <BsCalendar4 />
    </div>
  );
};

export default CustomInput;
