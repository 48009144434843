import Box from "@mui/material/Box";
import { GridToolbar, GridToolbarQuickFilter } from "@mui/x-data-grid";
import React from "react";

interface QuickSearchToolbarProps {
  processName: string;
  disableCSV?: boolean;
}

const QuickSearchToolbar: React.FC<QuickSearchToolbarProps> = ({
  processName,
  disableCSV = false,
}) => {
  return (
    <Box className="flex justify-between">
      <GridToolbar
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          disableToolbarButton: disableCSV,
          fileName: `${processName}`,
          utf8WithBom: true,
        }}
      />
      <GridToolbarQuickFilter />
    </Box>
  );
};

export default QuickSearchToolbar;
