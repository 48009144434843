import React, { SyntheticEvent } from "react";

import { inputProps } from "../../@types/assetTypes/ap";
import Input from "../../components/Input";
import Textlabel from "../../components/Textlabel";

export interface InitiateProps {
  isIncludeError: boolean;
  inputVal: inputProps;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isExcludeError: boolean;
  inputDisabled: boolean;
  handleSubmit: (e: SyntheticEvent) => void;
  buttonDisabled: boolean;
}

const Initiatesection: React.FC<InitiateProps> = ({
  isIncludeError,
  inputVal,
  handleChange,
  isExcludeError,
  inputDisabled,
}) => {
  return (
    <div className="py-7 shadow-custom">
      <div className="text-center font-semibold">
        <Textlabel label="AP Invoices to run" />
      </div>
      <form className="mt-10 flex flex-col items-center gap-14 w-full">
        <div className="w-full flex justify-center">
          <Input
            nameVal="includedInvoices"
            placeholder="Invoices range and/or comma separated eg: 100-200,304"
            inputValue={inputVal.includedInvoices || ""}
            changeHandler={handleChange}
            inputTitle="Invoices to Include"
            isInputError={isIncludeError}
          />
        </div>
        <div className="w-full flex justify-center">
          <Input
            isInputError={isExcludeError}
            nameVal="excludedInvoices"
            placeholder="Invoices range and/or comma separated eg: 100-200,304"
            inputValue={inputVal.excludedInvoices || ""}
            changeHandler={handleChange}
            inputTitle="Invoices to Exclude"
            inputDisable={inputDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default Initiatesection;
