import { SyntheticEvent } from "react";

interface ButtonProps {
  label: string;
  onClick?: (e: SyntheticEvent) => void;
  disabled: boolean;
  styleVal?: string;
  type: "button" | "submit";
  customWidth?: string;
  customFontColor?: string;
  Icon?: JSX.Element;
}

const Button: React.FC<ButtonProps> = ({
  type,
  label,
  onClick,
  disabled = false,
  styleVal,
  customWidth,
  customFontColor,
  Icon,
}) => {
  return (
    <button
      type={type}
      className={`px-5 py-2 flex space-x-2.5 items-center justify-center ${
        customWidth || "w-fit"
      } rounded-lg ${styleVal || ""} ${
        disabled ? "bg-[#211551]/20" : "bg-[#211551]"
      } ${customFontColor || "text-white"}`}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon}
      <span>{label}</span>
    </button>
  );
};

export default Button;
