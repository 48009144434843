import { useState } from "react";

import useStore from "../store/useStore";

export interface Data {
  [k: string]: string | object | number | boolean | null;
}

export type Row = Data;

type Status = "__get__" | "__add__" | "__update__" | "__delete__";

interface Success {
  status: Status;
  message: string;
  idField: string;
  data: Data[];
  dataToRow: (data: Data[]) => Row[];
  rowId?: string;
}

interface Response {
  data: {
    errors: Array<{ message: string }>;
    data: {
      expense?: Data[];
      specialExpense?: Data[];
      clients?: Data[];
      time?: Data[];
      configs?: Data[];
      feeSplits?: Data[];
    };
  };
}

interface UseConfigHook {
  data: Row[];
  setData: (data: Row[]) => void;
  handleError: (e: { message: string }) => Promise<void>;
  handleResponse: (
    status: Status,
    dataToRow: (data: Data[]) => Row[],
    successMessage: string,
    other?: Data,
  ) => (response: Response) => Promise<void>;
}

const useConfig = (
  type:
    | "normalExpense"
    | "specialExpense"
    | "companyConfig"
    | "timeMapping"
    | "globalConfig"
    | "feeSplitConfig"
    | "scheduleBatchConfig",
): UseConfigHook => {
  const setLoader = useStore((state) => state.setLoader);
  const enableToast = useStore((state) => state.enableToast);
  const [data, setData] = useState<Row[]>([]);

  const handleSuccess = ({
    status,
    message,
    idField,
    data: d,
    dataToRow,
    rowId,
  }: Success): Promise<void> => {
    let rows: Data[] = [...data];

    if (status === "__add__") {
      const newRow = dataToRow(d)[0];

      if (newRow) rows.unshift(newRow);
    } else if (status === "__update__") {
      const updatedRow = dataToRow(d)[0];

      if (updatedRow) {
        if (rowId) {
          const index = rows.findIndex((r) => r[idField] === rowId);

          rows.splice(index, 1, updatedRow);
        } else {
          const i = rows.findIndex((r) => {
            if (typeof r[idField] === "object") {
              const c = r[idField] as { id: string; value: string };

              return c.id === d[0][idField] || c.value === d[0][idField];
            } else {
              return r[idField] === d[0][idField];
            }
          });

          rows.splice(i, 1, updatedRow);
        }
      }
    } else {
      rows = dataToRow(d);
    }

    setData(rows);
    setLoader(false);

    if (message) {
      enableToast({
        message,
        type: "success",
      });
    }

    return Promise.resolve();
  };

  const handleError = (e: { message: string }): Promise<never> => {
    setLoader(false);
    enableToast({
      message: e?.message || "Something went wrong!",
      type: "error",
    });

    return Promise.reject(e);
  };

  const handleResponse =
    (
      status: Status,
      dataToRow: (data: Data[]) => Row[],
      successMessage: string,
      other?: Data,
    ) =>
    (response: Response): Promise<void> => {
      const hasNoError =
        !response.data?.errors || response.data?.errors?.length === 0;

      let data: Data[] = [];
      let idField: string = "id";

      const rowId: string = (other?.rowId as string) || "";

      if (type === "normalExpense") {
        data = response.data.data.expense || [];
      } else if (type === "specialExpense") {
        data = response.data.data.specialExpense || [];
      } else if (type === "companyConfig") {
        const client =
          response.data.data.clients?.find(
            (c) => c.clientId === other?.clientId,
          ) || {};

        data = (client?.configs as Data[]) || [];
        idField = "key";
      } else if (type === "timeMapping") {
        data = response.data.data.time || [];
      } else if (type === "globalConfig") {
        data = response.data.data.configs || [];
      } else if (type === "feeSplitConfig") {
        if (status === "__get__") {
          data = response.data.data.feeSplits || [];
        } else if (status === "__delete__") {
          data = (response.data.data as Data[]) || [];
        } else {
          data = [response.data.data].filter(Boolean);
        }
      } else if (type === "scheduleBatchConfig") {
        if (status === "__get__") {
          data = (response.data.data as Data[]) || [];
        } else if (status === "__delete__") {
          data = (response.data.data as Data[]) || [];
        } else {
          data = [response.data.data].filter(Boolean);
        }

        idField = "batchId";
      }

      if (hasNoError) {
        return handleSuccess({
          status,
          message: successMessage,
          idField,
          data,
          dataToRow,
          rowId,
        });
      } else {
        const errorMessage = response.data?.errors?.[0]?.message || "";

        return Promise.reject(new Error(errorMessage));
      }
    };

  return { data, setData, handleError, handleResponse };
};

export default useConfig;
