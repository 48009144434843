import axios, { ResponseType } from "axios";
import axiosRetry from "axios-retry";

export interface performRequestProps {
  url: string;
  options?: {
    // headers?: AxiosRequestHeaders
    headers?: {
      "Content-Type"?: string;
      Authorization?: string;
    };
    responseType?: ResponseType;
    method?: string;
    data?: unknown;
  };
}

export const HTTP = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

const axiosInstance = axios.create();

axiosRetry(axiosInstance, {
  retries: 2, // Retry 2 times
  retryDelay: () => 2 * 1000, // Delay 2 seconds between retries
  retryCondition: (error): boolean => {
    return error?.response?.status === 401; // Retry only for 401 errors
  },
});

export default {
  performRequest({ url, options }: performRequestProps) {
    const token =
      JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

    const accessToken: string = token?.accessToken?.accessToken;
    const method = options?.method || HTTP.GET;
    const data = options?.data || {};
    const responseType = options?.responseType;

    const headers = {
      Accept: "application/vnd.finops-exp-api.v1+json",
      "Content-Type": "application/json",
      ...options?.headers,
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const response = axiosInstance(url, {
      method,
      headers,
      data,
      responseType,
    });

    return response;
  },
};
