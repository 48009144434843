import {
  GridFilterModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  inputProps,
  IRunHistoryDetails,
  IUnpaidInvoiceDetails,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import Button from "../../components/Button";
import DuplicateExpenseView from "../../components/DuplicateExpenseView";
import ConfirmModal from "../../components/Modalwindow";
import SpinnerComponent from "../../components/Spinner";
import ToastComponent from "../../components/Toast";
import useStore from "../../store/useStore";
import { countInvoiceIds } from "../../utils";

import Historysection from "./Historysection";
import Initiatesection from "./Initiatesection";
import UnpaidSection from "./UnpaidSection";

const Ap: React.FC = () => {
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);
  const [inputVal, setInputVal] = useState<inputProps>({});
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [inputDisabled, setInputDisabled] = useState<boolean>(true);
  const [isIncludeError, setIsIncludeError] = useState<boolean>(false);
  const [isExcludeError, setIsExcludeError] = useState<boolean>(false);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const [pageSize, setPageSize] = useState<number>(5);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "desc" },
  ]);

  const numbers = /^[0-9,-]+$/;
  const [runHistory, setRunHistory] = useState<IRunHistoryDetails[]>([]);
  const [unPaid, setUnpaid] = useState<IUnpaidInvoiceDetails[]>([]);
  const [noOfInvoices, setNoOfInvoices] = useState<number>(0);
  const arrayConvert = useStore((state) => state.arrayConvert);
  const pageNo = paginationModel.page;
  const pageNoRef = useRef(pageNo);
  const filterModelRef = useRef(filterModel);
  const sortModelRef = useRef(sortModel);

  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  // unpaid table state

  const [unpaidInvoicepaginationModel, setUnpaidInvoicePaginationModel] =
    useState<GridPaginationModel>({
      page: 0,
      pageSize: 10,
    });

  const [unpaidInvoicePageSize, setUnpaidInvoicePageSize] = useState<number>(5);

  const [unpaidInvoiceTotalRowCount, setUnpaidInvoiceTotalRowCount] =
    useState<number>(0);

  const [unpaidInvoiceFilterModel, setUnpaidInvoiceFilterModel] =
    useState<GridFilterModel>({
      items: [],
    });

  const [unpaidInvoiceSortModel, setUnpaidInvoiceSortModel] =
    useState<GridSortModel>([{ field: "invoice_id", sort: "desc" }]);

  const unpaidInvoicePageNo = unpaidInvoicepaginationModel.page;
  const unpaidInvoicePageNoRef = useRef(unpaidInvoicePageNo);
  const unpaidInvoiceFilterModelRef = useRef(unpaidInvoiceFilterModel);
  const unpaidInvoiceSortModelRef = useRef(unpaidInvoiceSortModel);
  const maxNoOfInvoices = 10 * 1000;

  const getRunHistory = useCallback(
    async (
      _pageNo?: number,
      _filterModel?: GridFilterModel,
      _sortModel?: GridSortModel,
    ): Promise<void> => {
      setLoader(true);

      const pNo = _pageNo ?? pageNoRef.current;
      const fModel = _filterModel ?? filterModelRef.current;
      const sModel = _sortModel ?? sortModelRef.current;
      const idFilter = fModel?.items?.find(({ field }) => field === "id");

      const invoicesIncludedFilter = fModel?.items?.find(
        ({ field }) => field === "col1",
      );

      const statusFilter = fModel?.items?.find(
        ({ field }) => field === "status",
      );

      const startedAtFilter = fModel?.items?.find(
        ({ field }) => field === "startedAt",
      );

      const startedByFilter = fModel?.items?.find(
        ({ field }) => field === "startedBy",
      );

      const filterId: string = [undefined, null, ""].includes(idFilter?.value)
        ? ""
        : idFilter?.value;

      const filterInvoicesIncluded: string = [undefined, null, ""].includes(
        invoicesIncludedFilter?.value,
      )
        ? ""
        : invoicesIncludedFilter?.value?.join(",");

      const filterStatus: string = [undefined, null, ""].includes(
        statusFilter?.value,
      )
        ? ""
        : statusFilter?.value;

      const filterStartedAt: string = [undefined, null, ""].includes(
        startedAtFilter?.value,
      )
        ? ""
        : startedAtFilter?.value;

      const filterStartedBy: string = [undefined, null, ""].includes(
        startedByFilter?.value,
      )
        ? ""
        : startedByFilter?.value;

      const sortValue: string = sModel?.[0]?.field ? sModel[0].field : "id";
      const sortOrder: string = sModel?.[0]?.sort ? sModel[0].sort : "desc";

      try {
        const fId = filterId ? `run_id in (${filterId})` : "";

        const fInvoicesIncluded = filterInvoicesIncluded
          ? `invoices_included in (${filterInvoicesIncluded})`
          : "";

        const fStatus = filterStatus ? `status in (${filterStatus})` : "";

        const fStartedAt = filterStartedAt
          ? `STARTED_DATE range (${filterStartedAt},${filterStartedAt})`
          : "";

        const fStartedBy = filterStartedBy
          ? `started_by in (${filterStartedBy})`
          : "";

        const pageInfo = { pageNo: pNo, pageSize };

        const getRequest = InitiateInvoiceApi.getRunHistory(
          "AP",
          sortValue,
          sortOrder,
          {
            fId,
            fInvoicesIncluded,
            fStatus,
            fStartedAt,
            fStartedBy,
          },
          pageInfo,
        );

        const getResponse = await ResponseAPI.performRequest(getRequest);

        const {
          executionDetails = [],
          totalCount = 0,
        }: { executionDetails: IRunHistoryDetails[]; totalCount: number } =
          getResponse?.data?.data?.process || {};

        setRunHistory(executionDetails);
        setPaginationModel({ page: pNo, pageSize });
        setFilterModel(fModel);
        setSortModel(sModel);
        setTotalRowCount(totalCount);

        if (getResponse.data.errors.length > 0) {
          enableToast({
            pos: "top",
            message: getResponse.data.errors[0].message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoader(false);
    },
    [pageSize],
  );

  const getUnpaidData = useCallback(
    async (
      _pageNo?: number,
      _filterModel?: GridFilterModel,
      _sortModel?: GridSortModel,
    ): Promise<void> => {
      setLoader(true);

      const pNo = _pageNo ?? unpaidInvoicePageNoRef.current;
      const fModel = _filterModel ?? unpaidInvoiceFilterModelRef.current;
      const sModel = _sortModel ?? unpaidInvoiceSortModelRef.current;

      const getValue = (colId: string): string => {
        const fltr = fModel?.items?.find(({ field }) => field === colId);

        return [undefined, null, ""].includes(fltr?.value) ? "" : fltr?.value;
      };

      const filterId = getValue("invoice_id");
      const filterEmpName: string = getValue("col1");
      const filterEmpCompany: string = getValue("empCompany");
      const filterEmpCategory: string = getValue("col2");
      const filterInvoiceType: string = getValue("col3");
      const filterSowId: string = getValue("col4");
      const filterVendorId: string = getValue("col6");
      const filterEmailId: string = getValue("col7");
      const filterStatus: string = getValue("col8");

      const sortValue: string = sModel?.[0]?.field
        ? sModel[0].field
        : "invoice_id";

      const sortOrder: string = sModel?.[0]?.sort ? sModel[0].sort : "desc";

      try {
        const fId = filterId ? `INVOICE_ID in (${filterId})` : "";

        const fEmpName = filterEmpName
          ? `EMPLOYEE_NAME in (${filterEmpName})`
          : "";

        const fEmpCompany = filterEmpCompany
          ? `EMPLOYEE_COMPANY in (${filterEmpCompany})`
          : "";

        const fEmpCategory = filterEmpCategory
          ? `EMPLOYEE_CATEGORY in (${filterEmpCategory})`
          : "";

        const fInvoiceType = filterInvoiceType
          ? `INVOICE_TYPE in (${filterInvoiceType})`
          : "";

        const fSowId = filterSowId ? `SOW_ID in (${filterSowId})` : "";

        const fVendorId = filterVendorId
          ? `VENDOR_ID in (${filterVendorId})`
          : "";

        const fEmailId = filterEmailId ? `EMAIL_ID in (${filterEmailId})` : "";

        const fStatus = filterStatus
          ? `STATUS in (${filterStatus})`
          : "STATUS in (Pending)";

        const pageInfo = { pageNo: pNo, pageSize: unpaidInvoicePageSize };

        const getRequest = InitiateInvoiceApi.getUnpaidTalents(
          sortValue,
          sortOrder,
          {
            fId,
            fEmpName,
            fEmpCompany,
            fEmpCategory,
            fInvoiceType,
            fSowId,
            fVendorId,
            fEmailId,
            fStatus,
          },
          pageInfo,
        );

        const getResponse = await ResponseAPI.performRequest(getRequest);

        const {
          invoicesSummary = [],
          totalCount = 0,
        }: { invoicesSummary: IUnpaidInvoiceDetails[]; totalCount: number } =
          getResponse?.data?.data || {};

        setUnpaid(invoicesSummary);
        setUnpaidInvoicePaginationModel({
          page: pNo,
          pageSize: unpaidInvoicePageSize,
        });
        setUnpaidInvoiceFilterModel(fModel);
        setUnpaidInvoiceSortModel(sModel);
        setUnpaidInvoiceTotalRowCount(totalCount);

        if (getResponse.data.errors.length > 0) {
          enableToast({
            pos: "top",
            message: getResponse.data.errors[0].message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoader(false);
    },
    [unpaidInvoicePageSize],
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    const updated = { ...inputVal, [name]: value };

    setInputVal(updated);

    setIsIncludeError(
      updated.includedInvoices
        ? !updated.includedInvoices.match(numbers)
        : false,
    );

    setInputDisabled(!updated.includedInvoices?.includes("-"));

    setIsExcludeError(
      updated.excludedInvoices
        ? !updated.excludedInvoices.match(numbers)
        : false,
    );
  };

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    const data = {
      includedInvoices: arrayConvert(inputVal.includedInvoices),
      excludedInvoices: arrayConvert(inputVal.excludedInvoices),
    };

    if (
      inputVal.includedInvoices?.split(",").filter((item) => item.includes("-"))
        .length !== data.includedInvoices.ranges?.length
    ) {
      setIsIncludeError(true);
      setLoader(false);

      return;
    }

    if (
      (inputVal.includedInvoices &&
        inputVal.includedInvoices?.length >= 5000) ||
      (inputVal.excludedInvoices && inputVal.excludedInvoices.length >= 5000)
    ) {
      enableToast({
        message: "Input values should not exceed 5000 characters",
        type: "error",
      });

      setLoader(false);

      return;
    }

    if (
      inputVal.excludedInvoices
        ?.split(",")
        .filter((item) => item.includes("-")) &&
      inputVal.excludedInvoices?.split(",").filter((item) => item.includes("-"))
        .length !== data.excludedInvoices.ranges?.length
    ) {
      setIsExcludeError(true);
      setLoader(false);

      return;
    }

    const noOfInvoices = countInvoiceIds(
      data.includedInvoices.invoiceNumbers || [],
      data.includedInvoices.ranges || [],
      data.excludedInvoices.invoiceNumbers || [],
      data.excludedInvoices.ranges || [],
    );

    if (noOfInvoices > maxNoOfInvoices) {
      setLoader(false);
      setNoOfInvoices(noOfInvoices);
    } else {
      initiateRun();
    }
  };

  const initiateRun = async (): Promise<void> => {
    const data = {
      includedInvoices: arrayConvert(inputVal.includedInvoices),
      excludedInvoices: arrayConvert(inputVal.excludedInvoices),
    };

    setLoader(true);

    try {
      const request = InitiateInvoiceApi.InitiateApInvoices(data);
      const response = await ResponseAPI.performRequest(request);

      setLoader(false);

      if (response.data.errors.length !== 0) {
        enableToast({
          message: response.data.errors[0].message,
          type: "error",
        });

        return;
      }

      const runID: number = response.data.data.process.executionDetail.id;

      enableToast({
        message: `AP Invoices Initiated, Run ID: ${runID}`,
        type: "success",
      });

      getRunHistory();

      setNoOfInvoices(0);
      setSelectionModel([]);
      setInputVal({});
      setInputDisabled(true);
    } catch (error) {
      console.log(error);
      enableToast({
        message: "Network error",
        type: "error",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  useEffect(() => {
    filterModelRef.current = filterModel;
  }, [filterModel]);

  useEffect(() => {
    sortModelRef.current = sortModel;
  }, [sortModel]);

  useEffect(() => {
    unpaidInvoicePageNoRef.current = unpaidInvoicePageNo;
  }, [unpaidInvoicePageNo]);

  useEffect(() => {
    unpaidInvoiceFilterModelRef.current = unpaidInvoiceFilterModel;
  }, [unpaidInvoiceFilterModel]);

  useEffect(() => {
    unpaidInvoiceSortModelRef.current = unpaidInvoiceSortModel;
  }, [unpaidInvoiceSortModel]);

  useEffect(() => {
    if (toastMessage.message === "") {
      enableToast({ message: "", type: "" });
    }
  }, [toastMessage.message]);

  useEffect(() => {
    getUnpaidData();
  }, [getUnpaidData]);

  useEffect(() => {
    getRunHistory();
  }, [getRunHistory]);

  useEffect(() => {
    const data2 = selectionModel.toString();

    setInputVal({
      includedInvoices: data2,
    });
  }, [selectionModel]);

  useEffect(() => {
    setButtonDisabled(
      !(inputVal.includedInvoices && !isIncludeError && !isExcludeError),
    );
  }, [inputVal]);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <Initiatesection
        isIncludeError={isIncludeError}
        inputVal={inputVal}
        handleChange={handleChange}
        isExcludeError={isExcludeError}
        inputDisabled={inputDisabled}
        handleSubmit={handleSubmit}
        buttonDisabled={buttonDisabled}
      />
      <UnpaidSection
        unpaidData={unPaid}
        totalRowCount={unpaidInvoiceTotalRowCount}
        pageSize={unpaidInvoicePageSize}
        filterModel={unpaidInvoiceFilterModel}
        sortModel={unpaidInvoiceSortModel}
        paginationModel={unpaidInvoicepaginationModel}
        onPaginationModelChange={({ page, pageSize }) => {
          getUnpaidData(page);
          setUnpaidInvoicePageSize(pageSize);
        }}
        onFilterChange={(filterModel) => {
          getUnpaidData(0, filterModel);
        }}
        onSortModelChange={(sortModel) => {
          getUnpaidData(undefined, undefined, sortModel);
        }}
      />
      <div className="flex my-10 text-center justify-center">
        <Button
          label="Initiate AP Run"
          type="submit"
          onClick={handleSubmit}
          disabled={buttonDisabled}
        />
      </div>
      <Historysection
        runHistory={runHistory}
        totalRowCount={totalRowCount}
        pageNo={pageNo}
        pageSize={pageSize}
        filterModel={filterModel}
        sortModel={sortModel}
        getRunHistoryFunc={getRunHistory}
        paginationModel={paginationModel}
        onPaginationModelChange={({ page, pageSize }) => {
          getRunHistory(page);
          setPageSize(pageSize);
        }}
        onFilterChange={(filterModel) => {
          getRunHistory(0, filterModel);
        }}
        onSortModelChange={(sortModel) => {
          getRunHistory(undefined, undefined, sortModel);
        }}
      />
      {noOfInvoices > maxNoOfInvoices && (
        <ConfirmModal closeModal={() => setNoOfInvoices(0)}>
          <DuplicateExpenseView
            title="Confirm Run"
            description={`You are about to run AP on (${new Intl.NumberFormat(
              "en-US",
            ).format(
              noOfInvoices,
            )} invoice IDs) which may impact the performance of the run. Please confirm if this is correct`}
            yesText="Yes, this is correct"
            noText="Back"
            onYes={initiateRun}
            onNo={() => setNoOfInvoices(0)}
            onClose={() => setNoOfInvoices(0)}
          />
        </ConfirmModal>
      )}
    </>
  );
};

export default Ap;
