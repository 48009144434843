import moment from "moment";
import React, { SyntheticEvent, useState } from "react";

import { dropListProps } from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import Textlabel from "../../components/Textlabel";
import useStore from "../../store/useStore";

import ActionComfirmationPopup from "./ActionComfirmationPopup";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";

const dropDownData = [
  { id: "1", name: "All" },
  { id: "2", name: "Time" },
  { id: "3", name: "Expense" },
  { id: "4", name: "Milestone" },
];

export interface inputProps {
  fromDateValidate?: Date | null;
  toDateValidate: Date;
  invoiceFileDate: Date;
  mockValidate?: boolean;
  dropDownValidate?: dropListProps;
  dropDownGenerate?: dropListProps;
  batchId?: Date | null;
}

interface InitiateSectionProps {
  getStatusUpdate: () => Promise<void>;
}

const InitiateSection: React.FC<InitiateSectionProps> = ({
  getStatusUpdate,
}) => {
  const setLoader = useStore((state) => state.setLoader);
  const enableToast = useStore((state) => state.enableToast);

  const [modal, setShowModal] = useState<{
    show: boolean;
    initiateAction: ((e: SyntheticEvent) => Promise<void>) | null;
    title: React.ReactNode | null;
  }>({
    show: false,
    initiateAction: null,
    title: null,
  });

  const [inputVal, setInputVal] = useState<inputProps>({
    invoiceFileDate: moment().startOf("week").toDate(),
    fromDateValidate: null,
    toDateValidate: new Date(),
    dropDownValidate: dropDownData[0],
    dropDownGenerate: dropDownData[0],
    batchId: null,
  });

  const handleDateChange = (date: Date, name: string): void => {
    if (name === "toDateValidate") {
      setInputVal({ ...inputVal, [name]: date, fromDateValidate: null });

      return;
    }

    setInputVal({ ...inputVal, [name]: date });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    setInputVal({ ...inputVal, [name]: value });
  };

  const handleValidateInvoice = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    const toDateVal = moment(inputVal.toDateValidate).format("MM-DD-YYYY");

    const invoiceTypeVal =
      inputVal.dropDownValidate?.name !== "All"
        ? inputVal.dropDownValidate?.name
        : "";

    const mockValidationVal = inputVal.mockValidate && inputVal.mockValidate;

    const fromDateVal =
      inputVal.fromDateValidate &&
      moment(inputVal.fromDateValidate).format("MM-DD-YYYY");

    const batchId =
      inputVal.batchId && moment(inputVal.batchId).format("MM-DD-YYYY");

    try {
      const getRequest = InitiateInvoiceApi.validatePwCInvoices(
        toDateVal,
        invoiceTypeVal,
        mockValidationVal,
        fromDateVal,
        batchId,
      );

      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length !== 0) {
        setLoader(false);
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });

        return;
      }

      setLoader(false);
      const runID: number = getResponse.data.data.taskId;

      enableToast({
        message: `Validate Invoice successfully triggered, TaskID: ${runID}`,
        type: "success",
      });
      getStatusUpdate();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleGenerateInvoice = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    const invoiceFileDate = moment(inputVal.invoiceFileDate).format(
      "MM-DD-YYYY",
    );

    const invoiceTypeVal =
      inputVal.dropDownGenerate?.name !== "All"
        ? inputVal.dropDownGenerate?.name
        : "";

    try {
      const getRequest = InitiateInvoiceApi.genaratePwCInvoices(
        invoiceFileDate,
        invoiceTypeVal,
      );

      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length !== 0) {
        setLoader(false);
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });

        return;
      }

      setLoader(false);
      const runID: number = getResponse.data.data.taskId;

      enableToast({
        message: `Generate Invoice successfully Triggered, TaskID: ${runID}`,
        type: "success",
      });
      getStatusUpdate();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleImportFiles = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.importJDFiles();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length !== 0) {
        setLoader(false);
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });

        return;
      }

      setLoader(false);

      const runID: number = getResponse.data.data.taskId;

      enableToast({
        message: `Import Files for Job Diva Successful, TaskID: ${runID}`,
        type: "success",
      });
      getStatusUpdate();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  return (
    <>
      {modal.show && (
        <ActionComfirmationPopup setShowModal={setShowModal} modal={modal} />
      )}
      <div className="pb-14 pt-7 shadow-custom flex flex-col items-center justify-center gap-14 w-full">
        <div className="text-center font-bold">
          <Textlabel label="PwC Talent Exchange" />
        </div>
        <Section1
          handleImportFiles={(e) => {
            e.preventDefault();

            setShowModal({
              show: true,
              initiateAction: handleImportFiles,
              title: (
                <p>Would you like to proceed with creating import file?</p>
              ),
            });
          }}
        />
        <Section2
          dropDownData={dropDownData}
          handleDateChange={handleDateChange}
          handleInputChange={handleInputChange}
          handleValidateInvoice={(e) => {
            e.preventDefault();

            setShowModal({
              show: true,
              initiateAction: handleValidateInvoice,
              title: (
                <>
                  <p>
                    Would you like to proceed validating invoices with below
                    entries?
                  </p>
                  <ul className="list-disc px-3">
                    <li>
                      Invoice Type : {inputVal?.dropDownValidate?.name || "NA"}
                    </li>
                    <li>
                      From Date :{" "}
                      {inputVal.fromDateValidate
                        ? moment(inputVal.fromDateValidate)?.format(
                            "MM-DD-YYYY",
                          )
                        : "NA"}
                    </li>
                    <li>
                      To Date :{" "}
                      {inputVal.toDateValidate
                        ? moment(inputVal.toDateValidate)?.format("MM-DD-YYYY")
                        : "NA"}
                    </li>
                    <li>
                      Mock Validation : {inputVal?.mockValidate ? "ON" : "OFF"}{" "}
                    </li>

                    <li>
                      InvoiceFile WeekStart date :{" "}
                      {inputVal.batchId
                        ? moment(inputVal.batchId)?.format("MM-DD-YYYY")
                        : "NA"}
                    </li>
                  </ul>
                </>
              ),
            });
          }}
          inputVal={inputVal}
          setInputVal={setInputVal}
        />
        <Section3
          dropDownData={dropDownData}
          inputVal={inputVal}
          setInputVal={setInputVal}
          handleGenerateInvoice={(e) => {
            e.preventDefault();

            setShowModal({
              show: true,
              initiateAction: handleGenerateInvoice,
              title: (
                <>
                  <p>
                    Would you like to proceed generating invoice with below
                    entries?
                  </p>
                  <ul className="list-disc px-3">
                    <li>
                      Invoice File date :{" "}
                      {inputVal.invoiceFileDate
                        ? moment(inputVal.invoiceFileDate)?.format("MM-DD-YYYY")
                        : "NA"}
                    </li>
                    <li>
                      Invoice Type : {inputVal?.dropDownGenerate?.name || "NA"}
                    </li>
                  </ul>
                </>
              ),
            });
          }}
          handleDateChange={handleDateChange}
        />
      </div>
    </>
  );
};

export default InitiateSection;
