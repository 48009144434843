import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";

import {
  dropdownFieldsDataProps,
  specialExpenseUpdate,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import ArrowDown from "../../assets/Icons/ArrowDown";
import { checkIfAdmin } from "../../auth/validateUser";
import SectionConatiner from "../../components/SectionConatiner";
import SpinnerComponent from "../../components/Spinner";
import Table from "../../components/Table";
import ToastComponent from "../../components/Toast";
import useConfig, { Data, Row } from "../../hooks/useConfig";
import useStore from "../../store/useStore";

const columns: GridColDef[] = [
  {
    field: "type",
    headerName: "Expense Type",
    minWidth: 200,
    editable: true,
    flex: 1,
  },
  {
    field: "materialCode",
    headerName: "Material Code",
    minWidth: 150,
    editable: true,
    flex: 1,
  },
  {
    field: "payableOrBillable",
    headerName: "Payable or Billable",
    type: "dropdown",
    minWidth: 150,
    editable: true,
    flex: 1,
    valueGetter: (params) => params?.value?.name || "",
  },
  {
    field: "pairedSpecialExpense",
    headerName: "Paired Expense Type",
    type: "dropdown",
    minWidth: 175,
    editable: true,
    flex: 1,
    valueGetter: (params) => params?.value?.name || "",
  },
  {
    field: "treatAsRevenue",
    headerName: "Treat as Revenue",
    type: "dropdown",
    minWidth: 175,
    editable: true,
    flex: 1,
    valueGetter: (params) => params?.value?.name || "",
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 150,
    editable: true,
    flex: 1,
  },
  {
    field: "lastUpdatedBy",
    headerName: "Last Updated by",
    minWidth: 150,
    editable: false,
    flex: 1,
  },
  {
    field: "lastUpdatedOn",
    headerName: "Last Updated on",
    minWidth: 150,
    editable: false,
    flex: 1,
  },
];

const SpecialExpense: React.FC = () => {
  const { data, handleError, handleResponse } = useConfig("specialExpense");
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const disableUpdateFields: string[] = ["type", "payableOrBillable"];
  const duplicateCheckFields: string[] = ["type", "payableOrBillable"];
  const isAdmin = checkIfAdmin();

  const mandatoryFields: string[] = [
    "type",
    "materialCode",
    "payableOrBillable",
  ];

  const hideFields: string[] = ["lastUpdatedBy", "lastUpdatedOn"];

  const payableOrBillableDropdown: Array<{ id: string; name: string }> = [
    {
      id: "true",
      name: "Payable",
    },
    {
      id: "false",
      name: "Billable",
    },
  ];

  const pairedSpecialExpenseDropdown: Array<{
    id: string;
    name: string;
  }> = data.map(({ id, type }) => ({
    id: id as string,
    name: type as string,
  }));

  const treatAsRevenueDropdown: Array<{
    id: string;
    name: string;
  }> = [
    {
      id: "true",
      name: "True",
    },
    {
      id: "false",
      name: "False",
    },
  ];

  const dropdownFieldsData: dropdownFieldsDataProps[] = [
    {
      field: "payableOrBillable",
      data: payableOrBillableDropdown,
    },
    {
      field: "pairedSpecialExpense",
      data: pairedSpecialExpenseDropdown,
    },
    {
      field: "treatAsRevenue",
      data: treatAsRevenueDropdown,
    },
  ];

  const dataToRow = (data: Data[]): Row[] => {
    return data.map((e: Data): Row => {
      const pairedSpecialExpense = e.pairedSpecialExpense as {
        id: string;
        type: string;
      };

      const lastUpdatedDate = e.lastUpdatedDate as {
        value: string;
      };

      return {
        id: e.id as string,
        type: e.type as string,
        materialCode: e.materialCode as string,
        payableOrBillable: e.isPayable
          ? payableOrBillableDropdown[0]
          : payableOrBillableDropdown[1],
        pairedSpecialExpense: {
          id: pairedSpecialExpense.id,
          name: pairedSpecialExpense.type,
        },
        treatAsRevenue:
          treatAsRevenueDropdown.find(
            (t) => t.id === e.treatAsRevenue?.toString(),
          ) || null,
        description: e.description as string,
        lastUpdatedBy: e.lastUpdatedBy as string,
        lastUpdatedOn: lastUpdatedDate.value,
      };
    });
  };

  const getData = (): Promise<void> => {
    setLoader(true);

    const getRequest = InitiateInvoiceApi.importSpecialExpenses("GET");

    return ResponseAPI.performRequest(getRequest)
      .then(handleResponse("__get__", dataToRow, ""))
      .catch(handleError);
  };

  const updateData = (
    rowId: string | number | null,
    data: Data,
  ): Promise<void> => {
    setLoader(true);

    const pairedSpecialExpense = data.pairedSpecialExpense as { id: string };
    const payableOrBillable = data.payableOrBillable as { id: string };
    const treatAsRevenue = data.treatAsRevenue as { id: string };

    const _updateState: specialExpenseUpdate = {
      type: data.type as string,
      materialCode: data.materialCode as string,
      isPayable: payableOrBillable.id === "true",
      pairedSpecialExpenseId: pairedSpecialExpense.id,
      treatAsRevenue: treatAsRevenue.id === "true",
      description: data.description as string,
    };

    if (rowId) _updateState.id = rowId.toString();

    const updateRequest = InitiateInvoiceApi.importSpecialExpenses(
      "PUT",
      _updateState,
    );

    return ResponseAPI.performRequest(updateRequest)
      .then(
        handleResponse(
          rowId ? "__update__" : "__add__",
          dataToRow,
          `${
            rowId ? "Updated" : "Added"
          } successfully! The changes saved will be taken into account on the next AP/AR run.`,
        ),
      )
      .catch(handleError);
  };

  const deleteData = (id: string | number): Promise<void> => {
    setLoader(true);

    const deleteRequest = InitiateInvoiceApi.importSpecialExpenses(
      "DELETE",
      null,
      id ? { specialExpenseId: [Number(id)] } : undefined,
    );

    return ResponseAPI.performRequest(deleteRequest)
      .then(
        handleResponse(
          "__delete__",
          dataToRow,
          "Deleted successfully! The changes saved will be taken into account on the next AP/AR run.",
        ),
      )
      .catch(handleError);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <SectionConatiner
        title={
          <div className="flex gap-1.5 items-center">
            <span>Expense Type Mapping</span>
            <ArrowDown className="-rotate-90" fill="currentColor" />
            <span>Special Expense</span>
          </div>
        }
        className="w-full mx-auto px-10 relative"
      >
        <Table
          suffix="Special Expense"
          cols={columns}
          rows={data}
          primaryField="type"
          mandatoryFields={mandatoryFields}
          hideFields={hideFields}
          disableUpdateFields={disableUpdateFields}
          duplicateCheckFields={duplicateCheckFields}
          dropdownFieldsData={dropdownFieldsData}
          isShowActionsColumn={isAdmin}
          onUpdate={updateData}
          onDelete={deleteData}
        />
      </SectionConatiner>
    </>
  );
};

export default SpecialExpense;
