import Button from "../../../components/Button";
import ConfirmModal from "../../../components/Modalwindow";

interface IProps {
  isOpen: boolean;
  title: string;
  description: string;
  primaryButtonProps?: {
    label?: string;
    styleVal?: string;
  };
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmationModal: React.FC<IProps> = ({
  isOpen,
  title,
  description,
  primaryButtonProps,
  onClose,
  onSubmit,
}) => {
  if (!isOpen) return null;

  return (
    <ConfirmModal closeModal={onClose}>
      <header className="flex justify-between items-center px-5 text-[#211551]">
        <h1 className="font-bold text-lg mx-auto ">{title}</h1>
        <button
          onClick={onClose}
          className="font-mono font-bold text-xl border-0 leading-4 "
        >
          x
        </button>
      </header>
      <div className="border border-[#211551] w-full my-3"></div>
      <div className="px-5 w-[400px] pt-4">
        <div className="text-center w-full text-[#211551]">{description}</div>
        <footer className="flex justify-center mt-8 space-x-5">
          <Button
            type="submit"
            label="Cancel"
            onClick={onClose}
            disabled={false}
            styleVal="border border-neutral-300 bg-white"
            customWidth="w-24"
            customFontColor="text-[#211551]"
          />
          <Button
            type="submit"
            label="Ok"
            onClick={onSubmit}
            disabled={false}
            customWidth="w-24"
            {...primaryButtonProps}
          />
        </footer>
      </div>
    </ConfirmModal>
  );
};

export default ConfirmationModal;
