import {
  DataGrid,
  GridRowsProp,
  MuiEvent,
  GridColDef,
  GridCellParams,
  GridRowId,
  GridPaginationModel,
  GridRenderCellParams,
  getGridNumericOperators,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridFilterInputMultipleValue,
  GridFilterInputSingleSelect,
  getGridDateOperators,
  GridFilterInputDate,
  GridFilterInputSingleSelectProps,
  GridFilterInputDateProps,
  GridFilterInputMultipleValueProps,
  GridFilterModel,
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { BiRefresh } from "react-icons/bi";

import { detailRecordProps, historyProps } from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
// import StatusTitle from "../../components/ColumnHeaders/StatusTitle";
import TimeStampTitle from "../../components/ColumnHeaders/TimeStampTitle";
import TooltipContent from "../../components/ColumnHeaders/TooltipContent";
import DetailWindow from "../../components/DetailpageTable";
import ExpandableCell from "../../components/ExpandableCell";
import ConfirmModal from "../../components/Modalwindow";
import Refresh from "../../components/RefreshButton";
import SectionContainer from "../../components/SectionConatiner";
import APAndARStatuses from "../../data/APAndARStatuses.json";
// import statusDetail from "../../data/APStatusDetail.json";
import useStore from "../../store/useStore";
import { downloadFile } from "../AP/Ap.logichandler";
import Empty from "../S4Upload/components/Empty";
import Toolbar from "../S4Upload/components/Toolbar";

export const columns: GridColDef[] = [
  {
    field: "id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 150,
    headerName: "Run Number",
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value === "=",
    ),
    valueFormatter: (params) => params.value,
  },
  {
    field: "col1",
    headerName: "Invoices Included",
    width: 200,
    type: "string",
    align: "left",
    renderCell: (params: GridRenderCellParams) => (
      <ExpandableCell
        {...params}
        maxWord={3}
        expandedMaxChar={300}
        expandedMaxWord={25}
        wordsDelimitter=","
      />
    ),
    filterable: false,
    filterOperators: getGridStringOperators()
      .filter((operator) => operator.value === "isAnyOf")
      .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent
          ? (props: GridFilterInputMultipleValueProps) => (
              <GridFilterInputMultipleValue {...props} variant="standard" />
            )
          : undefined,
      })),
    valueFormatter: (params) => {
      if (typeof params.value === "string" && !params.value.includes(",")) {
        return params.value.concat("\r");
      }

      return params.value;
    },
    sortable: false,
  },
  {
    field: "col2",
    headerName: "Invoices Excluded",
    width: 200,
    type: "string",
    align: "left",
    renderCell: (params: GridRenderCellParams) => (
      <ExpandableCell
        {...params}
        maxWord={3}
        expandedMaxChar={300}
        expandedMaxWord={25}
        wordsDelimitter=","
      />
    ),
    valueFormatter: (params) => {
      if (
        typeof params.value === "string" &&
        params.value.length > 0 &&
        !params.value.includes(",")
      ) {
        return params.value.concat("\r");
      }

      return params.value;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    type: "singleSelect",
    // renderHeader: (params) => {
    //   return (
    //     <StatusTitle title={params.colDef.headerName} detail={statusDetail} />
    //   );
    // },
    sortable: false,
    filterOperators: getGridSingleSelectOperators()
      .filter((operator) => operator.value === "is")
      .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent
          ? (props: GridFilterInputSingleSelectProps) => {
              return (
                <GridFilterInputSingleSelect
                  sx={{
                    width: 190,
                  }}
                  {...props}
                />
              );
            }
          : undefined,
      })),
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipContent title={params.value} />;
    },
    valueOptions: APAndARStatuses,
  },
  // {
  //   field: "outputFileLocation",
  //   headerName: "Output File",
  //   width: 150,
  //   sortable: false,
  //   filterable: false,
  //   renderCell: (params) => {
  //     if (!params.value) return <></>;
  //     else {
  //       return (
  //         <span className="text-[#1976d2] overflow-hidden text-ellipsis">
  //           Download File
  //         </span>
  //       );
  //     }
  //   },
  // },
  // {
  //   field: "reInitiate",
  //   headerName: "Re-Run",
  //   width: 80,
  //   sortable: false,
  //   filterable: false,
  //   align: "center",
  //   renderCell: (params) => {
  //     if (params.value !== "NA") {
  //       return <BiRefresh className="h-7 w-7 rotate-90 text-[#211551]" />;
  //     }
  //   },
  //   disableExport: true,
  // },
  {
    field: "startedAt",
    headerName: "Run Time (UTC)",
    width: 200,
    sortable: false,
    filterOperators: getGridDateOperators()
      .filter((operator) => operator.value === "is")
      .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent
          ? (props: GridFilterInputDateProps) => (
              <GridFilterInputDate {...props} variant="standard" />
            )
          : undefined,
      })),
    renderHeader: (params) => {
      return <TimeStampTitle title={params.colDef.headerName} />;
    },
  },
  {
    field: "startedBy",
    headerName: "Initiated By",
    minWidth: 300,
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "equals",
    ),
  },
];

const Historysection: React.FC<historyProps> = ({
  runHistory,
  totalRowCount,
  paginationModel,
  pageNo,
  pageSize,
  filterModel,
  sortModel,
  getRunHistoryFunc,
  onPaginationModelChange,
  onFilterChange,
  onSortModelChange,
}) => {
  const [totalRowCountDetails, setTotalRowCountDetails] = useState<number>(0);

  const [paginationModelDetails, setPaginationModelDetails] =
    useState<GridPaginationModel>({
      page: 0,
      pageSize: 20,
    });

  const [issuesFilter, setIssuesFilter] = useState<string>("all");
  const enableLoader = useStore((state) => state.enableLoader);
  const setPopup = useStore((state) => state.setPopup);
  const setLoader = useStore((state) => state.setLoader);
  const [processId, setProcessId] = useState<GridRowId>("");
  const [rerunProcessId, setRerunProcessId] = useState<GridRowId>("");
  const [detailData, setDetailData] = useState<detailRecordProps[]>([]);
  const convertData = useStore((state) => state.convertData);
  const enableToast = useStore((state) => state.enableToast);
  const enablePopup = useStore((state) => state.enablePopup);
  const [showModal, setShowModal] = useState<Boolean>(false);
  const [rowCountState, setRowCountState] = React.useState(totalRowCount || 0);

  const [detailsFilterModel, setDetailsFilterModel] = useState<GridFilterModel>(
    {
      items: [],
    },
  );

  const issuesFilterRef = useRef(issuesFilter);
  const detailsFilterModelRef = useRef(detailsFilterModel);

  const getDetailData = useCallback(
    (
      runID: GridRowId,
      _issuesFilter?: string,
      _filterModel?: GridFilterModel,
      isOnlyFetchAllRecords?: boolean,
    ): Promise<detailRecordProps[]> => {
      const filterIssues = _issuesFilter ?? issuesFilterRef.current;
      const fModel = _filterModel ?? detailsFilterModelRef.current;

      const remarksFilter = fModel?.items?.find(
        ({ field }) => field === "remarks",
      );

      const filterRemarks: string = [undefined, null, ""].includes(
        remarksFilter?.value,
      )
        ? ""
        : remarksFilter?.value;

      const fIssues =
        filterIssues && filterIssues !== "all"
          ? `errorRecords in (${filterIssues === "issues"})`
          : "";

      const fRemarks = filterRemarks ? `remarks in (${filterRemarks})` : "";

      const pageInfo = {
        pageNo: paginationModelDetails.page,
        pageSize: paginationModelDetails.pageSize,
      };

      const getRequest = InitiateInvoiceApi.getDetailRecord(
        runID,
        { fIssues, fRemarks },
        isOnlyFetchAllRecords ? undefined : pageInfo,
      );

      return ResponseAPI.performRequest(getRequest).then((getResponse) => {
        const { records = [], totalCount = 0 } =
          getResponse?.data?.data?.process?.executionDetail || {};

        if (!isOnlyFetchAllRecords) {
          setDetailData(records);
          setIssuesFilter(filterIssues);
          setDetailsFilterModel(fModel);
          setTotalRowCountDetails(totalCount);
          setPopup(true);
        }

        return records;
      });
    },
    [paginationModelDetails.page, paginationModelDetails.pageSize],
  );

  const reInititateRunHistory = async (runID: GridRowId): Promise<void> => {
    setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.reInitiateApInvoices(runID);
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length !== 0) {
        setLoader(false);
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });

        return;
      }

      setLoader(false);
      const newRunID: number = getResponse.data.data.process.executionDetail.id;

      enableToast({
        message: `AP Invoice Re-initiated, Run ID: ${newRunID}`,
        type: "success",
      });
      getRunHistoryFunc(0);
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  const rows: GridRowsProp = runHistory.map((item) => {
    const isErrorStatus = item?.status?.toLowerCase()?.includes("fail");

    return {
      id: item.id,
      col1:
        Object.keys(item.includedInvoices).length > 0
          ? convertData(item.includedInvoices)
          : "",
      col2:
        Object.keys(item.excludedInvoices).length > 0
          ? convertData(item.excludedInvoices)
          : "",
      status: [
        isErrorStatus ? "ERROR" : "-",
        item.status,
        isErrorStatus ? item.remarks : "",
      ]
        .filter(Boolean)
        .join(":"),
      outputFileLocation: ["STEP 3/3 COMPLETED"].includes(item.status)
        ? item.outputFileLocation
        : "",
      startedAt: item?.startedAt?.value || "",
      startedBy: item.startedBy,
    };
  });

  const handleClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
  ): void => {
    event.preventDefault();
    const { field, id, row } = params;
    const value = row[field];

    if (field === "outputFileLocation" && value) {
      const paramLength = value.split(",").length;
      const fileName = value.split("/").pop();

      downloadFile(id, paramLength === 1 ? fileName : `${id}_AP.zip`);
    }

    if (field === "reInitiate" && value !== "NA") {
      setRerunProcessId(id);
      setShowModal(true);
    }
  };

  useEffect(() => {
    issuesFilterRef.current = issuesFilter;
  }, [issuesFilter]);

  useEffect(() => {
    detailsFilterModelRef.current = detailsFilterModel;
  }, [detailsFilterModel]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState,
    );
  }, [totalRowCount, setRowCountState]);

  useEffect(() => {
    if (processId) {
      setLoader(true);
      getDetailData(processId)
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [processId, getDetailData]);

  return (
    <>
      <SectionContainer
        title="Run History"
        className="w-full mx-auto mt-14 px-10"
      >
        <div className="flex justify-end">
          <Refresh refresh={() => getRunHistoryFunc(pageNo)} label="Refresh" />
        </div>
        <div
          className={[
            rowCountState < pageSize
              ? "max-h-[850px]"
              : pageSize === 5
                ? "min-h-[350px] max-h-[375px] h-[60vh] 2xl:h-[45vh]"
                : "min-h-[450px] max-h-[625px] h-[70vh] 2xl:h-[65vh]",
            "mx-auto my-5 border border-[#211551] rounded-md",
          ].join(" ")}
        >
          <DataGrid
            autoHeight={rowCountState < pageSize}
            hideFooter={rowCountState < pageSize}
            loading={enableLoader}
            sortingOrder={["desc", "asc"]}
            initialState={{
              sorting: {
                sortModel: [{ field: "id", sort: "desc" }],
              },
            }}
            rowCount={rowCountState}
            rows={rows}
            columns={columns}
            slots={{ toolbar: Toolbar, loadingOverlay: Empty }}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[5, 10]}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            filterModel={filterModel}
            sortModel={sortModel}
            onFilterModelChange={onFilterChange}
            onSortModelChange={onSortModelChange}
            sx={{
              "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
                borderBottomWidth: 0,
              },
              "& .MuiDataGrid-cell:hover": {
                // color: "primary.main",
                cursor: "pointer",
              },
              "& .MuiDataGrid-toolbarContainer": {
                "& .MuiButtonBase-root": { color: "#211551" },
              },

              "& .MuiDataGrid-columnHeaders": {
                bgcolor: "#211551",
                color: "white",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiIconButton-sizeSmall": {
                  color: "white",
                },
              },
              "& .MuiDataGrid-row": { fontSize: "small" },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "4px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "10px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "17px",
              },
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
                {
                  outline: "none",
                },
            }}
            getRowHeight={() => "auto"}
            isCellEditable={() => false}
            columnHeaderHeight={44}
            disableRowSelectionOnClick
            onCellClick={handleClick}
          />
        </div>
      </SectionContainer>
      {enablePopup && (
        <ConfirmModal>
          <DetailWindow
            records={detailData}
            processId={processId}
            totalRowCount={totalRowCountDetails}
            paginationModel={paginationModelDetails}
            filterModel={detailsFilterModel}
            issuesFilter={issuesFilter}
            onPaginationModelChange={setPaginationModelDetails}
            getDetailData={() =>
              getDetailData(processId, "all", undefined, true)
            }
            onFilterChange={(filterModel) => {
              setLoader(true);
              getDetailData(processId, undefined, filterModel, false)
                .then(() => {
                  setLoader(false);
                })
                .catch(() => {
                  setLoader(false);
                });
            }}
            onIssuesFilterChange={(issuesFilter) => {
              setLoader(true);
              getDetailData(processId, issuesFilter, undefined, false)
                .then(() => {
                  setLoader(false);
                })
                .catch(() => {
                  setLoader(false);
                });
            }}
            onClose={() => {
              setTotalRowCountDetails(0);
              setPaginationModelDetails({ page: 0, pageSize: 20 });
              setIssuesFilter("all");
              setDetailsFilterModel({
                items: [],
              });
              setPopup(false);
              setDetailData([]);
              setProcessId("");
            }}
          />
        </ConfirmModal>
      )}
      {showModal && (
        <ConfirmModal closeModal={() => setShowModal(false)}>
          <>
            <div className="flex items-center justify-center">
              <div className="bg-white p-4">
                <p className="mb-4">
                  Would you like to re-run Invoice Run Number - {rerunProcessId}
                </p>
                <div className="flex justify-end">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                    onClick={() => {
                      setShowModal(false);
                      reInititateRunHistory(rerunProcessId);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => setShowModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </>
        </ConfirmModal>
      )}
    </>
  );
};

export default Historysection;
