import { Switch } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowId } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

import {
  batchConfigUpdate,
  clientListProps,
  dropdownFieldsDataProps,
  dropdownOptionProps,
  validFieldPatternsProps,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import ArrowDown from "../../assets/Icons/ArrowDown";
import { checkIfAdmin } from "../../auth/validateUser";
import TooltipList from "../../components/ColumnHeaders/TooltipList";
import SectionConatiner from "../../components/SectionConatiner";
import SpinnerComponent from "../../components/Spinner";
import Table from "../../components/Table";
import ToastComponent from "../../components/Toast";
import useConfig, { Data, Row } from "../../hooks/useConfig";
import useStore from "../../store/useStore";

const columns: GridColDef[] = [
  {
    field: "batchId",
    headerName: "Batch ID",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 250,
    flex: 1,
    type: "textarea",
    sortable: false,
  },
  {
    field: "frequencyInHours",
    headerName: "Report Gen. Freq. (Hrs)",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    field: "clients",
    headerName: "Clients",
    minWidth: 300,
    flex: 1,
    type: "multi-select-dropdown",
    renderCell: (params: GridRenderCellParams) => (
      <TooltipList list={params.value} />
    ),
    sortable: false,
  },
  {
    field: "nextRunAt",
    headerName: "Next Run At",
    minWidth: 200,
    editable: false,
    flex: 1,
    sortable: false,
  },
  {
    field: "startTime",
    headerName: "Start Date & Time",
    minWidth: 175,
    editable: false,
    flex: 1,
    sortable: false,
  },
  {
    field: "lastUpdatedBy",
    headerName: "Last Updated by",
    minWidth: 250,
    flex: 1,
    sortable: false,
  },
  {
    field: "lastUpdatedOn",
    headerName: "Last Updated on",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    field: "enableCode",
    headerName: "Enable/Disable",
    width: 120,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    align: "center",
    disableExport: true,
  },
];

const ScheduleBatch: React.FC = () => {
  const { data, handleError, handleResponse } = useConfig(
    "scheduleBatchConfig",
  );

  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);
  const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);
  const [clientList, setClientList] = useState<clientListProps[]>([]);
  const disableUpdateFields: string[] = ["batchId"];
  const duplicateCheckFields: string[] = ["batchId"];
  const isAdmin = checkIfAdmin();
  const mandatoryFields: string[] = ["frequencyInHours", "clients"];

  const hideFields: string[] = [
    "nextRunAt",
    "startTime",
    "enableCode",
    "lastUpdatedBy",
    "lastUpdatedOn",
  ];

  const hideAddFields: string[] = ["batchId"];

  const validFieldPatterns: validFieldPatternsProps = {
    frequencyInHours: {
      pattern: /^[1-9][0-9]*$/g, // only whole numbers greater than 0
      isValid: (value: string) => {
        const v = Number(value);

        return v >= 1 && v <= 1000;
      },
      errorText: "Enter a value between 1 to 1000",
    },
  };

  const clientsColumn = columns.find((c) => c.field === "clients");
  const enableCodeColumn = columns.find((c) => c.field === "enableCode");

  if (clientsColumn) {
    clientsColumn.valueGetter = (params) =>
      params?.value
        ?.map(
          (id: string) => clientList.find((c) => c.s4CompanyId === id)?.name,
        )
        ?.filter(Boolean)
        ?.map((c: string) => `"${c}"`)
        ?.join(";") || "";
  }

  if (enableCodeColumn) {
    enableCodeColumn.renderCell = (params) => {
      const onChange = (e: { target: { checked: boolean } }): void => {
        updateData(params.row.batchId, {
          ...params.row,
          enableCode: e.target.checked,
        });
      };

      return (
        <Switch
          disabled={!isAdmin}
          checked={params.value}
          onChange={onChange}
        />
      );
    };
  }

  const otherClients: string[] = [];

  data.forEach((d) => {
    const clients = d.clients as string[];

    if (d.batchId !== selectedRowId) {
      clients.forEach((c) => {
        if (!otherClients.includes(c)) {
          otherClients.push(c);
        }
      });
    }
  });

  const clientListDropdown: dropdownOptionProps[] = clientList
    .filter((c) => {
      return !otherClients.includes(c.s4CompanyId);
    })
    .map((c) => ({ value: c.s4CompanyId, label: c.name }));

  const dropdownFieldsData: dropdownFieldsDataProps[] = [
    {
      field: "clients",
      data1: clientListDropdown,
    },
  ];

  const dataToRow = (data: Data[]): Row[] => {
    return data.map((e: Data): Row => {
      const clients = e.clients as Array<{
        s4CompanyId: string;
      }>;

      const startTime = e.startTime as {
        value: string;
      };

      const lastUpdatedDate = e.lastUpdatedDate as {
        value: string;
      };

      return {
        id: e.batchId as string,
        batchId: e.batchId as string,
        description: e.description as string,
        enableCode: e.enabled as boolean,
        clients: clients?.map((c) => c.s4CompanyId) || [],
        frequencyInHours: e.frequencyInHours as string,
        nextRunAt: e.nextRunAt as string,
        startTime: startTime.value,
        lastUpdatedBy: e.lastUpdatedBy as string,
        lastUpdatedOn: lastUpdatedDate.value,
      };
    });
  };

  const getClientList = async (): Promise<void> => {
    try {
      const getRequest = InitiateInvoiceApi.getClientList();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse?.data?.errors?.length === 0) {
        const clientList = (getResponse?.data?.data?.clients || [])
          ?.filter((c: clientListProps) => !!c.s4CompanyId)
          ?.sort((a: clientListProps, b: clientListProps) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });

        setClientList(clientList);
      } else {
        enableToast({
          message:
            getResponse?.data?.errors?.[0]?.message || "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setClientList([]);
    }
  };

  const getData = (): Promise<void> => {
    const getRequest = InitiateInvoiceApi.getBatchList();

    return ResponseAPI.performRequest(getRequest)
      .then(handleResponse("__get__", dataToRow, ""))
      .catch(handleError);
  };

  const updateData = (
    rowId: string | number | null,
    data: Data,
  ): Promise<void> => {
    setLoader(true);

    const clients: Array<{
      s4CompanyId: string;
      s4CompanyName: string;
    }> = [];

    (data.clients as string[]).forEach((s4CompanyId: string) => {
      const c = clientList.find((c) => c.s4CompanyId === s4CompanyId);

      if (c)
        clients.push({ s4CompanyId: c.s4CompanyId, s4CompanyName: c.name });
    });

    const _updateState: batchConfigUpdate = {
      description: data.description as string,
      frequencyInHours: data.frequencyInHours
        ? Number(data.frequencyInHours)
        : -1,
      clients,
      enabled: rowId ? (data.enableCode as boolean) || false : true,
    };

    if (rowId) _updateState.batchId = Number(rowId);

    const updateRequest = InitiateInvoiceApi.updateBatch(_updateState);

    return ResponseAPI.performRequest(updateRequest)
      .then(
        handleResponse(
          rowId ? "__update__" : "__add__",
          dataToRow,
          `${rowId ? "Updated" : "Added"} successfully!`,
        ),
      )
      .catch(handleError);
  };

  const deleteData = async (rowId: string | number): Promise<void> => {
    setLoader(true);

    const deleteRequest = InitiateInvoiceApi.deleteBatch(rowId.toString());

    return await ResponseAPI.performRequest(deleteRequest)
      .then(handleResponse("__delete__", dataToRow, "Deleted successfully!"))
      .catch(handleError);
  };

  useEffect(() => {
    setLoader(true);
    Promise.all([getClientList(), getData()]).finally(() => setLoader(false));
  }, []);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <SectionConatiner
        title={
          <div className="flex gap-1.5 items-center">
            <span>Invoice Presentation</span>
            <ArrowDown className="-rotate-90" fill="currentColor" />
            <span>Batch Config</span>
          </div>
        }
        className="w-full mx-auto px-10 relative"
      >
        <Table
          suffix="Batch"
          cols={columns}
          rows={data}
          primaryField="batchId"
          mandatoryFields={mandatoryFields}
          hideFields={hideFields}
          hideAddFields={hideAddFields}
          disableUpdateFields={disableUpdateFields}
          duplicateCheckFields={duplicateCheckFields}
          dropdownFieldsData={dropdownFieldsData}
          validFieldPatterns={validFieldPatterns}
          isShowActionsColumn={isAdmin}
          onRowIdChange={setSelectedRowId}
          onUpdate={updateData}
          onDelete={deleteData}
        />
      </SectionConatiner>
    </>
  );
};

export default ScheduleBatch;
