import React, { SyntheticEvent } from "react";

import Button from "../../components/Button";
import SectionConatiner from "../../components/SectionConatiner";
import Tooltip from "../../components/Tooltip";

interface Section1Props {
  handleImportFiles: (e: SyntheticEvent) => void;
}

const Section1: React.FC<Section1Props> = ({ handleImportFiles }) => {
  return (
    <div className="w-5/6 mx-auto">
      <SectionConatiner
        title="Pre-Processor"
        collapse={true}
        className="px-10"
        infoText="Expand to Process Input files from PwC"
      >
        <form className="w-full flex flex-row justify-start items-center gap-3 my-10">
          Click the Button to create Import files for Job Diva:
          <Tooltip title="Picks PwC input files and creates JobDiva import files and legacy system import files." />
          <Button
            disabled={false}
            label="Create Import Files"
            type="submit"
            onClick={handleImportFiles}
          />
        </form>
      </SectionConatiner>
    </div>
  );
};

export default Section1;
