import React, { SyntheticEvent } from "react";

import { dropListProps, dropdownOptionProps } from "../../@types/assetTypes/ap";
import Button from "../../components/Button";
import SearchableDropdown from "../../components/SearchableDropdown";
import Textlabel from "../../components/Textlabel";

interface ArvmsProps {
  arrayList: dropListProps[];
  clientList: dropdownOptionProps[];
  batchIdList: dropdownOptionProps[];
  clientsSelected: dropdownOptionProps;
  batchIdSelected: dropdownOptionProps;
  handleDropdownChange: (v: dropListProps) => void;
  handleClientDropdownChange: (v: dropdownOptionProps, type: string) => void;
  inputVal: dropListProps;
  handleSubmit: (e: SyntheticEvent) => void;
  buttonDisabled: boolean;
  handleFileUpload: (file: File) => void;
  closeFileUploaded: () => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InitiateSection: React.FC<ArvmsProps> = ({
  clientList,
  clientsSelected,
  batchIdList,
  batchIdSelected,
  handleClientDropdownChange,
  handleSubmit,
  buttonDisabled,
}) => {
  return (
    <div className="py-5 shadow-custom">
      <div className="text-center font-bold">
        <Textlabel label="Generate Consolidated Invoice" />
      </div>
      <form className="mt-10 max-w-2xl px-5 mx-auto">
        <div className="flex flex-col items-center w-fit mx-auto gap-7">
          <div className="w-full flex items-center">
            <Textlabel label="Clients" styleVal="mr-auto" />
            <SearchableDropdown
              options={clientList}
              value={clientsSelected}
              onSelect={(e) => {
                handleClientDropdownChange(e, "client");
              }}
              disabled={batchIdSelected.label !== ""}
              allowEmptyValue={false}
              showAllOnExactMatch
            />
          </div>
          <div className="w-[100%] flex items-center justify-end gap-3">
            <hr className="w-[35%]" /> <p className="font-bold">or</p>
            <hr className="w-[35%]" />
          </div>
          <div className="w-full flex items-center">
            <Textlabel label="Batch No" styleVal="mr-auto" />
            <SearchableDropdown
              options={batchIdList}
              value={batchIdSelected}
              onSelect={(e) => {
                handleClientDropdownChange(e, "batch");
              }}
              disabled={clientsSelected.label !== ""}
            />
          </div>
          <div className="mx-auto mt-5">
            <Button
              label="Generate PDF"
              type="submit"
              onClick={handleSubmit}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InitiateSection;
