import React, { SyntheticEvent, useEffect, useState } from "react";

import {
  clientListProps,
  dropListProps,
  dropdownOptionProps,
  batchListProps,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import SpinnerComponent from "../../components/Spinner";
import ToastComponent from "../../components/Toast";
import useStore from "../../store/useStore";

import InitiateSection from "./InitiateSection";

const Arvms: React.FC = () => {
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);
  const [inputVal, setInputVal] = useState<dropListProps>({ fixIssues: false });
  const [dropdownList, setDropdownList] = useState([{ id: "", name: "" }]);
  const [clientList, setClientList] = useState([]);
  const [batchIdList, setBatchIdList] = useState([]);

  const [clientsSelected, setClientsSelected] = useState<dropdownOptionProps>({
    value: "",
    label: "",
  });

  const [batchIdSelected, setBatchIdSelected] = useState<dropdownOptionProps>({
    value: "",
    label: "",
  });

  const setAsc = useStore((state) => state.setAsc);

  const buttonDisabled =
    clientsSelected.label === "" && batchIdSelected.label === "";

  const getRunHistory = async (
    sortValue: string = "id",
    sortOrder: string = "desc",
    loader: boolean = true,
  ): Promise<void> => {
    if (loader) setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.getRunHistory(
        "AR_VMS",
        sortValue,
        sortOrder,
        {},
        { pageNo: 0, pageSize: 1 },
      );

      const getResponse = await ResponseAPI.performRequest(getRequest);

      getResponse.data.errors.length > 0 &&
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });
    } catch (error) {
      console.log(error);
    }

    setAsc({
      ascValue: sortOrder.toLowerCase() !== "desc",
      ascField: sortValue,
    });

    if (loader) setLoader(false);
  };

  const getDropdownList = async (loader: boolean = true): Promise<void> => {
    if (loader) setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.getDropdownList();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      getResponse.data.errors.length === 0
        ? setDropdownList(getResponse.data.data.vms)
        : enableToast({
            message: getResponse.data.errors[0].message,
            type: "error",
          });
      setInputVal({
        name: getResponse.data.data.vms[0].name,
        id: getResponse.data.data.vms[0].id,
        fixIssues: false,
      });
    } catch (error) {
      console.log(error);
    }

    if (loader) setLoader(false);
  };

  const getClientList = async (loader: boolean = true): Promise<void> => {
    if (loader) setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.getClientList();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      getResponse.data.errors.length === 0
        ? setClientList(
            (getResponse?.data?.data?.clients || [])
              .sort((a: clientListProps, b: clientListProps) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                  return -1;
                }

                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              })
              .map((c: clientListProps) => ({
                value: c.id,
                label: c.name,
              })),
          )
        : enableToast({
            message: getResponse.data.errors[0].message,
            type: "error",
          });
      setClientsSelected({ label: "", value: "" });
    } catch (error) {
      console.log(error);
    }

    if (loader) setLoader(false);
  };

  const getBatchIdList = async (loader: boolean = true): Promise<void> => {
    if (loader) setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.getBatchIds();
      const getResponse = await ResponseAPI.performRequest(getRequest);

      getResponse.data.errors.length === 0
        ? setBatchIdList(
            getResponse.data.data.batches
              .sort((a: batchListProps, b: batchListProps) => {
                if (a.id < b.id) {
                  return -1;
                }

                if (a.id > b.id) {
                  return 1;
                }

                return 0;
              })
              .map((c: batchListProps) => ({
                value: c.id.toString(),
                label: c.id.toString(),
              })),
          )
        : enableToast({
            message: getResponse.data.errors[0].message,
            type: "error",
          });
      setBatchIdSelected({ label: "", value: "" });
    } catch (error) {
      console.log(error);
    }

    if (loader) setLoader(false);
  };

  console.log(batchIdSelected);

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    const queryParam =
      batchIdSelected.label !== ""
        ? `filter=BATCHID in (${batchIdSelected.value})`
        : `filter=CLIENTID in (${clientsSelected.value})`;

    try {
      const request = InitiateInvoiceApi.submitIdsToGenerateInvoice(queryParam);
      const response = await ResponseAPI.performRequest(request);

      if (response.data.errors.length !== 0) {
        setLoader(false);
        enableToast({
          message: response.data.errors[0].message,
          type: "error",
        });

        return;
      }

      setLoader(false);

      enableToast({
        message: response.data.data.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
      setLoader(false);
    }

    setClientsSelected({ label: "", value: "" });
    setBatchIdSelected({ label: "", value: "" });
  };

  const handleDropdownChange = (value: dropListProps): void => {
    setInputVal({ ...inputVal, name: value.name, id: value.id });
  };

  const handleClientDropdownChange = (
    value: dropdownOptionProps,
    type: string,
  ): void => {
    if (type === "client") {
      setClientsSelected(value);

      return;
    }

    setBatchIdSelected(value);
  };

  const handleFileUpload = (file: File): void => {
    setInputVal({ ...inputVal, file });
  };

  const closeFileUploaded = (): void => {
    setInputVal({ ...inputVal, file: undefined });
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = e.target.type === "checkbox" && e.target.checked;

    setInputVal({ ...inputVal, fixIssues: value || false });
  };

  useEffect(() => {
    if (toastMessage.message === "") {
      enableToast({ message: "", type: "" });
    }
  }, [toastMessage.message]);

  useEffect(() => {
    setLoader(true);
    Promise.all([
      getRunHistory(undefined, undefined, false),
      getDropdownList(false),
      getClientList(false),
      getBatchIdList(false),
    ]).finally(() => setLoader(false));
  }, []);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <InitiateSection
        arrayList={dropdownList}
        clientList={clientList}
        batchIdList={batchIdList}
        batchIdSelected={batchIdSelected}
        clientsSelected={clientsSelected}
        handleDropdownChange={handleDropdownChange}
        handleClientDropdownChange={handleClientDropdownChange}
        inputVal={inputVal}
        handleFileUpload={handleFileUpload}
        handleSubmit={handleSubmit}
        buttonDisabled={buttonDisabled}
        closeFileUploaded={closeFileUploaded}
        handleCheckboxChange={handleCheckboxChange}
      />
    </>
  );
};

export default Arvms;
